<app-loading-spinner *ngIf="!promotion"></app-loading-spinner>

<div *ngIf="promotion" class="container-fluid">
  <div class="row justify-content-end">
    <div class="col-auto">
      <button type="button"
        class="btn btn-primary"
        [disabled]="!dirty || loading"
        (click)="savePromotion()">
        <span class="k-icon k-i-save"></span>
        Save Promo
      </button>
    </div>
    <div class="col-auto">
      <button type="button"
        class="btn btn-secondary"
        [disabled]="!dirty || loading"
        (click)="abandonChanges()">
        <span class="k-icon k-i-cancel"></span>
        Abandon Changes
      </button>
    </div>
    <div class="col-auto">
      <a class="font-weight-bold" [routerLink]="['/website-management', websiteConfig.id, 'calendar']">
        <span class="k-icon k-i-arrow-left"></span>
        Return to website management
      </a>
    </div>
  </div>

  <div class="form-group">
    <label [for]="promoDescription" class="d-block">Description</label>
    <input #promoDescription
      class="form-control"
      [disabled]="loading"
      [(ngModel)]="promotion.description"
      maxlength="255">
  </div>
  <div class="form-group">
    <div class="form-check">
      <input #inputActive
        type="checkbox"
        class="form-check-input"
        [(ngModel)]="promotion.active"
        [disabled]="loading">
      <label [for]="inputActive" class="form-check-label">Active</label>
    </div>
  </div>
  <div class="form-row">
    <div class="col form-group">
      <label [for]="promoStart">Start Date</label>
      <kendo-datepicker #promoStart
        [(value)]="promotionStart"
        [disabled]="loading"
        class="w-100">
      </kendo-datepicker>
    </div>
    <div class="col form-group">
      <label [for]="promoEnd">End Date</label>
      <kendo-datepicker #promoEnd
        [(value)]="promotionEnd"
        [disabled]="loading"
        class="w-100">
      </kendo-datepicker>
    </div>
  </div>
  <div class="row align-items-center">
    <div class="col-auto">
      <label [for]="selectPromoType">Sale/Promotion</label>
      <kendo-dropdownlist #selectPromoType
        [data]="promotionTypes"
        [(ngModel)]="promotion.promotionType"
        (valueChange)="selectPromoTypeChanged($event)">
      </kendo-dropdownlist>
    </div>
    <div *ngIf="promotion.promotionType === 'Sale'" class="col-auto form-group mb-0">
      <div class="form-check">
        <input #inputOverrideSaleColors
          type="checkbox"
          class="form-check-input"
          [(ngModel)]="promotion.overrideSaleColors"
          [disabled]="loading">
        <label class="form-check-label" [for]="inputOverrideSaleColors">Entire Store Sale</label>
        <div kendoTooltip class="d-inline-block cursor-pointer">
          <span class="k-icon k-i-question"
            title="When selected, current sale colors will not appear on the website between the start &amp; end sale dates.">
          </span>
          <span class="sr-only">Entire store sale information</span>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group">
    <label class="d-block">
      Image
      <span class="text-muted font-weight-normal">(Recommended Size: {{ recommendedImageSize }})</span>
    </label>
    <kendo-fileselect
      [multiple]="false"
      [restrictions]="fileSelectRestrictions"
      [showFileList]="false"
      [disabled]="loading"
      zoneId="imageDropZone"
      (valueChange)="imageChanged($event)">
    </kendo-fileselect>
    <figure *ngIf="smallImage" kendoUploadDropZone="imageDropZone" class="figure mt-3">
      <img
        [src]="smallImage.src"
        alt="Preview Small Image"
        class="img-fluid preview-image"
        [style.maxHeight.px]="250">
      <figcaption class="figure-caption">
        <button type="button" class="btn btn-link" (click)="previewSmallImage()">
          View Small Image
          <span class="k-icon k-i-hyperlink-open"></span>
        </button>
        <button type="button" class="btn btn-link" (click)="previewLargeImage()">
          View Large Image
          <span class="k-icon k-i-hyperlink-open"></span>
        </button>
      </figcaption>
    </figure>
  </div>
  <div class="form-group">
    <label [for]="promoNavigateUrl" class="font-weight-bold">Navigate URL</label>
    <input #promoNavigateUrl
      class="form-control"
      [(ngModel)]="promotion.navigateUrl"
      [disabled]="loading"
      maxlength="255">
  </div>
</div>

<kendo-dialog title="Abandon Changes" *ngIf="abandonDialogOpened" [minWidth]="250">
  <p>Are you sure you want to abandon the changes to this promotion?</p>
  <kendo-dialog-actions>
    <button kendoButton (click)="abandonDialogClick(true)" [primary]="true">Yes</button>
    <button kendoButton (click)="abandonDialogClick(false)">No</button>
  </kendo-dialog-actions>
</kendo-dialog>

<kendo-dialog *ngIf="previewDialogOpened"
  [title]="previewDialogTitle"
  [maxWidth]="previewDialogWidth"
  (close)="previewDialogOpened = false">
  <figure class="figure">
    <img [src]="previewDialogImage.src" [alt]="previewDialogImage.alt" class="img-fluid">
    <figcaption class="figure-caption">
      <label class="font-weight-bold">Actual Image Size</label>
      {{ previewDialogImage.width }}&times;{{ previewDialogImage.height }}
    </figcaption>
  </figure>
</kendo-dialog>
