import { SingleImageSlide } from 'src/app/models/website-management/single-image-slide';
import { SplitImageSlide } from 'src/app/models/website-management/split-image-slide';
import { WebsiteComponent } from 'src/app/models/website-management/website-component';
import { WebsiteConfig } from 'src/app/models/website-management/website-config';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-split-image-slide-draft',
  templateUrl: './split-image-slide-draft.component.html',
  styleUrls: ['./split-image-slide-draft.component.scss']
})
export class SplitImageSlideDraftComponent implements OnInit {
  @Input() slide: SplitImageSlide;
  @Input() component: WebsiteComponent;
  @Input() websiteConfig: WebsiteConfig;
  @Input() disabled: boolean;

  readonly largeImageWidth = 570;
  readonly largeImageHeight = 445;
  readonly smallImageWidth = 270;
  readonly smallImageHeight = 211;

  ngOnInit(): void {
    if (!this.slide.left) {
      this.slide.left = {} as SingleImageSlide;
    }

    if (!this.slide.right) {
      this.slide.right = {} as SingleImageSlide;
    }
  }
}
