import { Subscription } from 'rxjs';
import { CONSUMERSITE_CONFIG, WINDOW } from 'src/app/injection-tokens';
import { DropoffLocation } from 'src/app/models/website-management/dropoff-location';
import { LocationHours } from 'src/app/models/website-management/location-hours';
import { WebsiteConfig } from 'src/app/models/website-management/website-config';
import {
    DropoffLocationManagementService
} from 'src/app/services/website-management/dropoff-location-management.service';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

interface StateLocation {
  state: string;
  locations: DropoffLocation[];
  expanded: boolean;
}

@Component({
  selector: 'app-dropoff-locations',
  templateUrl: './dropoff-locations.component.html',
  styleUrls: ['./dropoff-locations.component.scss']
})
export class DropoffLocationsComponent implements OnInit, OnDestroy {
  websiteConfig: WebsiteConfig;

  private routeSubscription: Subscription;
  private getDropoffLocationsSubscription: Subscription;
  private updateAllDropoffLocationHoursSubscription: Subscription;

  stateLocations: StateLocation[];
  loading: boolean;
  allDropoffHours: LocationHours[];

  dropOffHoursError = false;
  opened = false;

  constructor(
    private dropoffLocationManagement: DropoffLocationManagementService,
    private route: ActivatedRoute,
    @Inject(CONSUMERSITE_CONFIG) private consumersitesConfig: WebsiteConfig[],
    @Inject(WINDOW) private window: Window,
  ) { }

  ngOnInit(): void {
    this.routeSubscription = this.route.paramMap.subscribe(params => {
      this.websiteConfig = this.consumersitesConfig.find(x => x.id === params.get('websiteId'));
      this.refreshDropoffLocations();
    });
  }

  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    if (this.getDropoffLocationsSubscription) {
      this.getDropoffLocationsSubscription.unsubscribe();
    }
    if (this.updateAllDropoffLocationHoursSubscription) {
      this.updateAllDropoffLocationHoursSubscription.unsubscribe();
    }
  }

  dropoffLocationClick(state: string): void {
    const stateLocation = this.stateLocations.find(x => x.state === state);
    if (stateLocation) {
      stateLocation.expanded = !stateLocation.expanded;
    }
  }

  previewDropoffLocation(event: Event): void {
    event.preventDefault();
    const url = (event.target as HTMLAnchorElement).href;
    this.window.open(url, 'preview');
  }

  onChangeHoursClick(): void {
    this.opened = true;
  }

  onDialogClose(): void {
    this.opened = false;
  }

  onSaveHours(): void {
    this.loading = true;

    const $ = this.dropoffLocationManagement.updateDropoffLocationHours(this.websiteConfig.id, this.allDropoffHours);
    this.updateAllDropoffLocationHoursSubscription = $.subscribe(() => {
      this.loading = false;
      this.refreshDropoffLocations();
      this.onDialogClose();
    });
  }

  private refreshDropoffLocations() {
    if (this.getDropoffLocationsSubscription) {
      this.getDropoffLocationsSubscription.unsubscribe();
    }
    this.loading = true;
    const $ = this.dropoffLocationManagement.getDropoffLocations(this.websiteConfig.id);
    this.getDropoffLocationsSubscription = $.subscribe(locations => {
      this.stateLocations = locations
        .reduce((acc, val) => {
          if (!acc.find(x => x === val.state.name)) {
            acc.push(val.state.name);
          }
          return acc;
        }, [] as string[])
        .map(state => {
          return {
            state,
            locations: locations
              .filter(x => x.state.name === state)
              .sort((a, b) => a.name.localeCompare(b.name, 'en', { sensitivity: 'base' })),
            expanded: false
          };
        })
        .sort((a, b) => a.state.localeCompare(b.state, 'en', { sensitivity: 'base' }));

      if (locations.length) {
        this.allDropoffHours = locations[0].hours.sort(x => x.dayofWeek);
      }

      this.loading = false;
    });
  }

}
