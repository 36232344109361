import { Directive } from '@angular/core';
import { UntypedFormGroup, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: '[appPressItemFormValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: PressItemFormValidatorDirective,
      multi: true
    }
  ]
})
export class PressItemFormValidatorDirective implements Validator {
  validate(formGroup: UntypedFormGroup): ValidationErrors {
    if ('resource' in formGroup.controls && 'resourceType' in formGroup.controls &&
      formGroup.controls.resourceType.value === 'Url') {
      const resourceUrl: string = formGroup.controls.resource.value ?? '';
      if (!resourceUrl.length) {
        return { urlRequired: true };
      }
    }
    return null;
  }
}
