import { HtmlContentSlide } from 'src/app/models/website-management/html-content-slide';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-html-content-slide-preview',
  templateUrl: './html-content-slide-preview.component.html',
  styleUrls: ['./html-content-slide-preview.component.scss']
})
export class HtmlContentSlidePreviewComponent {
  @Input() slide: HtmlContentSlide;
}
