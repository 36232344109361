import { Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { HeaderService } from '../services/header.service';
import { AccountInfo, EventMessage, EventType } from '@azure/msal-browser';

@Component({
  selector: 'app-main-section-header',
  templateUrl: './main-section-header.component.html',
  styleUrls: ['./main-section-header.component.scss']
})
export class MainSectionHeaderComponent implements OnInit, OnDestroy {
  private headerSubscription: Subscription;
  private msalSubscription: Subscription;

  salutation: string;
  title: string;
  account: AccountInfo;

  constructor(
    private headerService: HeaderService,
    private msal: MsalService,
    private broadcastService: MsalBroadcastService) {
      this.headerSubscription = this.headerService.subscribe(title => this.refresh(title));

      this.msalSubscription = this.broadcastService.msalSubject$.subscribe((eventMessage: EventMessage) => {
        if (eventMessage.eventType === EventType.LOGIN_SUCCESS || eventMessage.eventType === EventType.LOGIN_FAILURE) {
          this.refresh();
        }
      });
  }

  ngOnInit(): void {
    this.refresh();
  }

  ngOnDestroy(): void {
    if (this.headerSubscription) {
      this.headerSubscription.unsubscribe();
    }
    if (this.msalSubscription) {
      this.msalSubscription.unsubscribe();
    }
  }

  private refresh(title: string = null) {
    if (title !== null) {
      this.title = title;
    }

    this.account = this.msal.instance.getActiveAccount();

    const hour = new Date().getHours();
    this.salutation = hour < 12 ? 'morning' :
      hour < 17 ? 'afternoon' :
      'evening';
  }
}
