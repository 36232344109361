import { fromEvent, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { CONSUMERSITE_CONFIG, WINDOW } from 'src/app/injection-tokens';
import { Charity } from 'src/app/models/website-management/charity';
import { DropoffLocation } from 'src/app/models/website-management/dropoff-location';
import { fileSelectRestrictions as restrictions } from 'src/app/models/website-management/file-select-restrictions';
import { LocationHours } from 'src/app/models/website-management/location-hours';
import { State } from 'src/app/models/website-management/state';
import { WebsiteConfig } from 'src/app/models/website-management/website-config';
import { GoogleMapsService } from 'src/app/services/google-maps.service';
import { HeaderService } from 'src/app/services/header.service';
import { CharityManagementService } from 'src/app/services/website-management/charity-management.service';
import { ContentManagementService } from 'src/app/services/website-management/content-management.service';
import {
    DropoffLocationManagementService
} from 'src/app/services/website-management/dropoff-location-management.service';
import { Component, HostListener, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MapGeocoder } from '@angular/google-maps';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '@progress/kendo-angular-notification';

@Component({
  selector: 'app-dropoff-location-management',
  templateUrl: './dropoff-location-management.component.html',
  styleUrls: ['./dropoff-location-management.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DropoffLocationManagementComponent implements OnInit, OnDestroy {
  dropoffLocation: DropoffLocation;
  dropoffLocationImage: HTMLImageElement;
  fileSelectRestrictions = restrictions;
  loading = false;
  abandonDialogOpened = false;
  geolocateDialogOpened = false;
  viewCoordinatesDialogOpened = false;
  viewImageRequiredAlert = false;

  readonly defaultLatitude = 40.1043;
  readonly defaultLongitude = -74.9272;
  readonly minLatitude = -90;
  readonly maxLatitude = 90;
  readonly minLongitude = -180;
  readonly maxLongitude = 180;
  readonly recommendedImageSize = '740x500';

  states$: Observable<State[]>;
  googleApiLoaded$: Observable<boolean>;
  charities$: Observable<Charity[]>;

  geolocatedLatLng: google.maps.LatLngLiteral;
  websiteConfig: WebsiteConfig;

  private _savedDropoffLocation: string;
  private routeParamSubscription: Subscription;
  private fragmentSubscription: Subscription;
  private dropoffLocationSubscription: Subscription;
  private geocodingSubscription: Subscription;
  private imageSubscription: Subscription;

  constructor(
    private dropoffLocationManagement: DropoffLocationManagementService,
    private charityManagement: CharityManagementService,
    private contentManagement: ContentManagementService,
    private geocoder: MapGeocoder,
    private route: ActivatedRoute,
    private router: Router,
    private header: HeaderService,
    private notification: NotificationService,
    googleMaps: GoogleMapsService,
    @Inject(WINDOW) private window: Window,
    @Inject(CONSUMERSITE_CONFIG) private consumersiteConfig: WebsiteConfig[],
  ) {
    this.googleApiLoaded$ = googleMaps.loaded;
  }

  ngOnInit(): void {

    this.routeParamSubscription = this.route.paramMap.subscribe(params => {
      const websiteId = params.get('websiteId');
      this.websiteConfig = this.consumersiteConfig.find(x => x.id === websiteId);
      this.header.title = `Drop-off Location Management for ${this.websiteConfig.name}`;

      this.states$ = this.contentManagement.getStates(websiteId).pipe(
        map(x => x.sort((a, b) => a.abbreviation.localeCompare(b.abbreviation, 'en', { sensitivity: 'base' })))
      );

      this.charities$ = this.charityManagement.getCharities(websiteId);

      const dropoffLocationIdText = params.get('dropoffLocationId');
      if (!dropoffLocationIdText) {
        this.createNewDropoffLocation();
      } else {
        this.fragmentSubscription = this.route.fragment.subscribe(fragment => {
          const dropoffLocationId = parseInt(dropoffLocationIdText, 10);
          this.loadDropoffLocation(dropoffLocationId, fragment === 'new');
        });
      }
    });
  }

  ngOnDestroy(): void {
    if (this.routeParamSubscription) {
      this.routeParamSubscription.unsubscribe();
    }
    if (this.fragmentSubscription) {
      this.fragmentSubscription.unsubscribe();
    }
    if (this.dropoffLocationSubscription) {
      this.dropoffLocationSubscription.unsubscribe();
    }
    if (this.geocodingSubscription) {
      this.geocodingSubscription.unsubscribe();
    }
    if (this.imageSubscription) {
      this.imageSubscription.unsubscribe();
    }
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.dirty) {
      return confirm('You have unsaved changes that will lost if you leave. Are you sure you want to leave?');
    }
    return true;
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification(event: Event): void {
    if (this.dirty) {
      event.preventDefault();
    }
  }

  saveDropoffLocation(): void {
    if (!this.dropoffLocation.image || !this.dropoffLocation.image.trim().length) {
      this.viewImageRequiredAlert = true;
      return;
    }

    this.viewImageRequiredAlert = false;
    this.loading = true;

    if (this.dropoffLocation.id > 0) {
      this.dropoffLocationManagement.updateDropoffLocation(this.dropoffLocation, this.websiteConfig.id)
        .subscribe(location => {
          this.dropoffLocation = location;
          this.savedDropoffLocation = location;

          this.loading = false;
          this.notification.show({
            content: 'This drop-off location has been saved.',
            animation: { type: 'slide', duration: 350 },
            position: { horizontal: 'center', vertical: 'top' },
            type: { style: 'success', icon: true },
            hideAfter: 3000
          });
        });
      } else {
        this.dropoffLocationManagement.createDropoffLocation(this.dropoffLocation, this.websiteConfig.id)
          .subscribe(result => {
            this.dropoffLocation = result;
            this.savedDropoffLocation = result;
            this.router.navigate(
              [ 'website-management', this.websiteConfig.id, 'dropoff-location-management', result.id ],
              { fragment: 'new' }
            );
          });
      }
  }

  geolocate(): void {
    this.loading = true;
    const address = `${this.dropoffLocation.address}, ${this.dropoffLocation.city}, ${this.dropoffLocation.state}`;
    this.geocodingSubscription = this.geocoder.geocode({ address }).subscribe(response => {
      this.geolocatedLatLng = {
        lat: response.results[0].geometry.location.lat(),
        lng: response.results[0].geometry.location.lng()
      };

      this.geolocateDialogOpened = true;

      setTimeout(() => this.loading = false);
    });
  }

  useGeolocatedCoordinates(): void {
    this.dropoffLocation.latitude = this.geolocatedLatLng.lat;
    this.dropoffLocation.longitude = this.geolocatedLatLng.lng;

    this.geolocateDialogOpened = false;
  }

  viewCoordinates(): void {
    this.viewCoordinatesDialogOpened = true;
  }

  imageChanged(files: File[]): void {
    if (!files || !files.length) {
      this.dropoffLocation.image = '';
      this.dropoffLocationImage = undefined;
      return;
    }

    this.loading = true;

    this.dropoffLocationManagement.saveImage(this.websiteConfig.id, files[0]).subscribe(result => {
      this.dropoffLocation.image = result.image;
      this.dropoffLocation.imageUrls = result.imageUrls;
      this.loading = false;
      this.loadImage();
    });
  }

  get existingPageSlug(): string {
    return this.savedDropoffLocation.pageSlug;
  }

  abandonChanges(): void {
    this.abandonDialogOpened = true;
  }

  abandonDialogClick(value: boolean): void {
    if (value) {
      this.dropoffLocation = this.savedDropoffLocation;
      this.loadImage();
    }

    this.abandonDialogOpened = false;
  }

  previewDropoffLocation(event: Event): void {
    event.preventDefault();
    const url = (event.target as HTMLAnchorElement).href;
    this.window.open(url, 'preview');
  }

  private createNewDropoffLocation() {
    const hours: LocationHours[] = [];
    const now = new Date();
    for (let i = 1; i <= 7; i++) {
      hours.push({
        dayofWeek: i,
        openTime: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 10, 0),
        closeTime: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 17, 0),
        closed: false,
        temporary: false
      });
    }
    const dropoffLocation: DropoffLocation = {
      id: 0,
      name: '',
      address: '',
      address2: '',
      city: '',
      state: undefined,
      zip: '',
      active: true,
      latitude: this.defaultLatitude,
      longitude: this.defaultLongitude,
      image: undefined,
      pageSlug: '',
      pageContent: '',
      hours: hours,
      imageUrls: undefined,
      locatedNear: '',
      charities: []
    };

    this.dropoffLocation = dropoffLocation;
    this.savedDropoffLocation = dropoffLocation;
  }

  private loadDropoffLocation(dropoffLocationId: number, newDropoffLocation: boolean) {
    this.loading = true;

    this.dropoffLocationSubscription = this.dropoffLocationManagement
      .getDropoffLocation(dropoffLocationId, this.websiteConfig.id)
      .subscribe(location => {
        this.dropoffLocation = location;
        this.savedDropoffLocation = location;
        this.loadImage();
        this.loading = false;

        if (newDropoffLocation) {
          this.notification.show({
            content: 'This drop-off location has been created.',
            animation: { type: 'slide', duration: 350 },
            position: { horizontal: 'center', vertical: 'top' },
            type: { style: 'success', icon: true },
            hideAfter: 3000
          });
        }
      });
  }

  get dirty(): boolean {
    return JSON.stringify(this.dropoffLocation) !== this._savedDropoffLocation;
  }

  get dropoffLocationLatLng(): google.maps.LatLng {
    return new google.maps.LatLng(this.dropoffLocation.latitude, this.dropoffLocation.longitude);
  }

  private loadImage() {
    if (!this.dropoffLocation.image) {
      this.dropoffLocationImage = undefined;
      return;
    }

    const image = new Image();
    fromEvent(image, 'load').subscribe(() => this.dropoffLocationImage = image);
    image.src = this.dropoffLocation.imageUrls.thumb
  }

  private get savedDropoffLocation(): DropoffLocation {
    const location = JSON.parse(this._savedDropoffLocation) as DropoffLocation;

    location.hours.forEach(hours => {
      hours.openTime = new Date(hours.openTime);
      hours.closeTime = new Date(hours.closeTime);
    });

    return location;
  }

  private set savedDropoffLocation(location: DropoffLocation) {
    this._savedDropoffLocation = JSON.stringify(location);
  }
}
