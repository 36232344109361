import { ServiceAlert } from 'src/app/models/website-management/service-alert';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-service-alert-preview',
  templateUrl: './service-alert-preview.component.html',
  styleUrls: ['./service-alert-preview.component.scss']
})
export class ServiceAlertPreviewComponent {
  @Input() content: ServiceAlert;
}
