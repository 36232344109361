import { Subscription } from 'rxjs';
import { CONSUMERSITE_CONFIG } from 'src/app/injection-tokens';
import { ColorSale } from 'src/app/models/website-management/color-sale';
import { PriceColor } from 'src/app/models/website-management/price-color';
import { WebsiteConfig } from 'src/app/models/website-management/website-config';
import { PromotionManagementService } from 'src/app/services/website-management/promotion-management.service';
import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    DateChangeEvent, EventClickEvent, EventStyleArgs, Resource, SchedulerEvent
} from '@progress/kendo-angular-scheduler';

interface PromotionSchedulerEvent extends SchedulerEvent {
  saleColor: number;
}


@Component({
  selector: 'app-promotion-calendar',
  templateUrl: './promotion-calendar.component.html',
  styleUrls: ['./promotion-calendar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PromotionCalendarComponent implements OnInit, OnDestroy {
  websiteConfig: WebsiteConfig;
  selectedDate = new Date();
  scheduleLoading = true;
  activeOnly = true;
  resources: Resource[] = [{
    name: 'Sale',
    data: [
      { text: 'Blue', value: PriceColor.Blue, color: '#0000ff' },
      { text: 'Pink', value: PriceColor.Pink, color: '#ff69b4' },
      { text: 'Orange', value: PriceColor.Orange, color: '#ffa500' },
      { text: 'Green', value: PriceColor.Green, color: '#008000' },
      { text: 'Yellow', value: PriceColor.Yellow, color: '#cccc00' },
      { text: 'Violet', value: PriceColor.Violet, color: '#9400d3' },
      { text: 'Promotion', value: 'promo', color: '#dc143c' }
    ],
    field: 'saleColor',
    valueField: 'value',
    textField: 'text',
    colorField: 'color'
  }];
  dateRangeStart: Date;
  dateRangeEnd: Date;

  events: SchedulerEvent[];
  schedulerHeight = 830;

  readonly eventHeight = 25;

  private routeSubscription: Subscription;
  private scheduleSubscription: Subscription;

  constructor(
    private router: Router,
    private promotionManagement: PromotionManagementService,
    private route: ActivatedRoute,
    @Inject(CONSUMERSITE_CONFIG) private consumersiteConfig: WebsiteConfig[]
  ) { }

  ngOnInit(): void {
    this.routeSubscription = this.route.paramMap.subscribe(params => {
      this.websiteConfig = this.consumersiteConfig.find(x => x.id === params.get('websiteId'));
    });
  }

  ngOnDestroy(): void {
    if (this.scheduleSubscription) {
      this.scheduleSubscription.unsubscribe();
    }
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  schedulerDateChanged(event: DateChangeEvent): void {
    this.dateRangeStart = event.dateRange.start;
    this.dateRangeEnd = event.dateRange.end;
    this.refreshEvents();
  }

  eventClicked(event: EventClickEvent): void {
    if (event.originalEvent.button === 0 && event.event.saleColor === 'promo') {
      this.router.navigate([
        'website-management',
        this.websiteConfig.id,
        'promotion-management',
        event.event.dataItem.promotion.id
      ]);
    }
  }

  getEventType(event: SchedulerEvent): 'Sale' | 'ActivePromo' | 'InactivePromo' {
    return event.dataItem.promotion
      ? (event.dataItem.promotion.active ? 'ActivePromo' : 'InactivePromo')
      : 'Sale';
  }

  getEventClass(args: EventStyleArgs): string {
    return args.event.dataItem.saleColor === 'promo' ? 'event-promo' : '';
  }

  refreshEvents(): void {
    this.scheduleLoading = true;

    this.scheduleSubscription = this.promotionManagement
      .getSchedule(this.websiteConfig.id, this.dateRangeStart, this.dateRangeEnd)
      .subscribe(result => {
        this.scheduleLoading = false;
        const perc33Sales: SchedulerEvent[] = result.perc33Sales.map(x => this.createEventFromColorSale(x, '33% Off'));
        const perc50Sales: SchedulerEvent[] = result.perc50Sales.map(x => this.createEventFromColorSale(x, '50% Off'));
        const promotions: SchedulerEvent[] = result.promotions
          .filter(x => !(!x.active && this.activeOnly))
          .map(x => {
            const end = new Date(x.end);

            return {
              title: x.description,
              start: new Date(x.start),
              end: new Date(end.getFullYear(), end.getMonth(), end.getDate(), 23, 59, 59, 999),
              isAllDay: false,
              saleColor: 'promo',
              promotion: x
            };
          });

        this.events = [].concat(perc33Sales, perc50Sales, promotions);
        this.refreshSchedulerHeight();
      });
  }

  private createEventFromColorSale(sale: ColorSale, saleDesc: string): PromotionSchedulerEvent {
    return {
      title: `${PriceColor[sale.color]} ${saleDesc}`,
      start: new Date(sale.start),
      end: new Date(new Date(sale.end).getTime() + 24 * 60 * 60),
      isAllDay: false,
      saleColor: sale.color
    };
  }

  private refreshSchedulerHeight() {
    const counts: number[] = [];
    for (const dt = new Date(this.dateRangeStart); dt < this.dateRangeEnd; dt.setDate(dt.getDate() + 1)) {
      counts.push(this.events.filter(x => x.start <= dt && x.end >= dt).length);
    }

    const maxEventPerDay = counts.reduce((acc, cur) => Math.max(cur, acc), 0);
    this.schedulerHeight = ((this.eventHeight + 2) * maxEventPerDay + 45) * 6 + 111;
  }
}
