import { Subscription } from 'rxjs';
import { CONSUMERSITE_CONFIG, WINDOW } from 'src/app/injection-tokens';
import { PressItem } from 'src/app/models/website-management/press-item';
import { WebsiteConfig } from 'src/app/models/website-management/website-config';
import { PressItemManagementService } from 'src/app/services/website-management/press-item-management.service';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-press-items',
  templateUrl: './press-items.component.html',
  styleUrls: ['./press-items.component.scss']
})
export class PressItemsComponent implements OnInit, OnDestroy {
  websiteConfig: WebsiteConfig;
  data: GridDataResult;
  loading: boolean;
  skip = 0;

  readonly pageSize = 10;

  private routeSubscription: Subscription;
  private pressItemSubscription: Subscription;

  constructor(
    private pressItemManagement: PressItemManagementService,
    private route: ActivatedRoute,
    @Inject(CONSUMERSITE_CONFIG) private consumersitesConfig: WebsiteConfig[],
    @Inject(WINDOW) private window: Window
  ) { }

  ngOnInit(): void {
    this.routeSubscription = this.route.paramMap.subscribe(params => {
      this.websiteConfig = this.consumersitesConfig.find(x => x.id === params.get('websiteId'));
      this.loadPressItems();
    })
  }

  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    if (this.pressItemSubscription) {
      this.pressItemSubscription.unsubscribe();
    }
  }

  pageChanged(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadPressItems();
  }

  isExpired(pressItem: PressItem): boolean {
    return pressItem.expirationDate < new Date();
  }

  previewPressItems(): void {
    const url = `${this.websiteConfig.baseUrl}/in-the-news`;
    this.window.open(url, 'preview')
  }

  private loadPressItems(): void {
    if (this.pressItemSubscription) {
      this.pressItemSubscription.unsubscribe();
      this.pressItemSubscription = undefined;
    }

    this.loading = true;
    this.pressItemSubscription = this.pressItemManagement
      .getPressItems(this.skip, this.pageSize, this.websiteConfig.id)
      .subscribe(result => {
        this.data = {
          data: result.items,
          total: result.itemCount
        };
        this.loading = false;
      });
  }
}
