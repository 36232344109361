<header class="row justify-content-between">
  <h2 class="col-auto">Press Items</h2>
  <div class="col-auto">
    <button type="button" class="btn btn-link" (click)="previewPressItems()">
      <span class="k-icon k-i-preview"></span>
      Preview
    </button>
    <a [routerLink]="['/website-management', websiteConfig.id, 'press-item-management']" class="btn btn-link">
      <span class="k-icon k-i-plus"></span>
      New Press Item
    </a>
  </div>
</header>

<kendo-grid
  [data]="data"
  [pageSize]="pageSize"
  [skip]="skip"
  [pageable]="true"
  [loading]="loading"
  scrollable="none"
  (pageChange)="pageChanged($event)">
  <kendo-grid-column field="title" title="Title">
  </kendo-grid-column>
  <kendo-grid-column title="Category">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.category | humanize }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Resource">
    <ng-template kendoGridCellTemplate let-dataItem>
      <a *ngIf="dataItem.resourceType === 'Url'"
        [href]="dataItem.resource"
        [title]="dataItem.resource"
        target="_blank"
        class="text-primary">
        External Link
        <span class="k-icon k-i-hyperlink-open"></span>
      </a>
      <a *ngIf="dataItem.resourceType === 'Document'"
        [href]="dataItem.resource"
        [target]="dataItem.resource"
        download
        class="text-primary">
        Document
        <span class="k-icon k-i-download"></span>
      </a>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="source" title="Source" media="xl">
  </kendo-grid-column>
  <kendo-grid-column field="publishDate" title="Published" format="d">
  </kendo-grid-column>
  <kendo-grid-column title="Expires">
    <ng-template kendoGridCellTemplate let-dataItem class="text-danger">
      <span [class.text-danger]="isExpired(dataItem)">{{ dataItem.expirationDate | date:'M/d/yy h:mm a' }}</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column>
    <ng-template kendoGridCellTemplate let-dataItem>
      <a [routerLink]="['/website-management', websiteConfig.id, 'press-item-management', dataItem.id]"
        class="text-primary">
        <span class="k-icon k-i-edit"></span>
        Edit
      </a>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
