import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appSlidePreviewSwitch]'
})
export class SlidePreviewSwitchDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}
