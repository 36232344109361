<ul class="list-unstyled">
  <li>
    <label>Left Image</label>
    <div class="ml-2">
      <app-single-image-slide-preview
        [slide]="slide.left"
        [componentId]="componentId"
        [websiteId]="websiteId">
      </app-single-image-slide-preview>
    </div>
  </li>
  <li>
    <label>Right Image</label>
    <div class="ml-2">
      <app-single-image-slide-preview
        [slide]="slide.right"
        [componentId]="componentId"
        [websiteId]="websiteId">
      </app-single-image-slide-preview>
    </div>
  </li>
</ul>
