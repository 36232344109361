import { fromEvent, Observable, Subscription } from 'rxjs';
import { CanComponentDeactivate } from 'src/app/guards/can-deactivate.guard';
import { CONSUMERSITE_CONFIG } from 'src/app/injection-tokens';
import { fileSelectRestrictions as restrictions } from 'src/app/models/website-management/file-select-restrictions';
import { Promo } from 'src/app/models/website-management/promo';
import { WebsiteConfig } from 'src/app/models/website-management/website-config';
import { HeaderService } from 'src/app/services/header.service';
import { PromotionManagementService } from 'src/app/services/website-management/promotion-management.service';
import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '@progress/kendo-angular-notification';

@Component({
  selector: 'app-promotion-management',
  templateUrl: './promotion-management.component.html',
  styleUrls: ['./promotion-management.component.scss']
})
export class PromotionManagementComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  promotion: Promo;
  savedPromo: string;
  smallImage: HTMLImageElement;
  largeImage: HTMLImageElement;
  fileSelectRestrictions = restrictions;
  loading = false;
  abandonDialogOpened = false;

  previewDialogOpened = false;
  previewDialogWidth: number;
  previewDialogTitle: string;
  previewDialogImage: HTMLImageElement;
  websiteConfig: WebsiteConfig;

  private routeParamSubscription: Subscription;
  private fragmentSubscription: Subscription;
  private promotionSubscription: Subscription;
  private smallImageSubscription: Subscription;
  private largeImageSubscription: Subscription;

  readonly recommendedImageSize = '1140x641';
  readonly promotionTypes = ['Sale', 'Promotion'];

  constructor(
    private promotionManagement: PromotionManagementService,
    private route: ActivatedRoute,
    private router: Router,
    private header: HeaderService,
    private notification: NotificationService,
    @Inject(CONSUMERSITE_CONFIG) private consumersiteConfig: WebsiteConfig[]
  ) { }

  ngOnInit(): void {
    this.routeParamSubscription = this.route.paramMap.subscribe(params => {
      const websiteId = params.get('websiteId');
      this.websiteConfig = this.consumersiteConfig.find(x => x.id === websiteId);
      this.header.title = `Promotion Management for ${this.websiteConfig.name}`;

      const promotionIdText = params.get('promotionId');
      if (!promotionIdText) {
        this.createNewPromo();
      } else {
        this.fragmentSubscription = this.route.fragment.subscribe(fragment => {
          const promotionId = parseInt(promotionIdText, 10);
          this.loadPromo(promotionId, fragment === 'new');
        });
      }
    });
  }

  ngOnDestroy(): void {
    if (this.routeParamSubscription) {
      this.routeParamSubscription.unsubscribe();
    }
    if (this.fragmentSubscription) {
      this.fragmentSubscription.unsubscribe();
    }
    if (this.promotionSubscription) {
      this.promotionSubscription.unsubscribe();
    }
    if (this.smallImageSubscription) {
      this.smallImageSubscription.unsubscribe();
    }
    if (this.largeImageSubscription) {
      this.largeImageSubscription.unsubscribe();
    }
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.dirty) {
      return confirm('You have unsaved changes that will lost if you leave. Are you sure you want to leave?');
    }
    return true;
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification(event: Event): void {
    if (this.dirty) {
      event.returnValue = true;
    }
  }

  get promotionStart(): Date {
    return new Date(this.promotion.start);
  }
  set promotionStart(value: Date) {
    this.promotion.start = value.toISOString();
  }

  get promotionEnd(): Date {
    return new Date(this.promotion.end);
  }
  set promotionEnd(value: Date) {
    this.promotion.end = value.toISOString();
  }

  savePromotion(): void {
    this.loading = true;
    this.promotionManagement.savePromotion(this.websiteConfig.id, this.promotion).subscribe(promo => {
      const created = this.promotion.id === 0;

      this.promotion = promo;
      this.savedPromo = JSON.stringify(promo);

      if (created) {
        this.router.navigate(
          [ 'website-management', this.websiteConfig.id, 'promotion-management', promo.id ],
          { fragment: 'new' });
      } else {
        this.loading = false;
        this.notification.show({
          content: 'This promotion has been saved.',
          animation: { type: 'slide', duration: 350 },
          position: { horizontal: 'center', vertical: 'top' },
          type: { style: 'success', icon: true },
          hideAfter: 3000
        });
      }
    });
  }

  abandonChanges(): void {
    this.abandonDialogOpened = true;
  }

  abandonDialogClick(value: boolean): void {
    if (value) {
      this.promotion = JSON.parse(this.savedPromo);
      this.loadSmallImage();
      this.loadLargeImage();
    }

    this.abandonDialogOpened = false;
  }

  selectPromoTypeChanged(promoType: string): void {
    if (promoType === 'Promotion') {
      this.promotion.overrideSaleColors = false;
    }
  }

  imageChanged(files: File[]): void {
    if (!files || !files.length) {
      this.promotion.imageSrcSmall = '';
      this.promotion.imageSrcLarge = '';
      return;
    }

    this.loading = true;
    this.promotionSubscription = this.promotionManagement.saveAsset(this.websiteConfig.id, files[0])
      .subscribe(result => {
        this.promotion.imageSrcSmall = result.smallImageFilename;
        this.promotion.imageSrcLarge = result.largeImageFilename;

        const smallImage = new Image();
        fromEvent(smallImage, 'load').subscribe(() => this.smallImage = smallImage);
        smallImage.src = result.smallImageUrl;

        const largeImage = new Image();
        fromEvent(largeImage, 'load').subscribe(() => this.largeImage = largeImage);
        largeImage.src = result.largeImageUrl;

        this.loading = false;
      });
  }

  previewSmallImage(): void {
    this.previewDialogOpened = true;
    this.previewDialogWidth = 575;
    this.previewDialogTitle = 'Preview Small Image';
    this.previewDialogImage = this.smallImage;
  }

  previewLargeImage(): void {
    this.previewDialogOpened = true;
    this.previewDialogWidth = 1140;
    this.previewDialogTitle = 'Preview Large Image';
    this.previewDialogImage = this.largeImage;
  }

  private createNewPromo() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

    const promo: Promo = {
      id: 0,
      promotionType: 'Sale',
      description: '',
      start: today.toISOString(),
      end: today.toISOString(),
      active: true,
      imageSrcSmall: undefined,
      imageSrcLarge: undefined,
      dateCreated: undefined,
      createdBy: undefined,
      navigateUrl: '/sales-and-specials',
      overrideSaleColors: false
    };

    this.promotion = promo;
    this.savedPromo = JSON.stringify(promo);
  }

  private loadPromo(promotionId: number, newPromo: boolean) {
    this.loading = true;

    this.promotionSubscription = this.promotionManagement
      .getPromotion(this.websiteConfig.id, promotionId)
      .subscribe(promo => {
        this.promotion = promo;
        this.savedPromo = JSON.stringify(promo);
        this.loadSmallImage();
        this.loadLargeImage();

        this.loading = false;

        if (newPromo) {
          this.notification.show({
            content: 'This promotion has been created.',
            animation: { type: 'slide', duration: 350 },
            position: { horizontal: 'center', vertical: 'top' },
            type: { style: 'success', icon: true },
            hideAfter: 3000
          });
        }
      });
  }

  private loadSmallImage() {
    if (!this.promotion.imageSrcSmall) {
      this.smallImage = undefined;
      return;
    }

    this.smallImageSubscription = this.promotionManagement
      .getAsset(this.websiteConfig.id, this.promotion.imageSrcSmall)
      .subscribe(src => {
        const image = new Image();
        fromEvent(image, 'load').subscribe(() => this.smallImage = image);
        image.src = src;
      });
  }

  private loadLargeImage() {
    if (!this.promotion.imageSrcLarge) {
      this.largeImage = undefined;
      return;
    }

    this.largeImageSubscription = this.promotionManagement
      .getAsset(this.websiteConfig.id, this.promotion.imageSrcLarge)
      .subscribe(src => {
        const image = new Image();
        fromEvent(image, 'load').subscribe(() => this.largeImage = image);
        image.src = src;
      });
  }

  get dirty(): boolean {
    return JSON.stringify(this.promotion) !== this.savedPromo;
  }
}
