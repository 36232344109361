import { Observable, Subscription } from 'rxjs';
import { CanComponentDeactivate } from 'src/app/guards/can-deactivate.guard';
import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CONSUMERSITE_CONFIG, WINDOW } from '../../injection-tokens';
import { ComponentHistoryItem } from '../../models/website-management/component-history-item';
import { WebsiteComponent } from '../../models/website-management/website-component';
import { WebsiteConfig } from '../../models/website-management/website-config';
import { HeaderService } from '../../services/header.service';
import { ContentManagementService } from '../../services/website-management/content-management.service';

@Component({
  selector: 'app-content-management',
  templateUrl: './content-management.component.html',
  styleUrls: ['./content-management.component.scss']
})
export class ContentManagementComponent implements OnInit, OnDestroy, CanComponentDeactivate {

  private websiteId: string;
  private componentId: string;
  private savedDraft: string;

  private componentSubscription: Subscription;
  private componentHistorySubscription: Subscription;

  readonly dateTimeFormat = 'M/d/yyyy h:mm a';

  websiteConfig: WebsiteConfig;
  component: WebsiteComponent;
  activeContent: ComponentHistoryItem[];
  componentHistory: ComponentHistoryItem[];
  draft: ComponentHistoryItem;

  deleteDialogOpened = false;
  publishDialogOpened = false;
  abandonDialogOpened = false;
  deactivateDialogOpened = false;
  loading = true;

  constructor(
    private route: ActivatedRoute,
    private contentManagement: ContentManagementService,
    private header: HeaderService,
    @Inject(WINDOW) private window: Window,
    @Inject(CONSUMERSITE_CONFIG) private consumersiteConfig: WebsiteConfig[]
  ) { }

  ngOnInit(): void {

    this.route.paramMap.subscribe(params => {
      this.websiteId = params.get('websiteId');
      this.componentId = params.get('componentId');

      this.websiteConfig = this.consumersiteConfig.find(x => x.id === this.websiteId);
      this.updateHistory();
    });
  }

  ngOnDestroy(): void {
    if (this.componentSubscription) {
      this.componentSubscription.unsubscribe();
    }
    if (this.componentHistorySubscription) {
      this.componentHistorySubscription.unsubscribe();
    }
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.dirty) {
      return confirm('You have unsaved changes that will be lost if you leave. Are you sure you want to leave?');
    }
    return true;
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification(event: Event): void {
    if (this.dirty) {
      event.returnValue = true;
    }
  }

  createDraft(id: number): void {
    this.loading = true;
    this.contentManagement.createDraftComponent(this.websiteId, this.componentId, id).subscribe(status => {
      this.loading = false;
      if (status) {
      this.updateHistory();
      } else {
        console.error('Unable to create component draft');
      }
    });
  }

  saveAndPreviewDraft(): void {
    this.saveDraft(() => this.previewComponent());
  }

  saveDraft(fn: () => void): void {
    this.loading = true;
    this.contentManagement.saveDraftComponent(this.websiteId, this.componentId, this.draft).subscribe(status => {
      this.loading = false;
      if (status) {
        this.savedDraft = JSON.stringify(this.draft);
        if (fn) {
          fn.call(this);
        }
      } else {
        console.error('Unable to save draft component');
      }
    });
  }

  abandonDraft(): void {
    this.abandonDialogOpened = true;
  }

  abandonDialogClick(confirmed: boolean): void {
    if (confirmed) {
      this.draft = JSON.parse(this.savedDraft);
    }

    this.abandonDialogOpened = false;
  }

  deleteDraft(): void {
    this.deleteDialogOpened = true;
  }

  deleteDialogClick(confirmed: boolean): void {
    if (!confirmed) {
      this.deleteDialogOpened = false;
      return;
    }

    this.loading = true;
    this.contentManagement.deleteDraftComponent(this.websiteId, this.componentId).subscribe(() => {
      this.updateHistory();
      this.deleteDialogOpened = false;
    });
  }

  publishDraft(): void {
    this.publishDialogOpened = true;
  }

  publishDialogClick(confirmed: boolean): void {
    if (!confirmed) {
      this.publishDialogOpened = false;
      return;
    }

    this.loading = true;
    this.contentManagement.publishDraft(this.websiteId, this.componentId).subscribe(() => {
      this.updateHistory();
      this.publishDialogOpened = false;
    });
  }

  deactivateContent(): void {
    this.deactivateDialogOpened = true;
  }

  deactivateContentClick(confirmed: boolean): void {
    if (!confirmed || !this.activeContent || !this.activeContent.length) {
      this.deactivateDialogOpened = false;
      return;
    }

    this.loading = true;
    const contentId = this.activeContent[0].id;

    this.contentManagement.deactivate(this.websiteId, this.componentId, contentId).subscribe(() => {
      this.updateHistory();
      this.deactivateDialogOpened = false;
    });
  }

  previewComponent(): void {
    const url = `${this.websiteConfig.baseUrl}${this.component.previewUrl}?preview=${this.component.componentId}`;
    this.window.open(url, 'preview');
  }

  publishStartClick(value: boolean): void {
    if (!value) {
      this.draft.publishStart = null;
    } else if (!this.draft.publishStart) {
      this.draft.publishStart = this.today;
    }
  }

  publishStartChange(value: Date): void {
    this.draft.publishStart = value;
  }

  publishEndClick(value: boolean): void {
    if (!value) {
      this.draft.publishEnd = null;
    } else if (!this.draft.publishEnd) {
      this.draft.publishEnd = this.today;
    }
  }

  publishEndChange(value: Date): void {
    this.draft.publishEnd = value;
  }

  get dirty(): boolean {
    return JSON.stringify(this.draft) !== this.savedDraft;
  }

  private get today(): Date {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate());
  }

  private updateHistory() {
    this.loading = true;
    this.componentSubscription = this.contentManagement.getComponent(this.websiteId, this.componentId)
    .subscribe(component => {
      this.componentSubscription = undefined;
      this.component = component;
      this.header.title = `Content Management ${component.name}`;

      this.componentHistorySubscription = this.contentManagement.getComponentHistory(this.websiteId, this.componentId)
        .subscribe(history => {
          this.loading = false;

          const draftIndex = history.findIndex(x => x.draft);
          if (draftIndex >= 0 ) {
            const draft = history.splice(draftIndex, 1)[0];
            if (draft.publishStart) {
              draft.publishStart = new Date(draft.publishStart);
            }
            if (draft.publishEnd) {
              draft.publishEnd = new Date(draft.publishEnd);
            }
            this.draft = draft;
          } else {
            this.draft = undefined;
          }

          this.activeContent = undefined;
          if (!this.component.cascades && history.length) {
            const activeId = history
              .map(x => x.id)
              .reduce((acc, val) => val > acc ? val : acc);
            const activeIndex = history.findIndex(x => x.id === activeId);
            if (activeIndex >= 0) {
              const activeContent = history[activeIndex];
              if (activeContent.active) {
                this.activeContent = history.splice(activeIndex, 1);
              }
            }
          }

          this.savedDraft = JSON.stringify(this.draft);
          this.componentHistory = history;
        });
    });
  }
}
