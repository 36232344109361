<ul class="list-unstyled">
  <li *ngIf="slide.imageSrcSmall">
    <label>Small Image</label>
    <button type="button"
      class="btn btn-link p-0"
      (click)="openImageSmallModal()">
      {{ slide.imageSrcSmall }}
      <span class="k-icon k-i-hyperlink-open-sm"></span>
    </button>
    <kendo-dialog *ngIf="imageSmall"
      [title]="slide.imageSrcSmall"
      [maxWidth]="500"
      (close)="imageSmall = undefined">
      <img *ngIf="imageSmall" class="img-fluid" [alt]="slide.imageSrcSmall" [src]="imageSmall">
    </kendo-dialog>
  </li>
  <li *ngIf="slide.imageSrcLarge">
    <label>Large Image</label>
    <button type="button"
      class="btn btn-link p-0"
      (click)="openImageLargeModal()">
      {{ slide.imageSrcLarge }}
      <span class="k-icon k-i-hyperlink-open-sm"></span>
    </button>
    <kendo-dialog *ngIf="imageLarge"
      [title]="slide.imageSrcLarge"
      [maxWidth]="1140"
      (close)="imageLarge = undefined">
      <img *ngIf="imageLarge" class="img-fluid" [alt]="slide.imageSrcLarge" [src]="imageLarge">
    </kendo-dialog>
  </li>
  <li>
    <label>Alternate Text</label>
    {{ slide.imageAlt }}
  </li>
  <li>
    <label>Link to URL</label>
    <a [href]="navigateUrl" target="_blank">{{ slide.navigateUrl }}</a>
  </li>
  <li>
    <label>Open Link in New Window</label>
    <span *ngIf="slide.navigateTarget" class="h3 text-success k-icon k-i-check">
      <span class="sr-only">Yes</span>
    </span>
    <span *ngIf="!slide.navigateTarget" class="h3 text-error k-icon k-i-x">
      <span class="sr-only">No</span>
    </span>
  </li>
</ul>
