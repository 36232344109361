import { Directive } from '@angular/core';
import { UntypedFormGroup, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: '[appServiceAreaFormValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: ServiceAreaFormValidatorDirective,
      multi: true
    }
  ]
})
export class ServiceAreaFormValidatorDirective implements Validator {
  validate(formGroup: UntypedFormGroup): ValidationErrors {
    if ('serviceAreaType' in formGroup.controls) {
      const serviceAreaType = formGroup.controls.serviceAreaType.value;

      if (serviceAreaType === 'major' && 'city' in formGroup.controls && 'state' in formGroup.controls) {
        const city = formGroup.controls.city.value ?? '';
        const state = formGroup.controls.state.value ?? '';

        if (city.trim().length < 1 || state.trim().length !== 2) {
          return { cityStateRequired: true };
        }
      } else if (serviceAreaType === 'minor' && 'address' in formGroup.controls) {
        const address = formGroup.controls.address.value ?? '';

        if (address.trim().length < 1) {
          return { serviceAreaRequired: true };
        }
      }
    }
    return null;
  }
}
