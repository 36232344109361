<div class="form-group">
  <label [for]="summary">Summary</label>
  <input #summary
    kendoTextBox
    [(ngModel)]="content.summary"
    [disabled]="disabled"
    class="w-100">
</div>
<div class="form-group">
  <label>HTML Content</label>
  <app-html-editor
    [(content)]="content.htmlContent"
    [disabled]="disabled">
  </app-html-editor>
</div>
