<app-loading-spinner *ngIf="!dropoffLocation">
</app-loading-spinner>

<form *ngIf="dropoffLocation && googleApiLoaded$ | async"
  #dropoffLocationForm="ngForm"
  appValidateGeocoordinate
  class="container-fluid dropoff-location"
  novalidate>
  <div class="row justify-content-end mb-4">
    <div class="col-auto">
      <a class="font-weight-bold" [routerLink]="['/website-management', websiteConfig.id, 'dropoff-locations']">
        <span class="k-icon k-i-arrow-left"></span>
        Return to website management
      </a>
    </div>
  </div>
  <div class="row justify-content-end">
    <div class="col-auto">
      <button type="button"
        class="btn btn-primary"
        [disabled]="!dirty || loading || !dropoffLocationForm.valid"
        (click)="saveDropoffLocation()">
        <span class="k-icon k-i-save"></span>
        Save Drop-off Location
      </button>
    </div>
    <div class="col-auto">
      <button type="button"
        class="btn btn-secondary"
        [disabled]="!dirty || loading"
        (click)="abandonChanges()">
        <span class="k-icon k-i-cancel"></span>
        Abandon Changes
      </button>
    </div>
    <div class="col-auto">
      <a [href]="websiteConfig.baseUrl + '/find-a-location/' + dropoffLocation.pageSlug"
        class="btn btn-secondary"
        (click)="previewDropoffLocation($event)">
        <span class="k-icon k-i-preview"></span>
        Preview
      </a>
    </div>
  </div>

  <div class="form-group">
    <label for="name" class="font-weight-bold">Name</label>
    <input #name="ngModel"
      id="name"
      type="text"
      name="name"
      maxlength="255"
      required
      class="form-control w-100"
      [(ngModel)]="dropoffLocation.name"
      [disabled]="loading">
    <div *ngIf="name.errors?.required && (name.dirty || name.touched)" class="alert alert-danger">
      A name is required
    </div>
  </div>

  <div class="form-group mb-3">
    <div class="form-row justify-content-between">
      <div class="col">
        <label for="address" class="font-weight-bold">Address</label>
      </div>
      <div class="col-auto">
        <button *ngIf="googleApiLoaded$ | async"
          type="button"
          class="btn btn-secondary"
          [disabled]="loading || !address.valid || !city.valid"
          (click)="geolocate()">
          <span class="k-icon k-i-marker-pin-target"></span>
          Geolocate Address
        </button>
      </div>
    </div>

    <div class="form-row my-2">
      <div class="col">
        <input #address="ngModel"
          id="address"
          type="text"
          name="address"
          maxlength="255"
          required
          class="form-control w-100"
          [(ngModel)]="dropoffLocation.address"
          [disabled]="loading">
      </div>
    </div>
    <div class="form-row my-2">
      <div class="col">
        <input #address2="ngModel"
          id="address2"
          type="text"
          name="address2"
          maxlength="255"
          class="form-control w-100"
          [(ngModel)]="dropoffLocation.address2"
          [disabled]="loading">
      </div>
    </div>
    <div class="form-row my-2">
      <div class="col">
        <input #city="ngModel"
          id="city"
          type="text"
          name="city"
          maxlength="20"
          required
          class="form-control w-100"
          [(ngModel)]="dropoffLocation.city"
          [disabled]="loading">
      </div>
      <div class="col-auto">
        <kendo-dropdownlist #state
          name="state"
          [data]="states$ | async"
          textField="abbreviation"
          valueField="abbreviation"
          [(ngModel)]="dropoffLocation.state"
          [disabled]="loading">
        </kendo-dropdownlist>
      </div>
      <div class="col-auto">
        <input #zip="ngModel"
          id="zip"
          type="tel"
          name="zip"
          minlength="5"
          maxlength="5"
          required
          pattern="\d*"
          class="form-control"
          [style.width.em]="8"
          [(ngModel)]="dropoffLocation.zip"
          [disabled]="loading">
      </div>
    </div>
    <div *ngIf="address.errors?.required && (address.touched || address.dirty)" class="alert alert-danger">
      A street address is required.
    </div>
    <div *ngIf="city.errors?.required && (city.touched || city.dirty)" class="alert alert-danger">
      A city is required.
    </div>
    <div *ngIf="zip.invalid && (zip.touched || zip.dirty)" class="alert alert-danger">
      A zip code must contain 5 numeric digits.
    </div>
  </div>

  <div class="form-group mb-3">
    <label for="located-near" class="font-weight-bold">Located Near</label>
    <input #locatedNear
      id="located-near"
      type="text"
      name="locatedNear"
      maxlength="256"
      class="form-control w-100"
      [(ngModel)]="dropoffLocation.locatedNear"
      [disabled]="loading">
  </div>

  <div class="form-group mb-3">
    <input kendoCheckBoxButton #active
      type="checkbox"
      name="active"
      [(ngModel)]="dropoffLocation.active"
      [disabled]="loading">
    <label [for]="active" class="font-weight-bold ml-1">Active</label>
  </div>

  <div class="form-group mb-3">
    <label class="font-weight-bold">Map Coordinates</label>
    <div class="form-inline">
      <label [for]="latitude" class="font-weight-bold mr-2">Latitude</label>
      <kendo-numerictextbox #latitude
        name="latitude"
        [decimals]="5"
        format="n5"
        required
        [min]="minLatitude"
        [max]="maxLatitude"
        class="mr-4"
        [style.width.em]="12"
        [(ngModel)]="dropoffLocation.latitude"
        [disabled]="loading">
      </kendo-numerictextbox>

      <label [for]="longitude" class="font-weight-bold mr-2">Longitude</label>
      <kendo-numerictextbox #longitude
        name="longitude"
        [decimals]="5"
        format="n5"
        required
        [min]="minLongitude"
        [max]="maxLongitude"
        class="mr-2"
        [style.width.em]="12"
        [(ngModel)]="dropoffLocation.longitude"
        [disabled]="loading">
      </kendo-numerictextbox>

      <button type="button"
        class="btn btn-secondary"
        (click)="viewCoordinates()">
        <span class="k-icon k-i-globe"></span>
        View on Map
      </button>
    </div>
    <div class="form-row">
      <div *ngIf="!dropoffLocationForm.controls.latitude?.valid && (dropoffLocationForm.controls.latitude?.touched || dropoffLocationForm.controls.latitude?.dirty)"
        class="alert alert-danger">
        Latitude is a required value between {{ minLatitude }} and {{ maxLatitude }}.
      </div>
      <div *ngIf="!dropoffLocationForm.controls.longitude?.valid && (dropoffLocationForm.controls.longitude?.touched || dropoffLocationForm.controls.longitude?.dirty)"
        class="alert alert-danger">
        Longitude is a required value between {{ minLongitude }} and {{ maxLongitude }}.
      </div>
      <div *ngIf="dropoffLocationForm.errors?.invalidLatLng && dropoffLocationForm.controls.latitude?.touched && dropoffLocationForm.controls.longitude?.touched"
        class="alert alert-danger">
        The latitude and longitude provided does not exist in the United States.
      </div>
    </div>
  </div>

  <div class="form-group mb-3">
    <label for="page-slug" class="font-weight-bold">Page Slug</label>
    <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text" id="page-slug-base-url">{{ websiteConfig.baseUrl }}/find-a-location/</span>
      </div>
      <input #pageSlug="ngModel"
        id="page-slug"
        type="text"
        name="pageSlug"
        maxlength="255"
        class="form-control"
        required
        [appPageSlugValidator]="[websiteConfig.baseUrl + '/find-a-location', existingPageSlug]"
        [(ngModel)]="dropoffLocation.pageSlug"
        aria-describedby="page-slug-base-url"
        [disabled]="loading">
    </div>
    <div *ngIf="pageSlug.errors?.required && (pageSlug.dirty || pageSlug.touched)" class="alert alert-danger">
      Page Slug is required.
    </div>
    <div *ngIf="pageSlug.errors?.invalidSyntax" class="alert alert-danger">
      A page slug can only contain lower-case letters, numbers and the "-" character.
    </div>
    <div *ngIf="pageSlug.errors?.notUnique" class="alert alert-danger">
      The page slug entered already exists. Page slugs must be unique.
    </div>
    <div *ngIf="pageSlug.errors?.uniqueFailure" class="alert alert-danger">
      Unable to determine if the page slug is unqiue. Please try again later.
    </div>
  </div>

  <div class="form-group mb-3">
    <label class="d-block font-weight-bold">
      Image
      <span class="text-muted font-weight-normal">(Recommended Size: {{ recommendedImageSize }})</span>
    </label>
    <figure *ngIf="dropoffLocationImage" class="figure">
      <img
        [src]="dropoffLocationImage.src"
        alt="Preview Image"
        class="img-fluid preview-image">
    </figure>
    <kendo-fileselect
      [multiple]="false"
      [restrictions]="fileSelectRestrictions"
      [showFileList]="false"
      [disabled]="loading"
      (valueChange)="imageChanged($event)">
    </kendo-fileselect>
    <div *ngIf="viewImageRequiredAlert" class="alert alert-danger">
      An image is required.
    </div>
  </div>

  <div class="form-group mb-3">
    <label class="font-weight-bold">Charities</label>
    <kendo-multiselect name="charities"
      [data]="charities$ | async"
      [valuePrimitive]="true"
      [checkboxes]="true"
      [autoClose]="false"
      textField="dmsCharity.name"
      valueField="code"
      [(ngModel)]="dropoffLocation.charities">
    </kendo-multiselect>
  </div>

  <div class="form-group mb-3">
    <label class="font-weight-bold">Location Hours</label>
    <div class="row">
      <div class="col-12 col-sm-10 col-md-12 col-lg-10 col-xl-8">
        <div class="card">
          <app-location-hours #locationHours="ngModel"
            name="locationHours"
            [(ngModel)]="dropoffLocation.hours"
            [disabled]="loading">
          </app-location-hours>
        </div>
      </div>
    </div>

    <div *ngIf="locationHours.errors?.invalidHours" class="alert alert-danger">
      The opening time must occur before the closing time.
    </div>
  </div>

  <div class="form-group pb-5">
    <label for="pageContent" class="font-weight-bold">Page Content</label>
    <app-html-editor #pageContent
      [(content)]="dropoffLocation.pageContent"
      name="pageContent"
      [websiteConfig]="websiteConfig"
      [disabled]="loading">
    </app-html-editor>
  </div>
</form>

<kendo-dialog title="Abandon Changes" *ngIf="abandonDialogOpened" [minWidth]="250">
  <p>Are you sure you want to abandon the changes to this service area?</p>
  <kendo-dialog-actions>
    <button kendoButton (click)="abandonDialogClick(true)" [primary]="true">Yes</button>
    <button kendoButton (click)="abandonDialogClick(false)">No</button>
  </kendo-dialog-actions>
</kendo-dialog>

<kendo-dialog *ngIf="geolocateDialogOpened"
  title="Map Coordinates"
  [minWidth]="250"
  (close)="geolocateDialogOpened = false">
  <google-map
    height="350"
    [zoom]="16"
    [center]="geolocatedLatLng">
    <map-marker [position]="geolocatedLatLng"></map-marker>
  </google-map>

  <div class="form-inline">
    <label for="geolocated-latitude" class="font-weight-bold mr-2">Latitude</label>
    <input readonly
      id="geolocated-latitude"
      [value]="geolocatedLatLng.lat"
      class="form-control mr-4">

    <label for="geolocated-longitude" class="font-weight-bold mr-2">Longitude</label>
    <input readonly
      id="geolocated-longitude"
      [value]="geolocatedLatLng.lng"
      class="form-control">
  </div>

  <kendo-dialog-actions>
    <button kendoButton (click)="geolocateDialogOpened = false">Cancel</button>
    <button kendoButton (click)="useGeolocatedCoordinates()" [primary]="true">Use Coordinates</button>
  </kendo-dialog-actions>
</kendo-dialog>

<kendo-dialog *ngIf="viewCoordinatesDialogOpened"
  title="Map Coordinates"
  [minWidth]="250"
  (close)="viewCoordinatesDialogOpened = false">
  <google-map
    height="350"
    [zoom]="16"
    [center]="dropoffLocationLatLng">
    <map-marker [position]="dropoffLocationLatLng"></map-marker>
  </google-map>
</kendo-dialog>
