import { Observable, Subscriber, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  private title$: Observable<string>;
  private pageTitle = '';

  private observers: Subscriber<string>[] = [];

  constructor(private titleService: Title) {
    this.title$ = new Observable<string>(observer => {
      const observers = this.observers;
      observers.push(observer);
      observer.next(this.title);

      return {
        unsubscribe() {
          observers.splice(observers.indexOf(observer), 1);
        }
      };
    });
  }

  subscribe(next?: (value: string) => void, error?: (err: Error) => void): Subscription {
    return this.title$.subscribe(next, error);
  }

  get title(): string {
    return this.pageTitle;
  }

  set title(value: string) {
    if (this.pageTitle !== value) {
      this.pageTitle = value;
      this.setHtmlTitle(value);
      this.observers.forEach(o => o.next(value));
    }
  }

  private setHtmlTitle(value: string) {
    const title = value ? `${value} | Admin` : 'Admin';
    this.titleService.setTitle(title);
  }
}
