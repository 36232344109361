import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CONSUMERSITE_CONFIG } from 'src/app/injection-tokens';
import { ServiceArea } from 'src/app/models/website-management/service-area';
import { WebsiteConfig } from 'src/app/models/website-management/website-config';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ServiceAreaManagementService {

  constructor(
    private http: HttpClient,
    @Inject(CONSUMERSITE_CONFIG) private consumersiteConfig: WebsiteConfig[]
  ) { }

  getServiceArea(id: number, websiteId: string): Observable<ServiceArea> {
    const url = `${this.getBaseUrl(websiteId)}/service-area/${id}`;
    return this.http.get<ServiceArea>(url);
  }

  getServiceAreas(websiteId: string): Observable<ServiceArea[]> {
    const url = `${this.getBaseUrl(websiteId)}/service-areas`;
    return this.http.get<ServiceArea[]>(url);
  }

  createServiceArea(serviceArea: ServiceArea, websiteId: string): Observable<ServiceArea> {
    const url = `${this.getBaseUrl(websiteId)}/service-area`;
    return this.http.post<ServiceArea>(url, serviceArea)
      .pipe(
        catchError(() => {
          throw new Error('Failure creating new service area');
        })
      );
  }

  updateServiceArea(serviceArea: ServiceArea, websiteId: string): Observable<boolean> {
    const url = `${this.getBaseUrl(websiteId)}/service-area/${serviceArea.id}`;

    return this.http.put<boolean>(url, serviceArea)
      .pipe(
        map(() => true),
        catchError(() => {
          throw new Error('Failure updating service area');
        })
      );
  }

  updateServiceAreaSortOrder(ids: number[], websiteId: string): Observable<boolean> {
    const url = `${this.getBaseUrl(websiteId)}/service-areas/sort-order`;

    return this.http.post<boolean>(url, ids)
      .pipe(
        map(() => true),
        catchError(() => {
          throw new Error('Failure updating service area sort orders');
        })
      );
  }

  private getBaseUrl(websiteId: string): string {
    const config = this.consumersiteConfig.find(x => x.id === websiteId);
    return `${config.baseUrl}/service-area-management`;
  }
}
