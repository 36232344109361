<div class="container-fluid">
  <ul class="nav nav-pills mb-4" role="tablist">
    <li class="nav-item">
      <a class="nav-link"
        [class.active]="isTabActive('components')"
        id="pills-tab-components"
        [routerLink]="createRelativeHref('components')"
        role="tab"
        aria-controls="pills-components"
        aria-selected="isTabActive('components')">
        Content Management
      </a>
    </li>
    <ng-template [ngIf]="websiteConfig.id === 'SecondAve'">
      <li class="nav-item">
        <a class="nav-link"
          [class.active]="isTabActive('calendar')"
          id="pills-tab-calendar"
          [routerLink]="createRelativeHref('calendar')"
          role="tab"
          aria-controls="pills-calendar"
          aria-selected="isTabActive('calendar')">
          Sales &amp; Promotions
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link"
          [class.active]="isTabActive('stores')"
          id="pills-tab-stores"
          [routerLink]="createRelativeHref('stores')"
          role="tab"
          aria-controls="pills-stores"
          aria-selected="isTabActive('stores')">
          Stores
        </a>
      </li>
    </ng-template>
    <ng-template [ngIf]="websiteConfig.id === 'GoGreenDrop'">
      <li class="nav-item">
        <a class="nav-link"
          [class.active]="isTabActive('charities')"
          id="pills-tab-charities"
          [routerLink]="createRelativeHref('charities')"
          role="tab"
          aria-controls="pills-charities"
          aria-selected="isTabActive('charities')">
          Charities
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link"
          [class.active]="isTabActive('dropoff-locations')"
          id="pills-tab-dropoff-locations"
          [routerLink]="createRelativeHref('dropoff-locations')"
          role="tab"
          aria-controls="pills-dropoff-locations"
          aria-selected="isTabActive('dropoff-locations')">
          Drop-off Locations
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link"
          [class.active]="isTabActive('service-areas')"
          id="pills-tab-service-areas"
          [routerLink]="createRelativeHref('service-areas')"
          role="tab"
          aria-controls="pills-service-areas"
          aria-selected="isTabActive('service-areas')">
          Service Areas
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link"
          [class.active]="isTabActive('press-items')"
          id="pills-tab-press-items"
          [routerLink]="createRelativeHref('press-items')"
          role="tab"
          aria-controls="pills-press-items"
          aria-selected="isTabActive('press-items')">
          Press Items
        </a>
      </li>
    </ng-template>
  </ul>
  <div class="tab-content">
    <div *ngIf="isTabActive('components')"
      class="tab-pane show active"
      id="pills-components"
      role="tabpanel"
      aria-labelledby="pills-tab-components">
      <app-components></app-components>
    </div>
    <ng-template [ngIf]="websiteConfig.id === 'SecondAve'">
      <div *ngIf="isTabActive('calendar')"
        class="tab-pane show active"
        id="pills-calendar"
        role="tabpanel"
        aria-labelledby="pills-tab-calendar">
        <app-promotion-calendar></app-promotion-calendar>
      </div>
      <div *ngIf="isTabActive('stores')"
        class="tab-pane show active"
        id="pills-stores"
        role="tabpanel"
        aria-labelledby="pills-tab-stores">
        <app-stores></app-stores>
      </div>
    </ng-template>
    <ng-template [ngIf]="websiteConfig.id === 'GoGreenDrop'">
      <div *ngIf="isTabActive('charities')"
        class="tab-pane show active"
        id="pills-charities"
        role="tabpanel"
        aria-labelledby="pills-tab-charities">
        <app-charities></app-charities>
      </div>
      <div *ngIf="isTabActive('dropoff-locations')"
        class="tab-pane show active"
        id="pills-dropoff-locations"
        role="tabpanel"
        aria-labelledby="pills-tab-dropoff-locations">
        <app-dropoff-locations></app-dropoff-locations>
      </div>
      <div *ngIf="isTabActive('service-areas')"
        class="tab-pane show active"
        id="pills-service-areas"
        role="tabpanel"
        aria-labelledby="pills-tab-service-areas">
        <app-service-areas></app-service-areas>
      </div>
      <div *ngIf="isTabActive('press-items')"
        class="tab-pane show active"
        id="pills-press-items"
        role="tabpanel"
        aria-labelledby="pills-tab-press-items">
        <app-press-items></app-press-items>
      </div>
    </ng-template>
  </div>
</div>
