import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { GOOGLE_API_KEY } from '../injection-tokens';

@Injectable({
  providedIn: 'root'
})
export class GoogleMapsService {
  private _requested = false;
  private loaded$ = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    @Inject(GOOGLE_API_KEY) private googleApiKey: string
  ) { }

  get loaded(): Observable<boolean> {
    if (!this._requested) {
      this._requested = true;
      const subscription = this.http.jsonp(this.url, 'callback')
        .pipe(
          map(() => true),
          catchError(() => of(false))
        )
        .subscribe(result => {
            this.loaded$.next(result);
            subscription.unsubscribe();
        });
    }

    return this.loaded$;
  }

  private get url(): string {
    return `https://maps.googleapis.com/maps/api/js?key=${this.googleApiKey}`;
  }
}
