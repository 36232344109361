import { Subscription } from 'rxjs';
import { CONSUMERSITE_CONFIG, WINDOW } from 'src/app/injection-tokens';
import { WebsiteComponent } from 'src/app/models/website-management/website-component';
import { WebsiteConfig } from 'src/app/models/website-management/website-config';
import { ContentManagementService } from 'src/app/services/website-management/content-management.service';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-components',
  templateUrl: './components.component.html',
  styleUrls: ['./components.component.scss']
})
export class ComponentsComponent implements OnInit, OnDestroy {
  websiteConfig: WebsiteConfig;
  components: WebsiteComponent[];
  loading = true;

  private componentSubscription: Subscription;
  private routeSubscription: Subscription;

  constructor(
    private contentManagement: ContentManagementService,
    private route: ActivatedRoute,
    @Inject(CONSUMERSITE_CONFIG) private consumersiteConfig: WebsiteConfig[],
    @Inject(WINDOW) private window: Window
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.websiteConfig = this.consumersiteConfig.find(x => x.id === params.get('websiteId'));
      this.loading = true;

      this.componentSubscription = this.contentManagement.getComponents(this.websiteConfig.id)
        .subscribe(components => {
          this.loading = false;
          this.components = components;
        });
    });
  }

  ngOnDestroy(): void {
    if (this.componentSubscription) {
      this.componentSubscription.unsubscribe();
    }
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  previewComponent(component: WebsiteComponent): void {
    const url = `${this.websiteConfig.baseUrl}${component.previewUrl}`;
    this.window.open(url, 'preview');
  }
}
