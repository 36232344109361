import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '@progress/kendo-angular-notification';
import { Observable, Subscription } from 'rxjs';
import { CONSUMERSITE_CONFIG } from 'src/app/injection-tokens';
import { Charity } from 'src/app/models/website-management/charity';
import { WebsiteConfig } from 'src/app/models/website-management/website-config';
import { HeaderService } from 'src/app/services/header.service';
import { CharityManagementService } from 'src/app/services/website-management/charity-management.service';

@Component({
  selector: 'app-charity-management',
  templateUrl: './charity-management.component.html',
  styleUrls: ['./charity-management.component.scss']
})
export class CharityManagementComponent implements OnInit, OnDestroy {
  charity: Charity;
  savedCharity: string;
  loading: boolean;
  abandonDialogOpened = false;
  websiteConfig: WebsiteConfig;

  displayOnWebsite: boolean;

  private routeSubscription: Subscription;
  private charitySubscription: Subscription;

  constructor(
    private charityManagement: CharityManagementService,
    private route: ActivatedRoute,
    private router: Router,
    private header: HeaderService,
    private notification: NotificationService,
    @Inject(CONSUMERSITE_CONFIG) private consumersitesConfig: WebsiteConfig[]
  ) { }

  ngOnInit(): void {
    this.routeSubscription = this.route.paramMap.subscribe(params => {
      const websiteId = params.get('websiteId');
      this.websiteConfig = this.consumersitesConfig.find(x => x.id === websiteId);
      this.header.title = `Charity Management for ${this.websiteConfig.name}`;

      const charityCode = params.get('charityCode');
      this.loadCharity(charityCode);
    })
  }

  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    if (this.charitySubscription) {
      this.charitySubscription.unsubscribe();
    }
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.dirty) {
      return confirm('You have unsaved changes that will lost if you leave. Are you sure you want to leave?');
    }
    return true;
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification(event: Event): void {
    if (this.dirty) {
      event.preventDefault();
    }
  }

  saveCharity(): void {
    this.loading = true;

    if (!this.displayOnWebsite) {
      this.charity.link = '';
    }

    this.charitySubscription = this.charityManagement.saveCharity(this.charity, this.websiteConfig.id)
      .subscribe(() => {
        this.savedCharity = JSON.stringify(this.charity);
        this.loading = false;
        this.showNotification('The charity has been saved.');
      });
  }

  abandonChanges(): void {
    this.abandonDialogOpened = true;
  }

  abandonDialogClick(value: boolean): void {
    if (value) {
      this.charity = JSON.parse(this.savedCharity);
    }

    this.abandonDialogOpened = false;
  }

  get dirty(): boolean {
    return JSON.stringify(this.charity) !== this.savedCharity;
  }

  private loadCharity(charityCode: string): void {
    this.loading = true;

    this.charitySubscription = this.charityManagement.getCharity(charityCode, this.websiteConfig.id)
      .subscribe(charity => {
        if (!charity) {
          this.router.navigate(['website-management', this.websiteConfig.id, 'charities']);
          return;
        }

        this.charity = charity;
        this.savedCharity = JSON.stringify(charity);

        this.loading = false;

        this.displayOnWebsite = !!this.charity.link.length;
      });
  }

  private showNotification(msg: string): void {
    this.notification.show({
      content: msg,
      animation: { type: 'slide', duration: 350 },
      position: { horizontal: 'center', vertical: 'top' },
      type: { style: 'success', icon: true },
      hideAfter: 3000
    });
  }
}
