import { fromEvent } from 'rxjs';
import { enableProdMode, StaticProvider } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AppModule } from './app/app.module';
import { CONSUMERSITE_CONFIG, GOOGLE_API_KEY } from './app/injection-tokens';
import { APPLICATION_INSIGHTS } from './app/services/application-insights.service';
import { consoleLogger } from './console-logger';
import { environment } from './environments/environment';
import { WebsiteConfig } from './app/models/website-management/website-config';

if (environment.production) {
  enableProdMode();
}

function createAppInsights(instrumentationKey: string): ApplicationInsights {
  if (instrumentationKey) {
    const ai = new ApplicationInsights({
      config: { instrumentationKey }
    });
    ai.loadAppInsights();
    return ai;
  } else {
    console.warn('ApplicationInsights instrumentation key not provided');
    return null;
  }
}

function bootstrapApp() {
  const xhr = new XMLHttpRequest();
  fromEvent(xhr, 'load').subscribe(() => {
    const config = JSON.parse(xhr.responseText);
    const redirectUri = location.origin.endsWith('/') ? location.origin : `${location.origin}/`;
    const authority = config.authorityHost.endsWith('/') ? `${config.authorityHost}${config.tenantId}` :
      `${config.authorityHost}/${config.tenantId}`;

    const protectedResourceMap = new Map<string, string[]>();
    config.websites.forEach((site: WebsiteConfig) => {
      protectedResourceMap.set(site.baseUrl, site.consentScopes);
    });

    const extraProviders: StaticProvider[] = [
      {
        provide: MSAL_INSTANCE,
        useValue: new PublicClientApplication({
          auth: {
            clientId: config.clientId,
            authority,
            redirectUri,
            postLogoutRedirectUri: redirectUri
          },
          cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: false
          },
          system: {
            loggerOptions: {
              loggerCallback: environment.production ? null : consoleLogger,
              logLevel: LogLevel.Info,
              piiLoggingEnabled: !environment.production
            }
          }
        })
      },
      {
        provide: MSAL_GUARD_CONFIG,
        useValue: {
          interactionType: InteractionType.Popup,
          authRequest: {
            scopes: config.consentScopes
          }
        }
      },
      {
        provide: MSAL_INTERCEPTOR_CONFIG,
        useValue: {
          interactionType: InteractionType.Popup,
          protectedResourceMap
        }
      },
      {
        provide: APPLICATION_INSIGHTS,
        useValue: createAppInsights(config.instrumentationKey)
      },
      {
        provide: CONSUMERSITE_CONFIG,
        useValue: config.websites
      },
      {
        provide: GOOGLE_API_KEY,
        useValue: config.googleApiKey
      }
    ];

    platformBrowserDynamic(extraProviders)
      .bootstrapModule(AppModule)
      .catch(err => console.error(err));
  });

  xhr.open('GET', 'api/config');
  xhr.send();
}

bootstrapApp();
