import { Subscription } from 'rxjs';
import { CONSUMERSITE_CONFIG, WINDOW } from 'src/app/injection-tokens';
import { ServiceArea } from 'src/app/models/website-management/service-area';
import { WebsiteConfig } from 'src/app/models/website-management/website-config';
import { ServiceAreaManagementService } from 'src/app/services/website-management/service-area-management.service';
import { Component, Inject, OnDestroy, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-service-areas',
  templateUrl: './service-areas.component.html',
  styleUrls: ['./service-areas.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ServiceAreasComponent implements OnInit, OnDestroy {
  websiteConfig: WebsiteConfig;
  loading: boolean;
  serviceAreas: ServiceArea[];

  private routeSubscription: Subscription;
  private subscription: Subscription;
  private sortOrderSubscription: Subscription;

  constructor(
    private serviceAreaManagement: ServiceAreaManagementService,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    @Inject(CONSUMERSITE_CONFIG) private consumersitesConfig: WebsiteConfig[],
    @Inject(WINDOW) private window: Window,
  ) { }

  ngOnInit(): void {
    this.routeSubscription = this.route.paramMap.subscribe(params => {
      this.websiteConfig = this.consumersitesConfig.find(x => x.id === params.get('websiteId'));
      this.refreshServiceAreas();
    });
  }

  ngOnDestroy(): void {
    this.destroyDragListeners();

    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.sortOrderSubscription) {
      this.sortOrderSubscription.unsubscribe();
    }
  }

  previewServiceArea(serviceArea: ServiceArea): void {
    const url = `${this.websiteConfig.baseUrl}/${serviceArea.pageSlug}`;
    this.window.open(url, 'preview');
  }

  private setDraggableRows(): void {
    const tableRows = this.getAllRows();
    document.addEventListener('dragend', () => {
      tableRows.forEach(tr => tr.classList.remove('service-area-drop'));
    });
    tableRows.forEach(tr => {
      this.renderer.setAttribute(tr, 'draggable', 'true');
      this.addDragListener(tr);
    });
  }

  private refreshServiceAreas() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }

    this.loading = true;
    this.subscription = this.serviceAreaManagement.getServiceAreas(this.websiteConfig.id).subscribe(result => {
      this.serviceAreas = result;
      this.loading = false;

      setTimeout(() => this.setDraggableRows());
    });
  }

  private getAllRows(): HTMLTableRowElement[] {
    return Array.from(document.querySelectorAll('#service-area-grid tr'));
  }


  private addDragListener(tr: HTMLTableRowElement) {
    tr.addEventListener('dragstart', event => {
      const sourceId = this.getServiceAreaIdFromRow(tr);
      if (!sourceId) {
        event.preventDefault();
        return;
      }

      event.dataTransfer.setData('text/plain', sourceId.toString());
      event.dataTransfer.effectAllowed = 'move';
    });
    tr.addEventListener('dragover', event => {
      event.preventDefault();

      this.getAllRows().forEach(tr => tr.classList.remove('service-area-drop'));

      const targetId = this.getServiceAreaIdFromRow(tr);
      if (!targetId) {
        return;
      }

      event.dataTransfer.dropEffect = 'move';
      tr.classList.add('service-area-drop');
    });
    tr.addEventListener('drop', event => {
      event.preventDefault();

      this.getAllRows().forEach(tr => tr.classList.remove('service-area-drop'));

      const sourceId = Number(event.dataTransfer.getData('text/plain'));
      const targetId = this.getServiceAreaIdFromRow(tr);
      if (!sourceId || !targetId) {
        return;
      }

      const sourceIndex = this.serviceAreas.findIndex(x => x.id === sourceId);
      const targetIndex = this.serviceAreas.findIndex(x => x.id === targetId);

      const serviceArea = this.serviceAreas.splice(sourceIndex, 1);
      this.serviceAreas.splice(targetIndex, 0, ...serviceArea);

      if (this.sortOrderSubscription) {
        this.sortOrderSubscription.unsubscribe();
      }
      this.sortOrderSubscription = this.serviceAreaManagement
        .updateServiceAreaSortOrder(this.serviceAreas.map(x => x.id), this.websiteConfig.id)
        .subscribe();
    });
  }

  private destroyDragListeners(): void {
    const tableRows = this.getAllRows();
    tableRows.forEach(tr => {
      document.removeAllListeners('dragend');
      tr.removeAllListeners('dragstart');
      tr.removeAllListeners('dragover');
      tr.removeAllListeners('drop');
    });
  }

  private getServiceAreaIdFromRow(tr: HTMLTableRowElement): number | undefined {
    const td = tr.querySelector('td:first-child');
    return td ? Number(td.textContent) : undefined;
  }
}
