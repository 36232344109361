import { InjectionToken, inject } from '@angular/core';
import { WebsiteConfig } from './models/website-management/website-config';
import { DOCUMENT } from '@angular/common';

export const CONSUMERSITE_CONFIG = new InjectionToken<WebsiteConfig[]>('ConsumerSites Configuration');
export const GOOGLE_API_KEY = new InjectionToken<string>('Google API Key');

export const WINDOW = new InjectionToken<Window>(
  'An abstraction of the global window object',
  {
    factory: () => {
      const { defaultView } = inject(DOCUMENT);
      return defaultView;
    }
  }
);
