import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CONSUMERSITE_CONFIG } from 'src/app/injection-tokens';
import { PressItem } from 'src/app/models/website-management/press-item';
import { WebsiteConfig } from 'src/app/models/website-management/website-config';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

export interface PressItemsResult {
  items: PressItem[];
  itemCount: number;
}

@Injectable({
  providedIn: 'root'
})
export class PressItemManagementService {
  constructor(
    private http: HttpClient,
    @Inject(CONSUMERSITE_CONFIG) private consumersiteConfig: WebsiteConfig[]
  ) { }

  getPressItem(id: number, websiteId: string): Observable<PressItem> {
    const url = `${this.getBaseUrl(websiteId)}/press-item/${id}`;
    return this.http.get<PressItem>(url).pipe(
      map(this.correctDates)
    );
  }

  getPressItems(start: number, take: number, websiteId: string): Observable<PressItemsResult> {
    const url = `${this.getBaseUrl(websiteId)}/press-items?start=${start}&take=${take}`;
    return this.http.get<PressItemsResult>(url).pipe(
      map(result => {
        result.items.forEach(x => this.correctDates(x));
        return result;
      })
    );
  }

  savePressItem(pressItem: PressItem, websiteId: string): Observable<PressItem> {
    if (!pressItem) {
      throw new Error('Parameter "pressItem" not provided');
    }

    let $: Observable<PressItem>;

    if (pressItem.id > 0) {
      const url = `${this.getBaseUrl(websiteId)}/press-item/${pressItem.id}`;
      $ = this.http.put<PressItem>(url, pressItem);
    } else {
      const url = `${this.getBaseUrl(websiteId)}/press-item`;
      $ = this.http.post<PressItem>(url, pressItem);
    }

    return $.pipe(
      map(result => this.correctDates(result)),
      catchError(() => {
        const errMsg = pressItem.id > 0 ? 'Failure updating press item' : 'Failure creating press item';
        throw new Error(errMsg);
      })
    );
  }

  createDocument(file: File, websiteId: string): Observable<string> {
    const url = `${this.getBaseUrl(websiteId)}/asset`;

    const formData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post<string>(url, formData);
  }

  private getBaseUrl(websiteId: string): string {
    const config = this.consumersiteConfig.find(x => x.id === websiteId);
    return `${config.baseUrl}/press-item-management`;
  }

  private correctDates(item: PressItem): PressItem {
    item.publishDate = new Date(item.publishDate);
    if (item.expirationDate) {
      item.expirationDate = new Date(item.expirationDate);
    }

    return item;
  }
}
