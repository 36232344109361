import { forkJoin, fromEvent, Subscription } from 'rxjs';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';
import { CONSUMERSITE_CONFIG, WINDOW } from '../injection-tokens';
import { WebsiteConfig } from '../models/website-management/website-config';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit, OnDestroy {
  private resizeSubscription: Subscription;
  private msalSubscription: Subscription;
  private navigationSubscription: Subscription;

  private readonly breakpoint = 768;
  private readonly navbarHeight = 56;
  private resizeHandler: (Event) => void;

  navOpen = false;
  websitesToManage: WebsiteConfig[];

  constructor(
    private msal: MsalService,
    private broadcastService: MsalBroadcastService,
    private router: Router,
    private ref: ChangeDetectorRef,
    @Inject(CONSUMERSITE_CONFIG) public websiteConfig: WebsiteConfig[],
    @Inject(WINDOW) private window: Window) {
    this.resizeHandler = this.onResize.bind(this);
  }

  ngOnInit(): void {
    this.resizeSubscription = fromEvent(this.window, 'resize').subscribe(this.resizeHandler);

    this.broadcastService.msalSubject$.subscribe((eventMessage: EventMessage) => {
      if (eventMessage.eventType === EventType.LOGIN_SUCCESS) {
        const authResult = eventMessage.payload as AuthenticationResult;
        this.msal.instance.setActiveAccount(authResult.account);

        this.updateWebsiteManager();
        this.ref.detectChanges();
      }
    });

    this.navigationSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.navOpen = false;
      }
    });

    this.updateWebsiteManager();
  }

  ngOnDestroy(): void {
    if (this.resizeSubscription) {
      this.resizeSubscription.unsubscribe();
    }

    if (this.msalSubscription) {
      this.msalSubscription.unsubscribe();
    }

    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  onToggleNavClicked(): void {
    this.navOpen = !this.navOpen;
  }

  isNavActive(category: string): boolean {
    const segments = this.router.url.toLowerCase().split('/');
    if (segments.length > 0 && segments[0] === '') {
      segments.splice(0, 1);
    }
    return segments.length >= 1 && segments[0] === category;
  }

  userHasRole(role: string): boolean {
    const account = this.msal.instance.getActiveAccount();
    if (account && 'idTokenClaims' in account && 'roles' in account.idTokenClaims) {
      return account.idTokenClaims.roles.includes(role);
    }

    return false;
  }

  private updateWebsiteManager() {
    if (!this.signedIn) {
      this.websitesToManage = [];
      return;
    }

    const authResults$ = this.websiteConfig.map(x => this.msal.acquireTokenSilent({ scopes: x.consentScopes }));
    forkJoin(authResults$).subscribe(results => {
      this.websitesToManage = results.reduce((acc, val) => {
        if (val && val.accessToken) {
          const parts = val.accessToken.split('.');
          if (parts.length === 3) {
            const payload = JSON.parse(atob(parts[1]));
            if (payload && 'roles' in payload && payload.roles.includes('WebsiteManager')) {
              acc.push(
                this.websiteConfig.find(config => config.consentScopes.every(x => val.scopes.includes(x))));
            }
          }
        }
        return acc;
      }, []);
    });
  }

  get navHeight(): number {
    return Math.max(this.window.innerHeight - this.navbarHeight, 0);
  }

  get isNavSlideOut(): boolean {
    return this.window.innerWidth < this.breakpoint;
  }

  get signedIn(): boolean {
    return !!this.msal.instance.getActiveAccount();
  }

  private onResize() {
    this.ref.detectChanges();
  }

  get copyright(): string {
    const now = new Date();
    return `Copyright © ${now.getFullYear()}, 2nd Ave, LLC`;
  }
}
