import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CONSUMERSITE_CONFIG } from 'src/app/injection-tokens';
import { LocationHours } from 'src/app/models/website-management/location-hours';
import { Store } from 'src/app/models/website-management/store';
import { StoreImage } from 'src/app/models/website-management/store-image';
import { StoreSubType } from 'src/app/models/website-management/store-sub-type';
import { WebsiteConfig } from 'src/app/models/website-management/website-config';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { LocationHoursApiModel } from 'src/app/models/website-management/location-hours-api-model';

interface StoreApiModel extends Omit<Store, 'hours'> {
  hours: LocationHoursApiModel[]
}

@Injectable({
  providedIn: 'root'
})
export class StoreManagementService {

  constructor(
    private http: HttpClient,
    @Inject(CONSUMERSITE_CONFIG) private consumersiteConfig: WebsiteConfig[]
  ) { }

  getStore(storeId: number, websiteId: string): Observable<Store> {
    const url = `${this.getBaseUrl(websiteId)}/store/${storeId}`;

    return this.http.get<StoreApiModel>(url)
      .pipe(
        map(store => this.fromApiModel(store))
      );
  }

  getStores(websiteId: string): Observable<Store[]> {
    const url = `${this.getBaseUrl(websiteId)}/stores`;

    return this.http.get<StoreApiModel[]>(url)
      .pipe(
        map(stores => stores.map(store => this.fromApiModel(store)))
      );
  }

  saveStore(store: Store, websiteId: string): Observable<Store> {
    const url = `${this.getBaseUrl(websiteId)}/store/${store.id}`;

    const request = this.toApiModel(store);

    return this.http.put<StoreApiModel>(url, request)
      .pipe(
        map(store => this.fromApiModel(store))
      );
  }

  updateAllStoreHours(hours: LocationHours[], websiteId: string): Observable<boolean> {
    const url = `${this.getBaseUrl(websiteId)}/stores/hours`;

    const request: LocationHoursApiModel[] = hours.map(x => {
      return {
        dayofWeek: x.dayofWeek,
        openTime: this.toTimeSpan(x.openTime),
        closeTime: this.toTimeSpan(x.closeTime),
        closed: x.closed,
        temporary: x.temporary
      };
    })

    return this.http.put(url, request)
      .pipe(
        map(() => true),
        catchError(() => {
          throw new Error('Failure saving store location hours');
        })
      );
  }

  saveImage(file: File, websiteId: string): Observable<StoreImage> {
    const url = `${this.getBaseUrl(websiteId)}/image`;

    const formData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post<StoreImage>(url, formData);
  }

  getStoreSubTypes(websiteId: string): Observable<StoreSubType[]> {
    const url = `${this.getBaseUrl(websiteId)}/store-sub-types`;

    return this.http.get<StoreSubType[]>(url);
  }

  private getBaseUrl(websiteId: string): string {
    const config = this.consumersiteConfig.find(x => x.id === websiteId);
    return `${config.baseUrl}/store-management`;
  }

  private fromApiModel(model: StoreApiModel): Store {
    return {
      id: model.id,
      name: model.name,
      address: model.address,
      address2: model.address2,
      city: model.city,
      state: model.state,
      zip: model.zip,
      active: model.active,
      latitude: model.latitude,
      longitude: model.longitude,
      image: model.image,
      pageSlug: model.pageSlug,
      pageContent: model.pageContent,
      hours: model.hours.map(x => {
        return {
          dayofWeek: x.dayofWeek,
          openTime: this.fromTimeSpan(x.openTime),
          closeTime: this.fromTimeSpan(x.closeTime),
          closed: x.closed,
          temporary: x.temporary
        };
      }),
      subType: model.subType,
      phone: model.phone,
      imageUrl: model.imageUrl,
      pageTitle: model.pageTitle,
      pageDescription: model.pageDescription,
      alertMessage: model.alertMessage
    };
  }

  private toApiModel(store: Store): StoreApiModel {
    return {
      id: store.id,
      name: store.name,
      address: store.address,
      address2: store.address2,
      city: store.city,
      state: store.state,
      zip: store.zip,
      active: store.active,
      latitude: store.latitude,
      longitude: store.longitude,
      image: store.image,
      pageSlug: store.pageSlug,
      pageContent: store.pageContent,
      hours: store.hours.map(x => {
        return {
          dayofWeek: x.dayofWeek,
          openTime: this.toTimeSpan(x.openTime),
          closeTime: this.toTimeSpan(x.closeTime),
          closed: x.closed,
          temporary: x.temporary
        };
      }),
      subType: store.subType,
      phone: store.phone,
      imageUrl: store.imageUrl,
      pageTitle: store.pageTitle,
      pageDescription: store.pageDescription,
      alertMessage: store.alertMessage
    };
  }

  private fromTimeSpan(value: string): Date {
    const timeParts = value.split(':');

    const date = new Date(0);
    date.setHours(parseInt(timeParts[0], 10));
    date.setMinutes(parseInt(timeParts[1], 10));
    date.setSeconds(parseInt(timeParts[2], 10));
    date.setMilliseconds(0);

    return date;
  }

  private toTimeSpan(value: Date): string {
    return new Intl.DateTimeFormat('en-us', {
      hour12: false,
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
    }).format(value);
  }
}
