<ul class="list-unstyled">
  <li>
    <label>Slide Interval</label>
    <span>{{ slideIntervalSeconds }} sec</span>
  </li>
  <li>
    <label>Display Indicators</label>
    <span *ngIf="content?.displayIndicators" class="h3 text-success k-icon k-i-check">
      <span class="sr-only">Yes</span>
    </span>
    <span *ngIf="!content?.displayIndicators" class="h3 text-error k-icon k-i-x">
      <span class="sr-only">No</span>
    </span>
  </li>
  <li>
    <label>Display Controls</label>
    <span *ngIf="content?.displayControls" class="h3 text-success k-icon k-i-check">
      <span class="sr-only">Yes</span>
    </span>
    <span *ngIf="!content?.displayControls" class="h3 text-error k-icon k-i-x">
      <span class="sr-only">No</span>
    </span>
  </li>
  <li>
    <label>Slides</label>
    <ol>
      <li *ngFor="let slide of content.slides">
        <app-slide-preview
          [slide]="slide"
          [componentId]="componentId"
          [websiteId]="websiteId">
        </app-slide-preview>
      </li>
    </ol>
  </li>
</ul>
