import { timer } from 'rxjs';
import { WINDOW } from 'src/app/injection-tokens';
import { Carousel } from 'src/app/models/website-management/carousel';
import { CarouselSlide } from 'src/app/models/website-management/carousel-slide';
import { WebsiteConfig } from 'src/app/models/website-management/website-config';
import { Component, ElementRef, Inject, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-carousel-draft',
  templateUrl: './carousel-draft.component.html',
  styleUrls: ['./carousel-draft.component.scss']
})
export class CarouselDraftComponent {
  @Input() carousel: Carousel;
  @Input() component: Component;
  @Input() websiteConfig: WebsiteConfig;
  @Input() disabled: boolean;

  @ViewChild('slidesContainer') slidesContainer: ElementRef;

  chooseSlideDialogOpened = false;
  slideTypes: { text: string, value: string }[] = [
    { text: 'Single Image Slide', value: 'ConsumerSites.Common.Models.SingleImageSlide' },
    { text: 'HTML Content Slide', value: 'ConsumerSites.Common.Models.HtmlContentSlide' },
    { text: 'Split Image Slide', value: 'ConsumerSites.Common.Models.SplitImageSlide' }
  ];

  newSlideType: string;

  constructor(
    @Inject(WINDOW) private window: Window
  ) { }

  chooseSlide(): void {
    this.newSlideType = this.slideTypes[0].value;
    this.chooseSlideDialogOpened = true;
  }

  chooseSlideOk(): void {
    this.chooseSlideDialogOpened = false;

    this.carousel.slides.push({
      type: this.newSlideType
    });

    timer().subscribe(() => {
      const container: Element = this.slidesContainer.nativeElement;
      const newSlide = container.lastChild as HTMLElement;
      const top = Math.max(0, newSlide.offsetTop - 40);

      this.window.scrollTo({ left: 0, top, behavior: 'smooth' });
    });
  }

  chooseSlideCancel(): void {
    this.chooseSlideDialogOpened = false;
  }

  moveUp(index: number): void {
    const slide = this.deleteSlide(index);
    timer().subscribe(() => this.carousel.slides.splice(index - 1, 0, slide));
  }

  moveDown(index: number): void {
    const slide = this.deleteSlide(index);
    timer().subscribe(() => this.carousel.slides.splice(index + 1, 0, slide));
  }

  deleteSlide(index: number): CarouselSlide {
    return this.carousel.slides.splice(index, 1)[0];
  }

  get slideIntervalSeconds(): number {
    return this.carousel.slideInterval / 1000;
  }

  set slideIntervalSeconds(value: number) {
    this.carousel.slideInterval = value * 1000;
  }

  slideTypeText(slide: CarouselSlide): string {
    if (!slide) {
      return '';
    }

    const slideType = this.slideTypes.find(x => x.value === slide.type);
    return slideType ? slideType.text : '';
  }
}
