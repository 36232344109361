import { Subscription } from 'rxjs';
import { CONSUMERSITE_CONFIG, WINDOW } from 'src/app/injection-tokens';
import { LocationHours } from 'src/app/models/website-management/location-hours';
import { Store } from 'src/app/models/website-management/store';
import { WebsiteConfig } from 'src/app/models/website-management/website-config';
import { StoreManagementService } from 'src/app/services/website-management/store-management.service';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-stores',
  templateUrl: './stores.component.html',
  styleUrls: ['./stores.component.scss']
})
export class StoresComponent implements OnInit, OnDestroy {
  websiteConfig: WebsiteConfig;

  private routeSubscription: Subscription;
  private storesSubscription: Subscription;
  private hoursSubscription: Subscription;

  stores: Store[];

  loading: boolean;
  allStoreHours: LocationHours[];
  opened = false;

  constructor(
    private storeManagement: StoreManagementService,
    private route: ActivatedRoute,
    @Inject(CONSUMERSITE_CONFIG) private consumersiteConfig: WebsiteConfig[],
    @Inject(WINDOW) private window: Window
  ) { }

  ngOnInit(): void {
    this.routeSubscription = this.route.paramMap.subscribe(params => {
      this.websiteConfig = this.consumersiteConfig.find(x => x.id === params.get('websiteId'));
      this.refreshStores();
    })
  }

  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    if (this.storesSubscription) {
      this.storesSubscription.unsubscribe();
    }
    if (this.hoursSubscription) {
      this.hoursSubscription.unsubscribe();
    }
  }

  previewStore(event: Event): void {
    event.preventDefault();
    const url = (event.target as HTMLAnchorElement).href;
    this.window.open(url, 'preview');
  }

  onChangeHoursClick(): void {
    this.opened = true;
  }

  onDialogClose(): void {
    this.opened = false;

    this.allStoreHours = this.stores[0].hours.sort(x => x.dayofWeek);
  }

  onSaveHours(): void {
    this.loading = true;

    const $ = this.storeManagement.updateAllStoreHours(this.allStoreHours, this.websiteConfig.id);
    this.hoursSubscription = $.subscribe(() => {
      this.loading = false;
      this.refreshStores();
      this.onDialogClose();
    });
  }

  private refreshStores() {
    if (this.storesSubscription) {
      this.storesSubscription.unsubscribe();
    }
    this.loading = true;

    const $ = this.storeManagement.getStores(this.websiteConfig.id);
    this.storesSubscription = $.subscribe(stores => {
      this.stores = stores.sort(x => x.longitude);

      if (stores.length) {
        this.allStoreHours = stores[0].hours.sort(x => x.dayofWeek);
      }

      this.loading = false;
    })
  }
}
