import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Directive, forwardRef, Input } from '@angular/core';
import { AbstractControl, NG_ASYNC_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: '[appPageSlugValidator]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: forwardRef(() => PageSlugValidatorDirective),
      multi: true
    }
  ]
})
export class PageSlugValidatorDirective implements Validator {
  // input[0]: the base URL of validation requests
  // input[1]: existing page slug value
  @Input('appPageSlugValidator') input: [string, string];

  private readonly regex = new RegExp('^[a-z0-9]+[a-z0-9-]*$');

  constructor(
    private http: HttpClient
) { }

  validate(control: AbstractControl): Observable<ValidationErrors> {
    if (!control || !control.value || !control.value.length || this.existingValue === control.value) {
      return of(null);
    }

    if (!this.regex.test(control.value)) {
      return of({ invalidSyntax: true });
    }

    const url = `${this.baseUrl}/${control.value}`;
    return this.http.get(url, { responseType: 'text', observe: 'response' })
      .pipe(
        catchError(err => of(err)),
        map(response => response.error instanceof ErrorEvent ? { uniqueFailure: true } :
            response.status !== 404 ? { notUnique: true } :
            null)
      );
  }

  private get baseUrl(): string {
    return this.input[0];
  }

  private get existingValue(): string {
    return this.input[1];
  }
}
