import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CONSUMERSITE_CONFIG } from 'src/app/injection-tokens';
import { Charity } from 'src/app/models/website-management/charity';
import { DmsCharity } from 'src/app/models/website-management/dms-charity';
import { WebsiteConfig } from 'src/app/models/website-management/website-config';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CharityManagementService {

  constructor(
    private http: HttpClient,
    @Inject(CONSUMERSITE_CONFIG) private consumersiteConfig: WebsiteConfig[]
  ) { }

  getCharity(code: string, websiteId: string): Observable<Charity> {
    const url = `${this.getBaseUrl(websiteId)}/charity/${code}`;
    const charity$ = this.http.get<Charity>(url).pipe(
      catchError(e => {
        if (e instanceof HttpErrorResponse && (e as HttpErrorResponse).status === 404) {
          return of(null);
        }
        throw new Error('A problem occurred fetching charity from API');
      })
    );
    const dmsCharities$ = this.getDmsCharities();

    return forkJoin([charity$, dmsCharities$]).pipe(
      map(val => {
        const dmsCharity = val[1].find(x => x.charityCode === code);
        if (!dmsCharity) {
          return null;
        }

        const charity: Charity = val[0] ? val[0] : {
          code,
          link: '',
          logoImg: '',
          logoSquareImg: '',
          thankYouImage: null,
          thankYouLabel: null
        };

        charity.dmsCharity = dmsCharity;
        return charity;
      })
    );
  }

  getCharities(websiteId: string): Observable<Charity[]> {
    const url = `${this.getBaseUrl(websiteId)}/charities`;
    const charities$ = this.http.get<Charity[]>(url);
    const dmsCharities$ = this.getDmsCharities();

    return forkJoin([charities$, dmsCharities$]).pipe(
      map(val => val[0].map(charity => {
        charity.dmsCharity = val[1].find(x => x.charityCode === charity.code);
        return charity;
      }))
    );
  }

  saveCharity(charity: Charity, websiteId: string): Observable<boolean> {
    const url = `${this.getBaseUrl(websiteId)}/charity`;
    return this.http.post(url, charity).pipe(
      map(() => true),
      catchError(() => of(false)));
  }

  getDmsCharities(): Observable<DmsCharity[]> {
    const url = '/api/charities'
    return this.http.get<DmsCharity[]>(url);
  }

  private getBaseUrl(websiteId: string): string {
    const config = this.consumersiteConfig.find(x => x.id === websiteId);
    return `${config.baseUrl}/charity-management`;
  }}
