<header class="row justify-content-between">
  <h2 class="col-auto">Stores</h2>
  <div class="col-auto">
    <button type="button"
      class="btn btn-link"
      [disabled]="loading"
      (click)="onChangeHoursClick()">
      <span class="k-icon k-i-clock"></span>
      Change Hours
    </button>
  </div>
</header>

<app-loading-spinner *ngIf="loading"></app-loading-spinner>

<div class="row">
  <div *ngFor="let store of stores" class="col-12 col-sm-6 col-xl-4">
    <div class="card my-2" [class.inactive]="!store.active" [class.text-muted]="!store.active">
      <img class="card-img-top" [alt]="store.city" [src]="store.imageUrl">
      <div class="card-body">
        <h5 class="card-title">{{ store.name }}</h5>
        <address class="card-text">
          {{ store.address }}<br>
          {{ store.city }}, {{ store.state.abbreviation }} {{ store.zip }}
        </address>
        <app-location-hours [readonly]="true" [locationHours]="store.hours">
        </app-location-hours>
        <em *ngIf="!store.active" class="text-danger font-weight-bold">Not Active</em>
      </div>
      <footer class="card-body">
        <a [routerLink]="['/website-management', websiteConfig.id, 'store-management', store.id]"
          class="card-link">
          <span class="k-icon k-i-pencil"></span>
          Edit
        </a>
        <a [href]="websiteConfig.baseUrl + '/store/' + store.pageSlug"
          class="card-link"
          (click)="previewStore($event)">
          <span class="k-icon k-i-preview"></span>
          Preview
        </a>
      </footer>
    </div>
  </div>
</div>

<kendo-dialog *ngIf="opened"
  title="Store Hours"
  [width]="550"
  (close)="onDialogClose()">
  <div class="alert alert-warning">
    <span class="k-icon k-i-warning"></span>
    This will change the hours for <strong>ALL</strong> stores.
  </div>

  <app-location-hours #locationHours="ngModel"
    [(ngModel)]="allStoreHours"
    [disabled]="loading">
  </app-location-hours>

  <div *ngIf="locationHours.errors?.invalidHours" class="alert alert-danger">
    The opening time must occur before the closing time.
  </div>

  <kendo-dialog-actions>
    <button kendoButton [disabled]="loading" (click)="onDialogClose()">Cancel</button>
    <button kendoButton
      [primary]="true"
      [disabled]="loading || !locationHours.valid"
      (click)="onSaveHours()">
      Save
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
