<div class="container-fluid">
  <div class="float-right">
    <a [routerLink]="['/website-management', websiteConfig.id, 'components']"
      class="font-weight-bold">
      <span class="k-icon k-i-arrow-left"></span>
      Return to website management
    </a>
  </div>
  <h2>{{ component?.name }}</h2>
  <p>{{ component?.description }}</p>

  <div *ngIf="!draft" class="text-right">
    <button type="button"
      class="btn btn-primary"
      [disabled]="loading"
      (click)="createDraft(0)">
      <span class="k-icon k-i-edit"></span>
      Create Draft
    </button>
    <button type="button"
      class="btn btn-secondary ml-2"
      (click)="previewComponent()">
      <span class="k-icon k-i-preview"></span>
      Preview
    </button>
  </div>
  <div *ngIf="draft">
    <div class="text-right mb-4">
      <div class="btn-group">
        <button type="button"
          class="btn btn-primary"
          [disabled]="loading || !dirty"
          (click)="saveAndPreviewDraft()">
          <span class="k-icon k-i-save"></span>
          Save &amp; Preview
        </button>
        <button type="button"
          class="btn btn-primary dropdown-toggle dropdown-toggle-split"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false">
          <span class="sr-only">Toggle Dropdown</span>
        </button>
        <div class="dropdown-menu">
          <button type="button"
            class="dropdown-item"
            [disabled]="loading || !dirty"
            (click)="saveDraft(null)">
            <span class="k-icon k-i-save"></span>
            Save Draft
          </button>
          <button type="button"
            class="dropdown-item"
            (click)="previewComponent()">
            <span class="k-icon k-i-preview"></span>
            Preview Draft
          </button>
          <div class="dropdown-divider"></div>
          <button type="button"
            class="dropdown-item"
            [disabled]="loading || !dirty"
            (click)="abandonDraft()">
            <span class="k-icon k-i-cancel"></span>
            Abandon Draft
          </button>
        </div>
      </div>

      <button type="button"
        class="btn btn-danger ml-2"
        [disabled]="loading"
        (click)="deleteDraft()">
        <span class="k-icon k-i-delete"></span>
        Delete Draft
      </button>
      <button type="button"
        class="btn btn-success ml-2"
        [disabled]="loading || dirty"
        (click)="publishDraft()">
        <span class="k-icon k-i-upload"></span>
        Publish Draft
      </button>
    </div>

    <div class="row mt-4">
      <div class="col-auto">
        <h4>Publish Start Date</h4>
        <div class="form-check">
          <input #inputNoStartDate
            type="radio"
            class="form-check-input"
            [checked]="!draft.publishStart"
            [disabled]="loading"
            (click)="publishStartClick(false)">
          <label class="form-check-label" [for]="inputNoStartDate">No Start Date</label>
        </div>
        <div class="form-check">
          <input #inputPublishStart
            type="radio"
            class="form-check-input"
            [checked]="!!draft.publishStart"
            [disabled]="loading"
            (click)="publishStartClick(true)">
          <label class="form-check-label mr-2" [for]="inputPublishStart">Start Date/Time:</label>
          <kendo-datetimepicker
            [value]="draft.publishStart"
            [disabled]="loading || !draft.publishStart"
            [style.width.em]="14"
            (valueChange)="publishStartChange($event)">
          </kendo-datetimepicker>
        </div>
      </div>

      <div class="col-auto">
        <h4>Publish End Date</h4>
        <div class="form-check">
          <input #inputNoEndDate
            type="radio"
            class="form-check-input"
            [checked]="!draft.publishEnd"
            [disabled]="loading"
            (click)="publishEndClick(false)">
          <label class="form-check-label" [for]="inputNoEndDate">No End Date</label>
        </div>
        <div class="form-check">
          <input #inputPublishEnd
            type="radio"
            class="form-check-input"
            [checked]="!!draft.publishEnd"
            [disabled]="loading"
            (click)="publishEndClick(true)">
          <label class="form-check-label mr-2" [for]="inputPublishEnd">End Date:</label>
          <kendo-datetimepicker
            [value]="draft.publishEnd"
            [disabled]="!draft.publishEnd || loading"
            [style.width.em]="14"
            (valueChange)="publishEndChange($event)">
          </kendo-datetimepicker>
        </div>
      </div>
    </div>
    <div class="row mt-3 mb-4">
      <div class="col">
        <div *ngIf="draft.publishStart && draft.publishEnd && draft.publishEnd < draft.publishStart"
          class="alert alert-warning">
          <span class="k-icon k-i-warning"></span>
          The publish end date must come after the start date.
        </div>
      </div>
    </div>

    <app-content-draft
      [draft]="draft"
      [component]="component"
      [websiteConfig]="websiteConfig"
      [disabled]="loading">
    </app-content-draft>

  </div>

  <ng-template [ngIf]="component && !component.cascades">
    <h2 class="mt-5">Active Content</h2>
    <kendo-grid [data]="activeContent"
      [loading]="loading">
      <kendo-grid-column field="dateCreated" title="Creation Details" class="align-top">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div>{{ dataItem.createdBy }}</div>
          <div>{{ dataItem.dateCreated | date:dateTimeFormat }}</div>
          <div *ngIf="!draft">
            <button type="button"
              class="btn btn-primary btn-sm my-1"
              [disabled]="loading"
              (click)="createDraft(dataItem.id)">
              <span class="k-icon k-i-pencil"></span>
              Create Draft from this Item
            </button>
          </div>
          <button type="button"
            class="btn btn-danger btn-sm my-1"
            [disabled]="loading"
            (click)="deactivateContent()">
            <span class="k-icon k-i-cancel"></span>
            De-activate Content
        </button>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="publishStart" title="Start" class="text-center align-top">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem?.publishStart | date:dateTimeFormat }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="publishEnd" title="End" class="text-center align-top">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem?.publishEnd | date:dateTimeFormat }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="Preview">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div [ngSwitch]="component?.componentType">
            <app-news-ribbon-preview *ngSwitchCase="'NewsRibbon'"
              [content]="dataItem.contents">
            </app-news-ribbon-preview>
            <app-carousel-preview *ngSwitchCase="'Carousel'"
              [websiteId]="websiteConfig.id"
              [componentId]="component.componentId"
              [content]="dataItem.contents">
            </app-carousel-preview>
            <app-service-alert-preview *ngSwitchCase="'ServiceAlert'"
              [content]="dataItem.contents">
            </app-service-alert-preview>
          </div>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </ng-template>

  <h2 class="mt-5">Content History</h2>
  <kendo-grid [data]="componentHistory"
    [loading]="loading"
    scrollable="none">
    <kendo-grid-column field="dateCreated" title="Creation Details" class="align-top">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div>{{ dataItem.createdBy }}</div>
        <div>{{ dataItem.dateCreated | date:dateTimeFormat }}</div>
        <div *ngIf="!draft">
          <button type="button"
            class="btn btn-primary btn-sm"
            [disabled]="loading"
            (click)="createDraft(dataItem.id)">
            <span class="k-icon k-i-pencil"></span>
            Create Draft from this Item
          </button>
        </div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="publishStart" title="Start" class="text-center align-top">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem?.publishStart | date:dateTimeFormat }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="publishEnd" title="End" class="text-center align-top">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem?.publishEnd | date:dateTimeFormat }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Active" class="text-center align-top">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.active ? 'Yes' : 'No' }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Preview">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div [ngSwitch]="component?.componentType">
          <app-news-ribbon-preview *ngSwitchCase="'NewsRibbon'"
            [content]="dataItem.contents">
          </app-news-ribbon-preview>
          <app-carousel-preview *ngSwitchCase="'Carousel'"
            [websiteId]="websiteConfig.id"
            [componentId]="component.componentId"
            [content]="dataItem.contents">
          </app-carousel-preview>
          <app-service-alert-preview *ngSwitchCase="'ServiceAlert'"
            [content]="dataItem.contents">
          </app-service-alert-preview>
        </div>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>

<kendo-dialog title="Delete Draft" *ngIf="deleteDialogOpened" [minWidth]="250">
  <p>Are you sure you want to delete this draft?</p>
  <kendo-dialog-actions>
    <button kendoButton (click)="deleteDialogClick(true)" [primary]="true">Yes</button>
    <button kendoButton (click)="deleteDialogClick(false)">No</button>
  </kendo-dialog-actions>
</kendo-dialog>

<kendo-dialog title="Publish Draft" *ngIf="publishDialogOpened" [minWidth]="250">
  <p>Are you sure you want to publish the current draft?</p>
  <kendo-dialog-actions>
    <button kendoButton (click)="publishDialogClick(true)" [primary]="true">Yes</button>
    <button kendoButton (click)="publishDialogClick(false)">No</button>
  </kendo-dialog-actions>
</kendo-dialog>

<kendo-dialog title="Abandon Draft" *ngIf="abandonDialogOpened" [minWidth]="250">
  <p>Are you sure you want to abandon the current draft?</p>
  <kendo-dialog-actions>
    <button kendoButton (click)="abandonDialogClick(true)" [primary]="true">Yes</button>
    <button kendoButton (click)="abandonDialogClick(false)">No</button>
  </kendo-dialog-actions>
</kendo-dialog>

<kendo-dialog title="De-activate Content" *ngIf="deactivateDialogOpened" [minWidth]="250">
  <p>Are you sure you want to de-activate this content?</p>
  <kendo-dialog-actions>
    <button kendoButton (click)="deactivateContentClick(true)" [primary]="true">Yes</button>
    <button kendoButton (click)="deactivateContentClick(false)">No</button>
  </kendo-dialog-actions>
</kendo-dialog>
