import { Subscription } from 'rxjs';
import { CONSUMERSITE_CONFIG } from 'src/app/injection-tokens';
import { SingleImageSlide } from 'src/app/models/website-management/single-image-slide';
import { WebsiteConfig } from 'src/app/models/website-management/website-config';
import { ContentManagementService } from 'src/app/services/website-management/content-management.service';
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-single-image-slide-preview',
  templateUrl: './single-image-slide-preview.component.html',
  styleUrls: ['./single-image-slide-preview.component.scss']
})
export class SingleImageSlidePreviewComponent implements OnInit, OnDestroy {
  @Input() websiteId: string;
  @Input() componentId: string;
  @Input() slide: SingleImageSlide;

  imageSmall: string;
  imageLarge: string;

  private imageSmallSubscription: Subscription;
  private imageLargeSubscription: Subscription;
  private siteConfig: WebsiteConfig;

  constructor(
    private contentManagement: ContentManagementService,
    @Inject(CONSUMERSITE_CONFIG) private consumersiteConfig: WebsiteConfig[]
  ) { }

  ngOnInit(): void {
    this.siteConfig = this.consumersiteConfig.find(x => x.id === this.websiteId);
  }

  ngOnDestroy(): void {
    if (this.imageSmallSubscription) {
      this.imageSmallSubscription.unsubscribe();
    }
    if (this.imageLargeSubscription) {
      this.imageLargeSubscription.unsubscribe();
    }
  }

  get navigateUrl(): string {
    return `${this.siteConfig.baseUrl}${this.slide.navigateUrl}`;
  }

  openImageSmallModal(): void {
    this.imageSmall = undefined;
    this.imageSmallSubscription = this.contentManagement
      .getAsset(this.websiteId, this.componentId, this.slide.imageSrcSmall)
      .subscribe(src => this.imageSmall = src);
  }

  openImageLargeModal(): void {
    this.imageLarge = undefined;
    this.imageLargeSubscription = this.contentManagement
      .getAsset(this.websiteId, this.componentId, this.slide.imageSrcLarge)
      .subscribe(src => this.imageLarge = src);
  }
}
