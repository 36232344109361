<h2>Content Management</h2>
<kendo-grid [data]="components" [loading]="loading" scrollable="none">
  <kendo-grid-column field="componentId" [hidden]="true"></kendo-grid-column>
  <kendo-grid-column field="name" title="Name"></kendo-grid-column>
  <kendo-grid-column field="description" title="Description"></kendo-grid-column>
  <kendo-grid-column>
    <ng-template kendoGridCellTemplate let-dataItem>
      <button type="button"
        [routerLink]="['/website-management', websiteConfig.id, 'content-management', dataItem.componentId]"
        class="btn btn-link">
        <span class="k-icon k-i-edit"></span>
        Edit
      </button>
      <button class="btn btn-link" (click)="previewComponent(dataItem)">
        <span class="k-icon k-i-preview"></span>
        Preview
      </button>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
