import { HtmlContentSlide } from 'src/app/models/website-management/html-content-slide';
import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-html-content-slide-draft',
  templateUrl: './html-content-slide-draft.component.html',
  styleUrls: ['./html-content-slide-draft.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HtmlContentSlideDraftComponent {
  @Input() slide: HtmlContentSlide;
  @Input() disabled: boolean;
}
