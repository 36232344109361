import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CONSUMERSITE_CONFIG } from 'src/app/injection-tokens';
import { DropoffLocation } from 'src/app/models/website-management/dropoff-location';
import { DropoffLocationImage } from 'src/app/models/website-management/dropoff-location-image';
import { LocationHours } from 'src/app/models/website-management/location-hours';
import { LocationHoursApiModel } from 'src/app/models/website-management/location-hours-api-model';
import { WebsiteConfig } from 'src/app/models/website-management/website-config';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

export interface DropoffImageResult {
  image: string;
  imageUrls: DropoffLocationImage;
}

interface DropoffLocationApiModel extends Omit<DropoffLocation, 'hours'> {
  hours: LocationHoursApiModel[]
}

@Injectable({
  providedIn: 'root'
})
export class DropoffLocationManagementService {

  constructor(
    private http: HttpClient,
    @Inject(CONSUMERSITE_CONFIG) private consumersiteConfig: WebsiteConfig[]
  ) { }

  getDropoffLocation(dropoffLocationId: number, websiteId: string): Observable<DropoffLocation> {
    const url = `${this.getBaseUrl(websiteId)}/dropoff-location/${dropoffLocationId}`;

    return this.http.get<DropoffLocationApiModel>(url)
      .pipe(
        map(result => this.fromApiModel(result))
      );
  }

  getDropoffLocations(websiteId: string): Observable<DropoffLocation[]> {
    const url = `${this.getBaseUrl(websiteId)}/dropoff-locations`;
    return this.http.get<DropoffLocationApiModel[]>(url)
      .pipe(
        map(locations => locations.map(x => this.fromApiModel(x)))
      );
  }

  createDropoffLocation(location: DropoffLocation, websiteId: string): Observable<DropoffLocation> {
    const url = `${this.getBaseUrl(websiteId)}/dropoff-location`;
    const request = this.toApiModel(location);

    return this.http.post<DropoffLocationApiModel>(url, request)
      .pipe(
        catchError(() => {
          throw new Error('Failure creating new drop-off location');
        }),
        map(result => this.fromApiModel(result))
      );
  }

  updateDropoffLocation(location: DropoffLocation, websiteId: string): Observable<DropoffLocation> {
    const url = `${this.getBaseUrl(websiteId)}/dropoff-location/${location.id}`;
    const request = this.toApiModel(location);

    return this.http.put<DropoffLocationApiModel>(url, request)
      .pipe(
        map(result => this.fromApiModel(result))
      );
  }

  updateDropoffLocationHours(websiteId: string, hours: LocationHours[]): Observable<boolean> {
    const url = `${this.getBaseUrl(websiteId)}/dropoff-locations/hours`;

    const request: LocationHoursApiModel[] = hours.map(x => {
      return {
        dayofWeek: x.dayofWeek,
        openTime: this.toTimeSpan(x.openTime),
        closeTime: this.toTimeSpan(x.closeTime),
        closed: x.closed,
        temporary: x.temporary
      };
    });

    return this.http.put<boolean>(url, request)
      .pipe(
        map(() => true),
        catchError(() => {
          throw new Error('Failure saving drop-off location hours');
        })
      );
  }

  saveImage(websiteId: string, file: File): Observable<DropoffImageResult> {
    const url = `${this.getBaseUrl(websiteId)}/image`;

    const formData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post<DropoffImageResult>(url, formData);
  }

  private getBaseUrl(websiteId: string): string {
    const config = this.consumersiteConfig.find(x => x.id === websiteId);
    return `${config.baseUrl}/dropoff-location-management`;
  }

  private fromApiModel(model: DropoffLocationApiModel): DropoffLocation {
    return {
      id: model.id,
      name: model.name,
      address: model.address,
      address2: model.address2,
      city: model.city,
      state: model.state,
      zip: model.zip,
      active: model.active,
      latitude: model.latitude,
      longitude: model.longitude,
      image: model.image,
      pageSlug: model.pageSlug,
      pageContent: model.pageContent,
      hours: model.hours.map(x => {
        return {
          dayofWeek: x.dayofWeek,
          openTime: this.fromTimeSpan(x.openTime),
          closeTime: this.fromTimeSpan(x.closeTime),
          closed: x.closed,
          temporary: x.temporary
        };
      }),
      imageUrls: model.imageUrls,
      locatedNear: model.locatedNear,
      charities: model.charities
    };
  }

  private toApiModel(location: DropoffLocation): DropoffLocationApiModel {
    return {
      id: location.id,
      name: location.name,
      address: location.address,
      address2: location.address2,
      city: location.city,
      state: location.state,
      zip: location.zip,
      active: location.active,
      latitude: location.latitude,
      longitude: location.longitude,
      image: location.image,
      pageSlug: location.pageSlug,
      pageContent: location.pageContent,
      hours: location.hours.map(x => {
        return {
          dayofWeek: x.dayofWeek,
          openTime: this.toTimeSpan(x.openTime),
          closeTime: this.toTimeSpan(x.closeTime),
          closed: x.closed,
          temporary: x.temporary
        };
      }),
      imageUrls: location.imageUrls,
      locatedNear: location.locatedNear,
      charities: location.charities
    };
  }

  private fromTimeSpan(value: string): Date {
    const timeParts = value.split(':');

    const date = new Date(0);
    date.setHours(parseInt(timeParts[0], 10));
    date.setMinutes(parseInt(timeParts[1], 10));
    date.setSeconds(parseInt(timeParts[2], 10));
    date.setMilliseconds(0);

    return date;
  }

  private toTimeSpan(value: Date): string {
    return new Intl.DateTimeFormat('en-us', {
      hour12: false,
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
    }).format(value);
  }
}
