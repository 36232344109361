<div class="form-group">
  <label class="d-block">Image</label>
  <figure *ngIf="smallImage" kendoUploadDropZone="imageDropZone" class="figure">
    <img [src]="smallImage.src" alt="Preview Large Image" class="img-fluid preview-image">
    <figcaption class="figure-caption">
      <button type="button" class="btn btn-link" (click)="previewSmallImage()">
        Preview Small Image
        <span class="k-icon k-i-hyperlink-open"></span>
      </button>
      <button type="button" class="btn btn-link" (click)="previewLargeImage()">
        Preview Large Image
        <span class="k-icon k-i-hyperlink-open"></span>
      </button>
    </figcaption>
  </figure>
  <div class="text-muted font-weight-normal">(Recommended Size: {{ largeImageWidth }}&times; {{ largeImageHeight }})</div>
  <kendo-fileselect
    [multiple]="false"
    [restrictions]="fileSelectRestrictions"
    [showFileList]="false"
    [disabled]="disabled"
    zoneId="imageDropZone"
    (valueChange)="imageChanged($event)">
  </kendo-fileselect>
</div>
<div class="form-group">
  <label [for]="imageAltText">Alternate Text</label>
  <input #imageAltText
    kendoTextBox
    [(ngModel)]="slide.imageAlt"
    [disabled]="disabled"
    class="w-100">
</div>
<div class="form-group">
  <label [for]="imageLinkTypeSelect">Image Navigation Type</label>
  <kendo-dropdownlist #imageLinkTypeSelect
    [(ngModel)]="imageLinkType"
    [data]="imageLinkTypes"
    textField="text"
    valueField="value"
    [valuePrimitive]="true"
    class="w-100"
    [disabled]="disabled"
    (valueChange)="imageLinkTypeChanged($event)">
  </kendo-dropdownlist>

  <div *ngIf="imageLinkType !== 'None'" class="form-group">
    <label [for]="navigateUrlTextBox">Navigate to URL</label>
    <div class="input-group">
      <div *ngIf="imageLinkType === 'Internal'" class="input-group-prepend">
        <span class="input-group-text">{{ websiteConfig.baseUrl }}</span>
      </div>
      <input #navigateUrlTextBox
        class="form-control"
        [(ngModel)]="slide.navigateUrl"
        [disabled]="disabled">
    </div>
  </div>
</div>

<kendo-dialog *ngIf="previewDialogOpened"
  [title]="previewDialogTitle"
  [maxWidth]="previewDialogWidth"
  (close)="previewDialogOpened = false">
  <figure class="figure">
    <img [src]="previewDialogImage.src" [alt]="previewDialogImage.alt" class="img-fluid">
    <figcaption class="figure-caption">
      <label class="font-weight-bold">Actual Image Size</label>
      {{ previewDialogImage.width }}&times;{{ previewDialogImage.height }}
    </figcaption>
  </figure>
</kendo-dialog>
