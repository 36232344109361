<ul class="list-unstyled">
  <li>
    <label>Justify Content</label>
    <span>{{ content.justifyContent }}</span>
  </li>
  <li>
    <label>Columns</label>
    <ol>
      <li *ngFor="let column of content.columns" [innerHTML]="column"></li>
    </ol>
  </li>
</ul>
