<nav class="navbar">
  <button type="button"
          class="navbar-toggler d-md-none"
          aria-controls="sidebar-nav"
          aria-expanded="false"
          aria-label="Toggle navigation"
          (click)="onToggleNavClicked()">
    <span class="navbar-toggler-icon"></span>
  </button>
  <h1 class="text-center">2nd Ave Admin</h1>

  <div class="nav-menu"
       [class.slide-out]="isNavSlideOut"
       [class.open]="navOpen"
       id="sidebar-nav"
       role="navigation"
       [style.height.px]="navHeight">
    <ul *ngIf="signedIn" id="nav-accordion" class="accordion" [style.max-height.px]="navHeight - 30">
      <li *ngIf="websitesToManage && websitesToManage.length">
        <button type="button"
                class="btn nav-item"
                [class.collapsed]="!isNavActive('website-management')"
                data-toggle="collapse"
                data-target="#website-management-collapse">
          <span class="caret k-icon k-i-arrow-60-down"></span>
          Website Management
        </button>
        <ul id="website-management-collapse"
            class="collapse"
            [class.show]="isNavActive('website-management')"
            data-parent="#nav-accordion">
          <li *ngFor="let website of websitesToManage">
            <a [routerLink]="['/website-management', website.id]" routerLinkActive="active" class="nav-item">
              <span class="k-icon k-i-toggle-full-screen-mode"></span>
              {{ website.name }}
            </a>
          </li>
        </ul>
      </li>
      <li>
        <a routerLink="/signout" class="nav-item">
          <span class="k-icon k-i-logout"></span>
          Sign Out
        </a>
      </li>
    </ul>
    <ul *ngIf="!signedIn">
      <li>
        <a routerLink="/signin" routerLinkActive="active" class="nav-item">
          <span class="k-icon k-i-login"></span>
          Sign In
        </a>
      </li>
    </ul>
    <div class="nav-footer">
      <p class="m-0">{{copyright}}</p>
    </div>
  </div>
</nav>
