import { Carousel } from 'src/app/models/website-management/carousel';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-carousel-preview',
  templateUrl: './carousel-preview.component.html',
  styleUrls: ['./carousel-preview.component.scss']
})
export class CarouselPreviewComponent {
  @Input() websiteId: string;
  @Input() componentId: string;
  @Input() content: Carousel;

  get slideIntervalSeconds(): number {
    if (typeof this.content.slideInterval !== 'number') {
      return undefined;
    }

    return this.content.slideInterval / 1000;
  }
}
