<app-loading-spinner *ngIf="!pressItem"></app-loading-spinner>

<form *ngIf="pressItem" #pressItemForm="ngForm" appPressItemFormValidator class="container-fluid" novalidate>
  <div class="row justify-content-end mb-4">
    <div class="col-auto">
      <a class="font-weight-bold" [routerLink]="['/website-management', websiteConfig.id, 'press-items']">
        <span class="k-icon k-i-arrow-left"></span>
        Return to website management
      </a>
    </div>
  </div>
  <div class="row justify-content-end mb-5">
    <div class="col-auto">
      <button type="button"
        class="btn btn-primary"
        [disabled]="!dirty || loading"
        (click)="savePressItem()">
        <span class="k-icon k-i-save"></span>
        Save Press Item
      </button>
    </div>
    <div class="col-auto">
      <button type="button"
        class="btn btn-secondary"
        [disabled]="!dirty || loading"
        (click)="abandonChanges()">
        <span class="k-icon k-i-cancel"></span>
        Abandon Changes
      </button>
    </div>
  </div>

  <div class="form-group">
    <label for="title" class="font-weight-bold">Title</label>
    <input #title="ngModel"
      type="text"
      name="title"
      maxlength="255"
      required
      class="form-control"
      [(ngModel)]="pressItem.title"
      [disabled]="loading">
    <div *ngIf="title.errors?.required && (title.dirty || title.touched)" class="alert alert-danger my-2">
      Title is required.
    </div>
  </div>

  <div class="form-group">
    <label class="font-weight-bold">Category</label>
    <div>
      <input kendoRadioButton #pressRelease
        type="radio"
        name="category"
        value="PressRelease"
        required
        [(ngModel)]="pressItem.category"
        [disabled]="loading">
      <label [for]="pressRelease" class="ml-1">Press Release</label>
    </div>
    <div>
      <input kendoRadioButton #pressCoverage
        type="radio"
        name="category"
        value="PressCoverage"
        [(ngModel)]="pressItem.category"
        [disabled]="loading">
      <label [for]="pressCoverage" class="ml-1">Press Coverage</label>
    </div>
  </div>

  <label class="font-weight-bold">Resource</label>
  <div class="form-row">
    <div class="form-group col-auto" [style.width.em]="10">
      <input kendoRadioButton #urlResourceType
        type="radio"
        name="resourceType"
        value="Url"
        [(ngModel)]="pressItem.resourceType"
        [disabled]="loading">
      <label [for]="urlResourceType" class="ml-1">URL</label>
    </div>
    <div class="form-group col">
      <input #resource="ngModel"
        type="text"
        name="resource"
        maxlength="500"
        class="form-control"
        [(ngModel)]="resourceUrl"
        [disabled]="loading || pressItem.resourceType !== 'Url'">
    </div>
  </div>
  <div class="form-row">
    <div *ngIf="pressItemForm.errors?.urlRequired && (pressItemForm.touched || pressItemForm.dirty)"
      class="alert alert-danger"
      [style.margin-left.em]="10">
      A value is required when URL is selected.
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-auto" [style.width.em]="10">
      <input kendoRadioButton #documentResourceType
        type="radio"
        name="resourceType"
        value="Document"
        [(ngModel)]="pressItem.resourceType"
        [disabled]="loading">
      <label [for]="documentResourceType" class="ml-1">Document</label>
    </div>
    <div class="form-group col">
      <kendo-fileselect #documentResource
        name="documentResource"
        [multiple]="false"
        [showFileList]="false"
        [(ngModel)]="documentFiles"
        [disabled]="loading || pressItem.resourceType !== 'Document'"
        (valueChange)="documentChanged($event)">
      </kendo-fileselect>
      <a *ngIf="pressItem.resourceType === 'Document' && documentFileName" [href]="documentFileUrl" download>
        <span class="k-icon k-i-file"></span>
        {{ documentFileName }}
      </a>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-6">
      <label [for]="source" class="d-block font-weight-bold">Source</label>
      <input #source
        type="text"
        name="source"
        maxlength="128"
        class="form-control"
        [(ngModel)]="pressItem.source"
        [disabled]="loading">
    </div>
    <div class="form-group col-6">
      <label [for]="publishDate" class="d-block font-weight-bold">Publish Date</label>
      <kendo-datepicker #publishDate
        [(value)]="pressItem.publishDate">
      </kendo-datepicker>
    </div>
  </div>

  <div class="form-group">
    <label class="d-block font-weight-bold">Expiration Date</label>
    <input kendoCheckBox
      type="checkbox"
      name="expires"
      class="mr-2"
      [(ngModel)]="expires"
      [disabled]="loading"
      (change)="expiresChanged()">
    <kendo-datetimepicker
      [style.width.rem]="15"
      [(value)]="pressItem.expirationDate"
      [disabled]="loading || !expires">
    </kendo-datetimepicker>
  </div>
</form>

<kendo-dialog title="Abandon Changes" *ngIf="abandonDialogOpened" [minWidth]="250">
  <p>Are you sure you want to abandon the changes to this service area?</p>
  <kendo-dialog-actions>
    <button kendoButton (click)="abandonDialogClick(true)" [primary]="true">Yes</button>
    <button kendoButton (click)="abandonDialogClick(false)">No</button>
  </kendo-dialog-actions>
</kendo-dialog>
