<div class="form-inline my-3">
  <div class="form-group mx-3">
    <label [for]="slideIntervalInput">Slide Interval (seconds)</label>
    <kendo-numerictextbox #slideIntervalInput
      [(ngModel)]="slideIntervalSeconds"
      [format]="'n1'"
      [autoCorrect]="true"
      [min]="1"
      [max]="30"
      [step]="0.5"
      [disabled]="disabled">
    </kendo-numerictextbox>
  </div>
  <div class="form-group mx-3">
    <input #displayIndicatorsCheckBox
      kendoCheckBox
      type="checkbox"
      [disabled]="disabled"
      [(ngModel)]="carousel.displayIndicators">
    <kendo-label [for]="displayIndicatorsCheckBox" text="Display Indicators" class="k-checkbox-label"></kendo-label>
  </div>
  <div class="form-group mx-3">
    <input #displayControlsCheckBox
      kendoCheckBox
      type="checkbox"
      [disabled]="disabled"
      [(ngModel)]="carousel.displayControls">
    <kendo-label [for]="displayControlsCheckBox" text="Display Controls" class="k-checkbox-label"></kendo-label>
  </div>
</div>

<button type="button" class="btn btn-secondary float-right" (click)="chooseSlide()">
  <span class="k-icon k-i-plus"></span>
  Add Slide
</button>
<h3>
  Edit Slides
  <span class="badge badge-pill badge-dark">{{ carousel.slides.length | number }}</span>
</h3>
<div #slidesContainer>
  <div *ngFor="let slide of carousel.slides; let i = index" class="my-4">
    <div class="text-right mb-2">
      <button *ngIf="i > 0"
          type="button"
          class="btn btn-secondary"
          [disabled]="disabled"
          (click)="moveUp(i)">
        <span class="k-icon k-i-arrow-up"></span>
        Move Up
      </button>
      <button *ngIf="i < carousel.slides.length - 1"
          type="button"
          class="btn btn-secondary ml-2"
          [disabled]="disabled"
          (click)="moveDown(i)">
        <span class="k-icon k-i-arrow-down"></span>
        Move Down
      </button>
      <button
          type="button"
          class="btn btn-danger ml-2"
          [disabled]="disabled"
          (click)="deleteSlide(i)">
        <span class="k-icon k-i-delete"></span>
        Delete Slide
      </button>
    </div>

    <h4>Slide {{ i + 1 | number }} &mdash; {{ slideTypeText(slide) }}</h4>

    <app-slide-draft
      [slide]="slide"
      [component]="component"
      [websiteConfig]="websiteConfig"
      [disabled]="disabled">
    </app-slide-draft>
  </div>
</div>

<kendo-dialog title="Choose the Type of Slide to Add" *ngIf="chooseSlideDialogOpened" [minWidth]="250">
  <label for="choose-slide-dropdown">Slide Type</label>
  <kendo-dropdownlist
    [(ngModel)]="newSlideType"
    [data]="slideTypes"
    textField="text"
    valueField="value"
    [valuePrimitive]="true">
  </kendo-dropdownlist>
  <kendo-dialog-actions>
    <button kendoButton (click)="chooseSlideOk()" [primary]="true">Ok</button>
    <button kendoButton (click)="chooseSlideCancel()">Cancel</button>
  </kendo-dialog-actions>
</kendo-dialog>
