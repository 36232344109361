<table class="table table-sm table-borderless">
  <thead *ngIf="!readonly">
    <tr>
      <th class="text-center">Week Day</th>
      <th class="text-center">Open</th>
      <th class="text-center">Close</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let hours of locationHours">
      <td [ngSwitch]="hours.dayofWeek" class="align-middle">
        <ng-template [ngSwitchCase]="1">Monday</ng-template>
        <ng-template [ngSwitchCase]="2">Tuesday</ng-template>
        <ng-template [ngSwitchCase]="3">Wednesday</ng-template>
        <ng-template [ngSwitchCase]="4">Thursday</ng-template>
        <ng-template [ngSwitchCase]="5">Friday</ng-template>
        <ng-template [ngSwitchCase]="6">Saturday</ng-template>
        <ng-template [ngSwitchCase]="7">Sunday</ng-template>
      </td>
      <td *ngIf="readonly && hours.closed">Closed</td>
      <td *ngIf="readonly && hours.closed">Closed</td>
      <td *ngIf="readonly && !hours.closed">{{ hours.openTime | date:'shortTime' }}</td>
      <td *ngIf="readonly && !hours.closed">{{ hours.closeTime | date:'shortTime' }}</td>
      <td *ngIf="!readonly" class="align-middle">
        <kendo-timepicker
          [value]="hours.openTime"
          [style.width.em]="8"
          [disabled]="disabled || hours.closed"
          (blur)="markAsTouched()"
          (valueChange)="openTimeChanged(hours.dayofWeek, $event)">
        </kendo-timepicker>
      </td>
      <td *ngIf="!readonly" class="align-middle">
        <kendo-timepicker
          [value]="hours.closeTime"
          [style.width.em]="8"
          [disabled]="disabled || hours.closed"
          (blur)="markAsTouched()"
          (valueChange)="closeTimeChanged(hours.dayofWeek, $event)">
        </kendo-timepicker>
      </td>
      <td *ngIf="!readonly" class="align-middle">
        <div class="row">
          <div class="col-auto">
            <label class="k-label">
              <input kendoCheckBox type="checkbox"
                [checked]="hours.closed"
                [disabled]="disabled"
                (blur)="markAsTouched()"
                (change)="closedChanged(hours.dayofWeek, $event)">
              Closed
            </label>
          </div>
          <div class="col-auto">
            <label class="k-label">
              <input kendoCheckBox type="checkbox"
                [checked]="hours.temporary"
                [disabled]="disabled"
                (blur)="markAsTouched()"
                (change)="temporaryChanged(hours.dayofWeek, $event)">
              Temporary
            </label>
          </div>
        </div>
      </td>
    </tr>
  </tbody>
</table>
