<header class="row justify-content-between">
  <h2 class="col-auto">Service Areas</h2>
  <div class="col-auto">
    <a [routerLink]="['/website-management', websiteConfig.id, 'service-area-management']" class="btn btn-link">
      <span class="k-icon k-i-plus"></span>
      New Service Area
    </a>
  </div>
</header>

<app-loading-spinner *ngIf="loading"></app-loading-spinner>

<kendo-grid [data]="serviceAreas" [loading]="loading" scrollable="none" id="service-area-grid">
  <kendo-grid-column field="id" class="d-none" headerClass="d-none">
  </kendo-grid-column>
  <kendo-grid-column class="reorder-handle">
    <ng-template kendoGridCellTemplate>
      <span class="k-icon k-i-reorder"></span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Active" class="col-active">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="k-icon {{ dataItem.active ? 'k-i-check text-success' : 'k-i-close-circle text-danger' }}"></span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Name">
    <ng-template kendoGridCellTemplate let-dataItem>
      <ng-template [ngIf]="!dataItem.address">
        <strong>{{ dataItem.city }} {{ dataItem.state }}</strong>
      </ng-template>
      <ng-template [ngIf]="dataItem.address && dataItem.address.length">{{ dataItem.address }}</ng-template>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="pageDescription" title="Description"></kendo-grid-column>
  <kendo-grid-column>
    <ng-template kendoGridCellTemplate let-dataItem>
      <button type="button"
        [routerLink]="['/website-management', websiteConfig.id, 'service-area-management', dataItem.id]"
        class="btn btn-link">
        <span class="k-icon k-i-edit"></span>
        Edit
      </button>
      <button type="button"
        class="btn btn-link"
        (click)="previewServiceArea(dataItem)">
        <span class="k-icon k-i-preview"></span>
        Preview
      </button>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
