import { HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { MsalBroadcastService, MsalGuard, MsalInterceptor, MsalModule, MsalService } from '@azure/msal-angular';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { EditorModule } from '@progress/kendo-angular-editor';
import { GaugesModule } from '@progress/kendo-angular-gauges';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { MenuModule } from '@progress/kendo-angular-menu';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { PopupModule } from '@progress/kendo-angular-popup';
import { SchedulerModule } from '@progress/kendo-angular-scheduler';
import { TooltipModule, TooltipsModule } from '@progress/kendo-angular-tooltip';
import { FileSelectModule, UploadModule, UploadsModule } from '@progress/kendo-angular-upload';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DropDownListFilterComponent } from './components/dropdown-filter.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { PageSlugValidatorDirective } from './directives/page-slug-validator.directive';
import { PressItemFormValidatorDirective } from './directives/press-item-form-validator.directive';
import { ServiceAreaFormValidatorDirective } from './directives/service-area-form-validator.directive';
import { SlideDraftSwitchDirective } from './directives/slide-draft-switch.directive';
import { ValidateGeocoordinateDirective } from './directives/validate-geocoordinate.directive';
import { HomeComponent } from './home/home.component';
import { MainSectionHeaderComponent } from './main-section-header/main-section-header.component';
import { NavComponent } from './nav/nav.component';
import { HumanizePipe } from './pipes/humanize.pipe';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignOutComponent } from './sign-out/sign-out.component';
import { CarouselDraftComponent } from './website-management/components/carousel-draft/carousel-draft.component';
import { CarouselPreviewComponent } from './website-management/components/carousel-preview/carousel-preview.component';
import {
    HtmlContentSlideDraftComponent
} from './website-management/components/html-content-slide-draft/html-content-slide-draft.component';
import {
    HtmlContentSlidePreviewComponent
} from './website-management/components/html-content-slide-preview/html-content-slide-preview.component';
import { HtmlEditorComponent } from './website-management/components/html-editor/html-editor.component';
import { LocationHoursComponent } from './website-management/components/location-hours/location-hours.component';
import {
    NewsRibbonDraftComponent
} from './website-management/components/news-ribbon-draft/news-ribbon-draft.component';
import {
    NewsRibbonPreviewComponent
} from './website-management/components/news-ribbon-preview/news-ribbon-preview.component';
import {
    ServiceAlertDraftComponent
} from './website-management/components/service-alert-draft/service-alert-draft.component';
import {
    ServiceAlertPreviewComponent
} from './website-management/components/service-alert-preview/service-alert-preview.component';
import {
    SingleImageSlideDraftComponent
} from './website-management/components/single-image-slide-draft/single-image-slide-draft.component';
import {
    SingleImageSlidePreviewComponent
} from './website-management/components/single-image-slide-preview/single-image-slide-preview.component';
import {
    SplitImageSlideDraftComponent
} from './website-management/components/split-image-slide-draft/split-image-slide-draft.component';
import {
    SplitImageSlidePreviewComponent
} from './website-management/components/split-image-slide-preview/split-image-slide-preview.component';
import { ContentManagementComponent } from './website-management/content-management/content-management.component';
import {
    DropoffLocationManagementComponent
} from './website-management/dropoff-location-management/dropoff-location-management.component';
import {
    PressItemManagementComponent
} from './website-management/press-item-management/press-item-management.component';
import { PromotionManagementComponent } from './website-management/promotion-management/promotion-management.component';
import {
    ServiceAreaManagementComponent
} from './website-management/service-area-management/service-area-management.component';
import { SlideDraftComponent } from './website-management/slide-draft/slide-draft.component';
import { StoreManagementComponent } from './website-management/store-management/store-management.component';
import {
    ComponentsComponent
} from './website-management/website-management/components/components/components.component';
import {
    DropoffLocationsComponent
} from './website-management/website-management/components/dropoff-locations/dropoff-locations.component';
import {
    PressItemsComponent
} from './website-management/website-management/components/press-items/press-items.component';
import {
    PromotionCalendarComponent
} from './website-management/website-management/components/promotion-calendar/promotion-calendar.component';
import {
    ServiceAreasComponent
} from './website-management/website-management/components/service-areas/service-areas.component';
import { StoresComponent } from './website-management/website-management/components/stores/stores.component';
import { WebsiteManagementComponent } from './website-management/website-management/website-management.component';
import { ContentManagementSwitchDirective } from './directives/content-management-switch.directive';
import { ContentDraftComponent } from './website-management/components/content-draft/content-draft.component';
import { SlidePreviewSwitchDirective } from './directives/slide-preview-switch.directive';
import { SlidePreviewComponent } from './website-management/slide-preview/slide-preview.component';
import 'hammerjs';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { CharitiesComponent } from './website-management/website-management/components/charities/charities.component';
import { CharityManagementComponent } from './website-management/charity-management/charity-management.component';

@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    NavComponent,
    SignOutComponent,
    MainSectionHeaderComponent,
    HomeComponent,
    LoadingSpinnerComponent,
    DropDownListFilterComponent,
    ContentManagementComponent,
    WebsiteManagementComponent,
    NewsRibbonPreviewComponent,
    NewsRibbonDraftComponent,
    CarouselPreviewComponent,
    CarouselDraftComponent,
    SingleImageSlidePreviewComponent,
    HtmlContentSlidePreviewComponent,
    SplitImageSlidePreviewComponent,
    SingleImageSlideDraftComponent,
    SplitImageSlideDraftComponent,
    HtmlContentSlideDraftComponent,
    PromotionManagementComponent,
    PromotionCalendarComponent,
    DropoffLocationsComponent,
    DropoffLocationManagementComponent,
    ServiceAreasComponent,
    ServiceAreaManagementComponent,
    PageSlugValidatorDirective,
    ServiceAreaFormValidatorDirective,
    HtmlEditorComponent,
    LocationHoursComponent,
    StoresComponent,
    StoreManagementComponent,
    ComponentsComponent,
    PressItemsComponent,
    PressItemManagementComponent,
    HumanizePipe,
    PressItemFormValidatorDirective,
    ServiceAlertDraftComponent,
    ServiceAlertPreviewComponent,
    ValidateGeocoordinateDirective,
    SlideDraftSwitchDirective,
    SlideDraftComponent,
    ContentManagementSwitchDirective,
    ContentDraftComponent,
    SlidePreviewSwitchDirective,
    SlidePreviewComponent,
    CharitiesComponent,
    CharityManagementComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    HttpClientJsonpModule,
    MsalModule,
    BrowserAnimationsModule,
    ButtonsModule,
    InputsModule,
    DateInputsModule,
    LabelModule,
    DropDownsModule,
    GridModule,
    DialogsModule,
    GaugesModule,
    ChartsModule,
    TooltipModule,
    MenuModule,
    LayoutModule,
    SchedulerModule,
    EditorModule,
    PopupModule,
    UploadModule,
    FileSelectModule,
    NotificationModule,
    GoogleMapsModule,
    ExcelExportModule,
    UploadsModule,
    TooltipsModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    MsalBroadcastService,
    MsalGuard,
    MsalService
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
