import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-sign-out',
  template: ''
})
export class SignOutComponent implements OnInit {

  constructor(private msal: MsalService) { }

  ngOnInit() {
    this.msal.logout();
  }
}
