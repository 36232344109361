import { Inject, Injectable, InjectionToken } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

export const APPLICATION_INSIGHTS = new InjectionToken<ApplicationInsights>('APPLICATION_INSIGHTS');

@Injectable({
  providedIn: 'root'
})
export class ApplicationInsightsService {
  constructor(@Inject(APPLICATION_INSIGHTS) private appInsights: ApplicationInsights) { }

  setUserId(userId: string): void {
    if (this.appInsights) {
      this.appInsights.setAuthenticatedUserContext(userId);
    }
  }

  clearUserId(): void {
    if (this.appInsights) {
      this.appInsights.clearAuthenticatedUserContext();
    }
  }

  logPageView(uri: string): void {
    if (this.appInsights) {
      this.appInsights.trackPageView({ uri });
    }
  }
}
