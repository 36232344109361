import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { CanDeactivateGuard } from './guards/can-deactivate.guard';
import { HomeComponent } from './home/home.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignOutComponent } from './sign-out/sign-out.component';
import { CharityManagementComponent } from './website-management/charity-management/charity-management.component';
import { ContentManagementComponent } from './website-management/content-management/content-management.component';
import {
    DropoffLocationManagementComponent
} from './website-management/dropoff-location-management/dropoff-location-management.component';
import {
    PressItemManagementComponent
} from './website-management/press-item-management/press-item-management.component';
import { PromotionManagementComponent } from './website-management/promotion-management/promotion-management.component';
import {
    ServiceAreaManagementComponent
} from './website-management/service-area-management/service-area-management.component';
import { StoreManagementComponent } from './website-management/store-management/store-management.component';
import { WebsiteManagementComponent } from './website-management/website-management/website-management.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'signin', canActivate: [MsalGuard], component: SignInComponent },
  { path: 'signout', component: SignOutComponent },
  { path: 'website-management', canActivate: [MsalGuard], children: [
    {
      path: ':websiteId/content-management/:componentId',
      component: ContentManagementComponent,
      canDeactivate: [CanDeactivateGuard]
    },
    {
      path: ':websiteId/promotion-management',
      component: PromotionManagementComponent,
      canDeactivate: [CanDeactivateGuard]
    },
    {
      path: ':websiteId/promotion-management/:promotionId',
      component: PromotionManagementComponent,
      canDeactivate: [CanDeactivateGuard]
    },
    {
      path: ':websiteId/store-management/:storeId',
      component: StoreManagementComponent,
      canDeactivate: [CanDeactivateGuard]
    },
    {
      path: ':websiteId/dropoff-location-management',
      component: DropoffLocationManagementComponent,
      canDeactivate: [CanDeactivateGuard]
    },
    {
      path: ':websiteId/dropoff-location-management/:dropoffLocationId',
      component: DropoffLocationManagementComponent,
      canDeactivate: [CanDeactivateGuard]
    },
    {
      path: ':websiteId/service-area-management',
      component: ServiceAreaManagementComponent,
      canDeactivate: [CanDeactivateGuard]
    },
    {
      path: ':websiteId/service-area-management/:serviceAreaId',
      component: ServiceAreaManagementComponent,
      canDeactivate: [CanDeactivateGuard]
    },
    {
      path: ':websiteId/press-item-management',
      component: PressItemManagementComponent,
      canDeactivate: [CanDeactivateGuard]
    },
    {
      path: ':websiteId/press-item-management/:pressItemId',
      component: PressItemManagementComponent,
      canDeactivate: [CanDeactivateGuard]
    },
    {
      path: ':websiteId/charity-management/:charityCode',
      component: CharityManagementComponent,
      canDeactivate: [CanDeactivateGuard]
    },
    {
      path: ':websiteId',
      redirectTo: '/website-management/:websiteId/components',
      pathMatch: 'full'
    },
    {
      path: ':websiteId/:section',
      component: WebsiteManagementComponent
    }
  ]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
