import { fromEvent, Observable, Subscription } from 'rxjs';
import { CONSUMERSITE_CONFIG, WINDOW } from 'src/app/injection-tokens';
import { fileSelectRestrictions as restrictions } from 'src/app/models/website-management/file-select-restrictions';
import { State } from 'src/app/models/website-management/state';
import { Store } from 'src/app/models/website-management/store';
import { StoreSubType } from 'src/app/models/website-management/store-sub-type';
import { WebsiteConfig } from 'src/app/models/website-management/website-config';
import { GoogleMapsService } from 'src/app/services/google-maps.service';
import { HeaderService } from 'src/app/services/header.service';
import { ContentManagementService } from 'src/app/services/website-management/content-management.service';
import { StoreManagementService } from 'src/app/services/website-management/store-management.service';
import { Component, HostListener, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MapGeocoder } from '@angular/google-maps';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from '@progress/kendo-angular-notification';

@Component({
  selector: 'app-store-management',
  templateUrl: './store-management.component.html',
  styleUrls: ['./store-management.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StoreManagementComponent implements OnInit, OnDestroy {
  store: Store;
  storeImage: HTMLImageElement;
  fileSelectRestrictions = restrictions;
  loading = false;
  abandonDialogOpened = false;
  geolocateDialogOpened = false;
  viewCoordinatesDialogOpened = false;

  readonly latitudeMin = -90;
  readonly latitudeMax = 90;
  readonly longitudeMin = -180;
  readonly longitudeMax = 180;
  readonly recommendedImageSize = '760x300';

  states$: Observable<State[]>;
  storeSubTypes$: Observable<StoreSubType[]>;
  googleApiLoaded$: Observable<boolean>;
  websiteConfig: WebsiteConfig;

  geolocatedLatLng: google.maps.LatLngLiteral;

  private _savedStore: string;
  private routeParamsSubscription: Subscription;
  private storeSubscription: Subscription;
  private geocodingSubscription: Subscription;
  private imageSubscription: Subscription;

  constructor(
    private storeManagement: StoreManagementService,
    private contentManagement: ContentManagementService,
    private geocoder: MapGeocoder,
    private route: ActivatedRoute,
    private header: HeaderService,
    private notification: NotificationService,
    googleMaps: GoogleMapsService,
    @Inject(WINDOW) private window: Window,
    @Inject(CONSUMERSITE_CONFIG) private consumersiteConfig: WebsiteConfig[]
  ) {
    this.googleApiLoaded$ = googleMaps.loaded;
  }

  ngOnInit(): void {
    this.routeParamsSubscription = this.route.paramMap.subscribe(params => {
      const websiteId = params.get('websiteId');
      this.websiteConfig = this.consumersiteConfig.find(x => x.id === websiteId);
      this.header.title = `Store Management for ${this.websiteConfig.name}`;

      this.states$ = this.contentManagement.getStates(websiteId);
      this.storeSubTypes$ = this.storeManagement.getStoreSubTypes(websiteId);
      const storeIdText = params.get('storeId');
      const storeId = parseInt(storeIdText, 10);

      this.loadStore(storeId);
    });
  }

  ngOnDestroy(): void {
    if (this.routeParamsSubscription) {
      this.routeParamsSubscription.unsubscribe();
    }
    if (this.storeSubscription) {
      this.storeSubscription.unsubscribe();
    }
    if (this.geocodingSubscription) {
      this.geocodingSubscription.unsubscribe();
    }
    if (this.imageSubscription) {
      this.imageSubscription.unsubscribe();
    }
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.dirty) {
      return confirm('You have unsaved changes that will lost if you leave. Are you sure you want to leave?');
    }
    return true;
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification(event: Event): void {
    if (this.dirty) {
      event.returnValue = true;
    }
  }

  get existingPageSlug(): string {
    return this.savedStore.pageSlug;
  }

  saveStore(): void {
    this.loading = true;

    this.storeManagement.saveStore(this.store, this.websiteConfig.id).subscribe(store => {
      this.store = store;
      this.savedStore = store;

      this.loading = false;
      this.notification.show({
        content: 'This store has been saved.',
        animation: { type: 'slide', duration: 350 },
        position: { horizontal: 'center', vertical: 'top' },
        type: { style: 'success', icon: true },
        hideAfter: 3000
      });
    });
  }

  geolocate(): void {
    this.loading = true;
    const address = `${this.store.address}, ${this.store.city}, ${this.store.state}`;
    this.geocodingSubscription = this.geocoder.geocode({ address }).subscribe(response => {
      const location = response.results[0].geometry.location;
      this.geolocatedLatLng = {
        lat: location.lat(),
        lng: location.lng()
      };

      this.geolocateDialogOpened = true;

      setTimeout(() => this.loading = false);
    });
  }

  useGeolocatedCoordinates(): void {
    this.store.latitude = this.geolocatedLatLng.lat;
    this.store.longitude = this.geolocatedLatLng.lng;

    this.geolocateDialogOpened = false;
  }

  imageChanged(files: File[]): void {
    if (!files || !files.length) {
      this.store.image = '';
      this.storeImage = undefined;
      return;
    }

    this.loading = true;

    this.storeManagement.saveImage(files[0], this.websiteConfig.id).subscribe(result => {
      this.store.image = result.image;
      this.store.imageUrl = result.imageUrl;
      this.loading = false;
      this.loadImage();
    });
  }

  abandonChanges(): void {
    this.abandonDialogOpened = true;
  }

  abandonDialogClick(value: boolean): void {
    if (value) {
      this.store = this.savedStore;
      this.loadImage();
    }

    this.abandonDialogOpened = false;
  }

  previewStore(event: Event): void {
    event.preventDefault();
    const url = (event.target as HTMLAnchorElement).href;
    this.window.open(url, 'preview');
  }

  get dirty(): boolean {
    return JSON.stringify(this.store) !== this._savedStore;
  }

  private loadStore(storeId: number) {
    this.loading = true;

    this.storeSubscription = this.storeManagement.getStore(storeId, this.websiteConfig.id).subscribe(store => {
      this.store = store;
      this.savedStore = store;
      this.loadImage();
      this.loading = false;
    });
  }

  get storeLatLng(): google.maps.LatLng {
    return new google.maps.LatLng(this.store.latitude, this.store.longitude);
  }

  private loadImage() {
    if (!this.store.image) {
      this.storeImage = undefined;
      return;
    }

    const image = new Image();
    fromEvent(image, 'load').subscribe(() => this.storeImage = image);
    image.src = this.store.imageUrl;
  }

  private get savedStore(): Store {
    const store = JSON.parse(this._savedStore) as Store;

    store.hours.forEach(hours => {
      hours.openTime = new Date(hours.openTime);
      hours.closeTime = new Date(hours.closeTime);
    });

    return store;
  }

  private set savedStore(store: Store) {
    this._savedStore = JSON.stringify(store);
  }
}
