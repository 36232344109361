<ul class="list-unstyled">
  <li>
    <label>Slide Height</label>
    {{ slide.dynamicHeight ? 'Dynamic' : 'Fixed' }}
  </li>
  <li>
    <label>Content</label>
    <div class="embed-responsive embed-responsive-16by9">
      <iframe [srcdoc]="slide.htmlContent" class="embed-responsive-item" sandbox></iframe>
    </div>
  </li>
</ul>
