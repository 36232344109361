import { Directive } from '@angular/core';
import { UntypedFormGroup, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: '[appValidateGeocoordinate]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: ValidateGeocoordinateDirective,
      multi: true
    }
  ]
})
export class ValidateGeocoordinateDirective implements Validator {

  readonly allowedGeoCoordBounds: google.maps.LatLngBounds[] = [
    // Continental U.S.
    new google.maps.LatLngBounds(
      { lat: 24.7433195, lng: -124.7844079 },
      { lat: 49.3457868, lng: -66.9513812 }),
    // Hawaii
    new google.maps.LatLngBounds(
      { lat: 18.805884, lng: -154.496905 },
      { lat: 22.415061, lng: -154.523443 }),
    // Alaska
    new google.maps.LatLngBounds(
      { lat: 53.030173, lng: -169.574714 },
      { lat: 71.407324, lng: -141.098151 })
  ];

  validate(form: UntypedFormGroup): ValidationErrors | null {
    if ('latitude' in form.controls && 'longitude' in form.controls) {
      const latitude = form.controls.latitude.value;
      const longitude = form.controls.longitude.value;
      const latLng = new google.maps.LatLng(latitude, longitude);

      if (!this.allowedGeoCoordBounds.some(x => x.contains(latLng))) {
        return { invalidLatLng: true }
      }
    }

    return null;
  }
}
