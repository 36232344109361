import { forkJoin, Observable, Subscription } from 'rxjs';
import { CONSUMERSITE_CONFIG } from 'src/app/injection-tokens';
import { WebsiteConfig } from 'src/app/models/website-management/website-config';
import { CharityManagementService } from 'src/app/services/website-management/charity-management.service';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Charity } from 'src/app/models/website-management/charity';
import { DmsCharity } from 'src/app/models/website-management/dms-charity';

@Component({
  selector: 'app-charities',
  templateUrl: './charities.component.html',
  styleUrls: ['./charities.component.scss']
})
export class CharitiesComponent implements OnInit, OnDestroy {
  websiteConfig: WebsiteConfig;
  charities$: Observable<Charity[]>;
  unclaimedCharities: DmsCharity[];

  loading = true;

  private routeSubscription: Subscription;
  private dmsCharitiesSubscription: Subscription;

  constructor(
    private charityManagement: CharityManagementService,
    private route: ActivatedRoute,
    @Inject(CONSUMERSITE_CONFIG) private consumersitesConfig: WebsiteConfig[]
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const websiteId = params.get('websiteId');
      this.websiteConfig = this.consumersitesConfig.find(x => x.id === websiteId);

      this.charities$ = this.charityManagement.getCharities(websiteId);
      this.loading = false;
    });

    const dmsCharities$ = this.charityManagement.getDmsCharities();
    const charities$ = this.charityManagement.getCharities(this.websiteConfig.id);
    this.dmsCharitiesSubscription = forkJoin([dmsCharities$, charities$]).subscribe(val => {
      const charityCodes = val[1].map(x => x.code);
      this.unclaimedCharities = val[0].filter(x => !charityCodes.includes(x.charityCode));
    });
  }

  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    if (this.dmsCharitiesSubscription) {
      this.dmsCharitiesSubscription.unsubscribe();
    }
  }
}
