import { Observable, Subscription } from 'rxjs';
import { CONSUMERSITE_CONFIG } from 'src/app/injection-tokens';
import { PressItem } from 'src/app/models/website-management/press-item';
import { WebsiteConfig } from 'src/app/models/website-management/website-config';
import { HeaderService } from 'src/app/services/header.service';
import { PressItemManagementService } from 'src/app/services/website-management/press-item-management.service';
import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '@progress/kendo-angular-notification';

@Component({
  selector: 'app-press-item-management',
  templateUrl: './press-item-management.component.html',
  styleUrls: ['./press-item-management.component.scss']
})
export class PressItemManagementComponent implements OnInit, OnDestroy {
  pressItem: PressItem;
  savedPressItem: string;
  loading: boolean;
  expires: boolean;
  abandonDialogOpened = false;
  resourceUrl: string;
  documentFiles: File[];
  documentFileName: string;
  documentFileUrl: string;
  websiteConfig: WebsiteConfig;

  private routeParamSubscription: Subscription;
  private fragmentSubscription: Subscription;
  private pressItemSubscription: Subscription;

  constructor(
    private pressItemManagement: PressItemManagementService,
    private route: ActivatedRoute,
    private router: Router,
    private header: HeaderService,
    private notification: NotificationService,
    @Inject(CONSUMERSITE_CONFIG) private consumersitesConfig: WebsiteConfig[]
  ) { }

  ngOnInit(): void {
    this.routeParamSubscription = this.route.paramMap.subscribe(params => {
      const websiteId = params.get('websiteId');
      this.websiteConfig = this.consumersitesConfig.find(x => x.id === websiteId);
      this.header.title = `Press Item Management for ${this.websiteConfig.name}`;

      const pressItemIdText = params.get('pressItemId');
      if (!pressItemIdText) {
        this.createNewPressItem();
      } else {
        this.fragmentSubscription = this.route.fragment.subscribe(fragment => {
          const pressItemId = parseInt(pressItemIdText, 10);
          this.loadPressItem(pressItemId, fragment === 'new');
        })
      }
    })
  }

  ngOnDestroy(): void {
    if (this.routeParamSubscription) {
      this.routeParamSubscription.unsubscribe();
    }
    if (this.fragmentSubscription) {
      this.fragmentSubscription.unsubscribe();
    }
    if (this.pressItemSubscription) {
      this.pressItemSubscription.unsubscribe();
    }
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.dirty) {
      return confirm('You have unsaved changes that will lost if you leave. Are you sure you want to leave?');
    }
    return true;
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification(event: Event): void {
    if (this.dirty) {
      event.returnValue = true;
    }
  }

  savePressItem(): void {
    this.loading = true;

    this.pressItem.resource = this.pressItem.resourceType === 'Url' ? this.resourceUrl : this.documentFileName;

    this.pressItemSubscription = this.pressItemManagement.savePressItem(this.pressItem, this.websiteConfig.id)
      .subscribe(pressItem => {
        const created = this.pressItem.id === 0;

        this.pressItem = pressItem;
        this.savedPressItem = JSON.stringify(this.pressItem);

        if (created) {
          this.router.navigate(
            [ 'website-management', this.websiteConfig.id, 'press-item-management', pressItem.id ],
            { fragment: 'new' });
        } else {
          this.loading = false;
          this.showNotification('This press item has been saved.');
        }
      });
  }

  abandonChanges(): void {
    this.abandonDialogOpened = true;
  }

  abandonDialogClick(value: boolean): void {
    if (value) {
      this.pressItem = JSON.parse(this.savedPressItem);
      this.pressItem.publishDate = new Date(this.pressItem.publishDate);
      if (this.pressItem.expirationDate) {
        this.pressItem.expirationDate = new Date(this.pressItem.expirationDate);
      }

      this.renderPressItem();
    }

    this.abandonDialogOpened = false;
  }

  documentChanged(files: File[]): void {
    if (!files || !files.length) {
      return;
    }

    this.loading = true;
    this.pressItemSubscription = this.pressItemManagement.createDocument(files[0], this.websiteConfig.id)
      .subscribe(url => {
        this.loading = false;
        this.pressItem.resource = url;
        this.renderPressItem();
      });
  }

  expiresChanged(): void {
    if (this.expires) {
      const dt = this.pressItem.publishDate;
      this.pressItem.expirationDate = new Date(dt.getFullYear() + 1, dt.getMonth(), dt.getDate());
    } else {
      this.pressItem.expirationDate = null;
    }
  }

  get dirty(): boolean {
    return JSON.stringify(this.pressItem) !== this.savedPressItem;
  }

  private createNewPressItem(): void {
    const now = new Date();

    this.pressItem = {
      id: 0,
      category: 'PressRelease',
      title: '',
      resourceType: 'Url',
      resource: '',
      source: '',
      publishDate: new Date(now.getFullYear(), now.getMonth(), now.getDate()),
      expirationDate: null
    };

    this.savedPressItem = JSON.stringify(this.pressItem);
    this.renderPressItem();
  }

  private loadPressItem(pressItemId: number, newPressItem: boolean) {
    this.loading = true;

    this.pressItemSubscription = this.pressItemManagement.getPressItem(pressItemId, this.websiteConfig.id)
      .subscribe(pressItem => {
        this.pressItem = pressItem;
        this.savedPressItem = JSON.stringify(pressItem);

        this.renderPressItem();
        this.expires = !!pressItem.expirationDate;

        this.loading = false;

        if (newPressItem) {
          this.showNotification('This press item has been created');
        }
      });
  }

  private showNotification(msg: string): void {
    this.notification.show({
      content: msg,
      animation: { type: 'slide', duration: 350 },
      position: { horizontal: 'center', vertical: 'top' },
      type: { style: 'success', icon: true },
      hideAfter: 3000
    });
  }

  private renderPressItem() {
    this.expires = !!this.pressItem.expirationDate;

    this.resourceUrl = '';
    this.documentFileUrl = '';
    this.documentFileName = '';

    if (this.pressItem.resourceType === 'Url') {
      this.resourceUrl = this.pressItem.resource;
    } else {
      this.documentFileUrl = this.pressItem.resource;

      const url = new URL(this.pressItem.resource);
      const segments = url.pathname.split('/');
      this.documentFileName = segments[segments.length - 1];
    }
  }
}
