import { Observable } from 'rxjs';
import { CONSUMERSITE_CONFIG } from 'src/app/injection-tokens';
import { Promo } from 'src/app/models/website-management/promo';
import { Schedule } from 'src/app/models/website-management/schedule';
import { WebsiteConfig } from 'src/app/models/website-management/website-config';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

export interface ImageResult {
  smallImageFilename: string;
  largeImageFilename: string;
  smallImageUrl: string;
  largeImageUrl: string;
}

@Injectable({
  providedIn: 'root'
})
export class PromotionManagementService {
  constructor(
    private http: HttpClient,
    @Inject(CONSUMERSITE_CONFIG) private consumersiteConfig: WebsiteConfig[]
  ) { }

  getSchedule(websiteId: string, start: Date, end: Date): Observable<Schedule> {
    const url = `${this.getBaseUrl(websiteId)}/schedule?start=${start.toISOString()}&end=${end.toISOString()}`;
    return this.http.get<Schedule>(url);
  }

  getPromotion(websiteId: string, promotionId: number): Observable<Promo> {
    const url = `${this.getBaseUrl(websiteId)}/promotion/${promotionId}`;

    return this.http.get<Promo>(url);
  }

  getAsset(websiteId: string, filename: string): Observable<string> {
    const url = `${this.getBaseUrl(websiteId)}/asset/${filename}`;

    return this.http.get(url, { responseType: 'text' });
  }

  savePromotion(websiteId: string, promotion: Promo): Observable<Promo> {
    if (!promotion) {
      throw new Error('Parameter "promotion" not provided');
    }

    if (promotion.id > 0) {
      const url = `${this.getBaseUrl(websiteId)}/promotion/${promotion.id}`;
      return this.http.put<Promo>(url, promotion);
    } else {
      const url = `${this.getBaseUrl(websiteId)}/promotion`;
      return this.http.post<Promo>(url, promotion);
    }
  }

  saveAsset(websiteId: string, file: File): Observable<ImageResult> {
    const url = `${this.getBaseUrl(websiteId)}/asset`;

    const formData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post<ImageResult>(url, formData);
  }

  private getBaseUrl(websiteId: string): string {
    const config = this.consumersiteConfig.find(x => x.id === websiteId);
    return `${config.baseUrl}/promotion-management`;
  }
}
