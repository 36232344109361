import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ComponentHistoryItem } from 'src/app/models/website-management/component-history-item';
import { State } from 'src/app/models/website-management/state';
import { WebsiteConfig } from 'src/app/models/website-management/website-config';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CONSUMERSITE_CONFIG } from '../../injection-tokens';
import { WebsiteComponent } from '../../models/website-management/website-component';

export interface ImageResult {
  imageFilename: string;
  imageUrl: string;
}

@Injectable({
  providedIn: 'root'
})
export class ContentManagementService {
  constructor(
    private http: HttpClient,
    @Inject(CONSUMERSITE_CONFIG) private consumersiteConfig: WebsiteConfig[]
  ) { }

  getComponent(websiteId: string, componentId: string): Observable<WebsiteComponent> {
    const url = `${this.getBaseUrl(websiteId)}/component/${componentId}`;

    return this.http.get<WebsiteComponent>(url)
      .pipe(
        catchError(err => {
          throw err;
        })
      );
  }

  getComponents(websiteId: string): Observable<WebsiteComponent[]> {
    const url = `${this.getBaseUrl(websiteId)}/component`;

    return this.http.get<WebsiteComponent[]>(url)
      .pipe(
        catchError(err => {
          throw err;
        })
      );
  }

  getComponentHistory(websiteId: string, componentId: string): Observable<ComponentHistoryItem[]> {
    const url = `${this.getBaseUrl(websiteId)}/component/${componentId}/history`;

    return this.http.get<ComponentHistoryItem[]>(url)
      .pipe(
        map(results => results.sort((a, b) => new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime())),
        catchError(err => {
          throw err;
        })
      );
  }

  createDraftComponent(websiteId: string, componentId: string, id: number): Observable<boolean> {
    const url = `${this.getBaseUrl(websiteId)}/component/${componentId}/draft`;
    const body = { id };

    return this.http.post<boolean>(url, body)
      .pipe(
        map(() => true),
        catchError(() => of(false))
      );
  }

  deleteDraftComponent(websiteId: string, componentId: string): Observable<boolean> {
    const url = `${this.getBaseUrl(websiteId)}/component/${componentId}/draft`;

    return this.http.delete<boolean>(url)
      .pipe(
        map(() => true),
        catchError(() => of(false))
      );
  }

  saveDraftComponent(websiteId: string, componentId: string, content: ComponentHistoryItem): Observable<boolean> {
    const url = `${this.getBaseUrl(websiteId)}/component/${componentId}/draft`;

    const body = {
      publishStart: content.publishStart,
      publishEnd: content.publishEnd,
      active: content.active,
      contents: JSON.stringify(content.contents)
    };

    return this.http.put<boolean>(url, body)
      .pipe(
        map(() => true),
        catchError(() => of(false))
      );
  }

  publishDraft(websiteId: string, componentId: string): Observable<boolean> {
    const url = `${this.getBaseUrl(websiteId)}/component/${componentId}/publish`;

    return this.http.post<boolean>(url, null)
      .pipe(
        map(() => true),
        catchError(() => of(false))
      );
  }

  deactivate(websiteId: string, componentId: string, contentId: number): Observable<boolean> {
    const url = `${this.getBaseUrl(websiteId)}/component/${componentId}/deactivate/${contentId}`;

    return this.http.post<boolean>(url, null)
      .pipe(
        map(() => true),
        catchError(() => of(false))
      );
  }

  getAsset(websiteId: string, componentId: string, filename: string): Observable<string> {
    const url = `${this.getBaseUrl(websiteId)}/component/${componentId}/asset/${filename}`;

    return this.http.get(url, { responseType: 'text' });
  }

  resizeAndSaveAsset(websiteId: string, componentId: string, file: File, width: number, height: number): Observable<ImageResult> {
    const url = `${this.getBaseUrl(websiteId)}/component/${componentId}/asset`;

    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('width', width.toString());
    formData.append('height', height.toString());

    return this.http.post<ImageResult>(url, formData);
  }

  getStates(websiteId: string): Observable<State[]> {
    const url = `${this.getBaseUrl(websiteId)}/states`;

    return this.http.get<State[]>(url);
  }

  private getBaseUrl(websiteId: string): string {
    const config = this.consumersiteConfig.find(x => x.id === websiteId);
    return `${config.baseUrl}/content-management`;
  }
}
