<header class="row justify-content-between">
  <h2 class="col-auto">Drop-off Locations</h2>
  <div class="col-auto">
    <button type="button"
      class="btn btn-link"
      [disabled]="loading"
      (click)="onChangeHoursClick()">
      <span class="k-icon k-i-clock"></span>
      Change Hours
    </button>
    <a [routerLink]="['/website-management', websiteConfig.id, 'dropoff-location-management']"
      class="btn btn-link">
      <span class="k-icon k-i-plus"></span>
      New Drop-off Location
    </a>
  </div>
</header>

<div *ngIf="loading" class="position-relative" [style.min-height.px]="175">
  <app-loading-spinner></app-loading-spinner>
</div>

<div class="accordion">
  <div *ngFor="let stateLocation of stateLocations" class="card border-0">
    <div class="card-header bg-white" id="heading-{{stateLocation.state}}">
      <h3 class="mb-0">
        <button type="button"
          class="btn btn-link collapsed text-dark font-weight-bold"
          [style.font-size.rem]="1.25"
          aria-expanded="stateLocation.expanded"
          [attr.aria-controls]="'collapse-' + stateLocation.state"
          (click)="dropoffLocationClick(stateLocation.state)">
          <span class="k-icon"
            [class.k-i-arrow-60-right]="!stateLocation.expanded"
            [class.k-i-arrow-60-down]="stateLocation.expanded">
          </span>
          {{ stateLocation.state }}
        </button>
      </h3>
    </div>
    <div id="collapse-{{stateLocation.state}}"
      class="collapse"
      [class.show]="stateLocation.expanded"
      [attr.aria-labelledby]="'heading-' + stateLocation.state">
      <div class="card-body row">
        <div *ngFor="let location of stateLocation.locations" class="col-12 col-sm-6 col-lg-4">
          <div class="card my-2" [class.inactive]="!location.active" [class.text-muted]="!location.active">
            <img class="card-img-top" [alt]="location.name" [src]="location.imageUrls.thumb">
            <div class="card-body">
              <h5 class="card-title">{{ location.name }}</h5>
              <address class="card-text">
                {{ location.address}}
                <br>
                {{ location.address2.length ? location.address2 : '' }}
                <br *ngIf="location.address2.length">
                {{ location.city }}, {{ location.state.abbreviation }} {{ location.zip }}
              </address>
              <app-location-hours [locationHours]="location.hours" [readonly]="true">
              </app-location-hours>
              <em *ngIf="!location.active" class="text-danger font-weight-bold">Not Active</em>
            </div>
            <footer class="card-body">
              <a [routerLink]="['/website-management', websiteConfig.id, 'dropoff-location-management', location.id]"
                class="card-link">
                <span class="k-icon k-i-pencil"></span>
                Edit
              </a>
              <a [href]="websiteConfig.baseUrl + '/find-a-location/' + location.pageSlug"
                class="card-link"
                (click)="previewDropoffLocation($event)">
                <span class="k-icon k-i-preview"></span>
                Preview
              </a>
            </footer>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<kendo-dialog *ngIf="opened"
  title="Drop-off Locations Hours"
  [width]="550"
  (close)="onDialogClose()">
  <div class="alert alert-warning">
    <span class="k-icon k-i-warning"></span>
    This will change the hours for <strong>ALL</strong> drop-off locations.
  </div>

  <app-location-hours #locationHours="ngModel"
    [(ngModel)]="allDropoffHours"
    [disabled]="loading">
  </app-location-hours>

  <div *ngIf="locationHours.errors?.invalidHours" class="alert alert-danger">
    The opening time must occur before the closing time.
  </div>

  <kendo-dialog-actions>
    <button kendoButton [disabled]="loading" (click)="onDialogClose()">Cancel</button>
    <button kendoButton
      [primary]="true"
      [disabled]="loading || !locationHours.valid"
      (click)="onSaveHours()">
      Save
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
