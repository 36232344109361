<fieldset class="form-group row">
  <legend class="col-form-label font-weight-bold col-auto pt-0">Height</legend>
  <div class="col-auto">
    <div class="form-check">
      <input #fixedHeight
        type="radio"
        class="form-check-input"
        [disabled]="disabled"
        [checked]="!slide.dynamicHeight"
        (change)="slide.dynamicHeight = false">
      <label class="form-check-label" [for]="fixedHeight">Fixed</label>
    </div>
    <div class="form-check">
      <input #dynamicHeight
        type="radio"
        class="form-check-input"
        [disabled]="disabled"
        [checked]="slide.dynamicHeight"
        (change)="slide.dynamicHeight = true">
      <label class="form-check-label" [for]="dynamicHeight">Dynamic</label>
    </div>
  </div>
</fieldset>

<kendo-editor [(ngModel)]="slide.htmlContent" [disabled]="disabled">
  <kendo-toolbar>
    <kendo-toolbar-buttongroup>
      <kendo-toolbar-button kendoEditorUndoButton></kendo-toolbar-button>
      <kendo-toolbar-button kendoEditorRedoButton></kendo-toolbar-button>
    </kendo-toolbar-buttongroup>
    <kendo-toolbar-buttongroup>
      <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
      <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
      <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
      <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
    </kendo-toolbar-buttongroup>
    <kendo-toolbar-buttongroup>
      <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
      <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
    </kendo-toolbar-buttongroup>
    <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>
    <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>
    <kendo-toolbar-buttongroup>
      <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
      <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
      <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
      <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
    </kendo-toolbar-buttongroup>
    <kendo-toolbar-buttongroup>
      <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
      <kendo-toolbar-button kendoEditorOutdentButton></kendo-toolbar-button>
    </kendo-toolbar-buttongroup>
    <kendo-toolbar-buttongroup>
      <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
      <kendo-toolbar-button kendoEditorUnlinkButton></kendo-toolbar-button>
    </kendo-toolbar-buttongroup>
    <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
    <kendo-toolbar-buttongroup>
      <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
      <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
      <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
      <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
    </kendo-toolbar-buttongroup>
    <kendo-toolbar-buttongroup>
      <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
      <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
      <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
    </kendo-toolbar-buttongroup>
    <kendo-toolbar-buttongroup>
      <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
    </kendo-toolbar-buttongroup>
  </kendo-toolbar>
</kendo-editor>
