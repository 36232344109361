<div class="form-inline float-right">
  <label class="mr-2" for="justify-content">Justify Content</label>
  <kendo-dropdownlist
    [(ngModel)]="content.justifyContent"
    [data]="justifyContentItems"
    textField="text"
    valueField="value"
    [valuePrimitive]="true"
    [disabled]="disabled">
  </kendo-dropdownlist>

  <button type="button"
          class="btn btn-secondary ml-4"
          (click)="addColumn()"
          [disabled]="disabled">
    <span class="k-icon k-i-plus"></span>
    Add Column
  </button>
</div>

<h3>
  Edit Columns
  <span class="badge badge-pill badge-dark">{{ content.columns.length | number }}</span>
</h3>
<div *ngFor="let column of content.columns; let i = index; trackBy: dontTrack" class="my-4">
  <div class="text-right mb-2">
    <button *ngIf="i > 0"
            type="button"
            class="btn btn-secondary"
            [disabled]="disabled"
            (click)="moveLeft(i)">
      <span class="k-icon k-i-arrow-left"></span>
      Move Left
    </button>
    <button *ngIf="i < content.columns.length - 1"
            type="button"
            class="btn btn-secondary ml-2"
            [disabled]="disabled"
            (click)="moveRight(i)">
      <span class="k-icon k-i-arrow-right"></span>
      Move Right
    </button>
    <button *ngIf="content.columns.length > 1"
            type="button"
            class="btn btn-danger ml-2"
            [disabled]="disabled"
            (click)="deleteColumn(i)">
      <span class="k-icon k-i-delete"></span>
      Delete Column
    </button>
  </div>

  <kendo-editor [(ngModel)]="content.columns[i]" [disabled]="disabled">
    <kendo-toolbar>
      <kendo-toolbar-buttongroup>
        <kendo-toolbar-button kendoEditorUndoButton></kendo-toolbar-button>
        <kendo-toolbar-button kendoEditorRedoButton></kendo-toolbar-button>
      </kendo-toolbar-buttongroup>
      <kendo-toolbar-buttongroup>
        <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
        <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
        <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
        <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
      </kendo-toolbar-buttongroup>
      <kendo-toolbar-buttongroup>
        <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
        <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
      </kendo-toolbar-buttongroup>

      <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>
      <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>

      <kendo-toolbar-buttongroup>
        <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
        <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
        <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
        <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
      </kendo-toolbar-buttongroup>
      <kendo-toolbar-buttongroup>
        <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
        <kendo-toolbar-button kendoEditorUnlinkButton></kendo-toolbar-button>
      </kendo-toolbar-buttongroup>
    </kendo-toolbar>
  </kendo-editor>
</div>
