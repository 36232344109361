import { Component, Input, Output, EventEmitter } from '@angular/core';
import { BaseFilterCellComponent, FilterService } from '@progress/kendo-angular-grid';
import { State, FilterDescriptor } from '@progress/kendo-data-query';

@Component({
  selector: 'dropdown-filter',
  template: `
    <kendo-dropdownlist
      [data]="data"
      (valueChange)="onChange($event)"
      [defaultItem]="defaultItem"
      [value]="selectedValue"
      [valuePrimitive]="false"
      [textField]="textField"
      [valueField]="valueField">
    </kendo-dropdownlist>
  `
})
export class DropDownListFilterComponent extends BaseFilterCellComponent {

  public get selectedValue(): any {
    const filter = this.filterByField(this.valueField);
    return filter ? filter.value : null;
  }

  @Input() public filterName: string;
  @Input() public gridState: State;
  @Input() public data: any[];
  @Input() public textField: string;
  @Input() public valueField: string;

  @Output() public changed = new EventEmitter<State>();

  public get defaultItem(): any {
    return {
      [this.textField]: 'All',
      [this.valueField]: 'All'
    };
  }

  constructor(filterService: FilterService) {
    super(filterService);
  }

  public onChange(value: any): void {

    let filters = this.gridState.filter.filters;

    for (var i = filters.length - 1; i >= 0; --i) {
      if (filters[i]["field"] == this.filterName) {
        filters.splice(i, 1);
      }
    }

    if (value.name != 'All') {
      let newFilter: FilterDescriptor = {
        field: this.filterName,
        operator: "contains",
        value: value.name
      }

      filters.push(newFilter);
    }

    this.gridState.filter.filters = filters;

    this.changed.emit(this.gridState);
  }
}
