<header class="row">
  <h2 class="col-auto">Sale Colors &amp; Promotions</h2>
  <div class="col form-check align-self-center">
    <input #inputActiveOnly
      type="checkbox"
      class="form-check-input"
      [(ngModel)]="activeOnly"
      (change)="refreshEvents()">
    <label class="form-check-label" [for]="inputActiveOnly">Active Only</label>
  </div>
  <div class="col-auto">
    <a [routerLink]="['/website-management', websiteConfig.id, 'promotion-management']" class="btn btn-link">
      <span class="k-icon k-i-plus"></span>
      New Promotion
    </a>
  </div>
</header>
<kendo-scheduler
  [kendoSchedulerBinding]="events"
  [resources]="resources"
  [selectedDate]="selectedDate"
  [loading]="scheduleLoading"
  [eventClass]="getEventClass"
  (dateChange)="schedulerDateChanged($event)"
  (eventClick)="eventClicked($event)"
  [style.height.px]="schedulerHeight">
  <ng-template kendoSchedulerEventTemplate let-event>
    <span [ngSwitch]="getEventType(event)">
      <span *ngSwitchCase="'ActivePromo'" class="event-title">
        <span class="k-icon k-i-pencil mr-2"></span>
        <span class="sr-only">Click to edit</span>
        {{ event.title }}
      </span>
      <span *ngSwitchCase="'InactivePromo'" class="event-title">
        <span class="k-icon k-i-pencil mr-2"></span>
        <span class="sr-only">Click to edit</span>
        <del>{{ event.title }}</del>
      </span>
      <span *ngSwitchDefault class="event-title">{{ event.title }}</span>
    </span>
  </ng-template>

  <kendo-scheduler-month-view [eventHeight]="eventHeight">
  </kendo-scheduler-month-view>
</kendo-scheduler>
