import { Subscription } from 'rxjs';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CONSUMERSITE_CONFIG } from '../../injection-tokens';
import { WebsiteConfig } from '../../models/website-management/website-config';
import { HeaderService } from '../../services/header.service';

@Component({
  selector: 'app-website-management',
  templateUrl: './website-management.component.html',
  styleUrls: ['./website-management.component.scss']
})
export class WebsiteManagementComponent implements OnInit, OnDestroy {
  websiteConfig: WebsiteConfig;
  section: string;

  private routeParamSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private header: HeaderService,
    @Inject(CONSUMERSITE_CONFIG) private consumersiteConfig: WebsiteConfig[]
  ) {}

  ngOnInit(): void {
    this.routeParamSubscription = this.route.paramMap.subscribe(params => {
      this.websiteConfig = this.consumersiteConfig.find(x => x.id === params.get('websiteId'));
      this.section = params.get('section');
      this.header.title = `Website Management for ${this.websiteConfig.name}`;
    });
  }

  ngOnDestroy(): void {
    if (this.routeParamSubscription) {
      this.routeParamSubscription.unsubscribe();
    }
  }

  createRelativeHref(componentName: string): string[] {
    return [ '/website-management', this.websiteConfig.id, componentName ];
  }

  isTabActive(section: string): boolean {
    return this.section === section;
  }
}
