<div class="row">
  <div class="col-12 col-lg-6">
    <h5>Left Image</h5>
    <app-single-image-slide-draft
      [slide]="slide.left"
      [component]="component"
      [websiteConfig]="websiteConfig"
      [largeImageWidth]="largeImageWidth"
      [largeImageHeight]="largeImageHeight"
      [smallImageWidth]="smallImageWidth"
      [smallImageHeight]="smallImageHeight"
      [disabled]="disabled">
    </app-single-image-slide-draft>
  </div>
  <div class="col-12 col-lg-6">
    <h5>Right Image</h5>
    <app-single-image-slide-draft
      [slide]="slide.right"
      [component]="component"
      [websiteConfig]="websiteConfig"
      [largeImageWidth]="largeImageWidth"
      [largeImageHeight]="largeImageHeight"
      [smallImageWidth]="smallImageWidth"
      [smallImageHeight]="smallImageHeight"
      [disabled]="disabled">
    </app-single-image-slide-draft>
  </div>
</div>
