import { ContentManagementSwitchDirective } from 'src/app/directives/content-management-switch.directive';
import { Carousel } from 'src/app/models/website-management/carousel';
import { ComponentHistoryItem } from 'src/app/models/website-management/component-history-item';
import { NewsRibbon } from 'src/app/models/website-management/news-ribbon';
import { ServiceAlert } from 'src/app/models/website-management/service-alert';
import { WebsiteComponent } from 'src/app/models/website-management/website-component';
import { WebsiteConfig } from 'src/app/models/website-management/website-config';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CarouselDraftComponent } from '../carousel-draft/carousel-draft.component';
import { NewsRibbonDraftComponent } from '../news-ribbon-draft/news-ribbon-draft.component';
import { ServiceAlertDraftComponent } from '../service-alert-draft/service-alert-draft.component';

@Component({
  selector: 'app-content-draft',
  templateUrl: './content-draft.component.html',
  styleUrls: ['./content-draft.component.scss']
})
export class ContentDraftComponent implements OnInit {
  @Input() draft: ComponentHistoryItem;
  @Input() component: WebsiteComponent;
  @Input() websiteConfig: WebsiteConfig;
  @Input() disabled: boolean;

  @ViewChild(ContentManagementSwitchDirective, { static: true }) appContentManagementSwitch!: ContentManagementSwitchDirective;

  ngOnInit(): void {
    const viewContainerRef = this.appContentManagementSwitch.viewContainerRef;
    viewContainerRef.clear();

    if (this.component.componentType === 'NewsRibbon') {
      const componentRef = viewContainerRef.createComponent(NewsRibbonDraftComponent);
      componentRef.instance.content = this.draft.contents as NewsRibbon;
      componentRef.instance.disabled = this.disabled;
    } else if (this.component.componentType === 'Carousel') {
      const componentRef = viewContainerRef.createComponent(CarouselDraftComponent);
      componentRef.instance.carousel = this.draft.contents as Carousel;
      componentRef.instance.component = this.component as unknown;
      componentRef.instance.websiteConfig = this.websiteConfig;
      componentRef.instance.disabled = this.disabled;
    } else if (this.component.componentType === 'ServiceAlert') {
      const componentRef = viewContainerRef.createComponent(ServiceAlertDraftComponent);
      componentRef.instance.content = this.draft.contents as ServiceAlert;
      componentRef.instance.disabled = this.disabled;
    } else {
      throw new TypeError('Unknown component type');
    }
  }
}
