import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { CONSUMERSITE_CONFIG } from '../injection-tokens';
import { WebsiteConfig } from '../models/website-management/website-config';

@Component({
  selector: 'app-signin',
  template: ''
})
export class SignInComponent implements OnInit {

  constructor(
    private router: Router,
    private msal: MsalService,
    @Inject(CONSUMERSITE_CONFIG) public websiteConfig: WebsiteConfig[]) { }

  async ngOnInit(): Promise<void> {
    for (const config of this.websiteConfig) {
      const response = await this.msal.acquireTokenSilent({ scopes: config.consentScopes }).toPromise();
      if (response && response.accessToken) {
        const tokens = response.accessToken.split('.');
        for (const rawToken of tokens) {
          try {
            const token = JSON.parse(atob(rawToken));
            if (token && 'roles' in token && token.roles.includes('WebsiteManager')) {
              this.router.navigate(['/website-management', config.id]);
              return;
            }
          } catch {
            continue;
          }
        }
      }
    }

    this.router.navigate(['/']);
  }
}

