import { LogLevel } from "@azure/msal-browser";

export function consoleLogger(level: LogLevel, message: string): void {
  if (!window.console) {
    return;
  }

  switch (level) {
    case LogLevel.Error:
      console.error(message);
      break;

    case LogLevel.Warning:
      console.warn(message);
      break;

    default:
      console.log(message);
      break;
  }
}
