<header class="row justify-content-between">
  <h2 class="col-auto">Charities</h2>
  <div class="col-auto">
    <div class="dropdown">
      <button type="button"
        class="btn btn-secondary dropdown-toggle"
        id="new-charity-dropdown"
        [disabled]="unclaimedCharities.length < 1"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false">
        <span class="k-icon k-i-plus"></span>
        New Charity
      </button>
      <div class="dropdown-menu dropdown-menu-right" aria-labelledby="new-charity-dropdown">
        <a *ngFor="let unclaimedCharity of unclaimedCharities"
          [routerLink]="['/website-management', websiteConfig.id, 'charity-management', unclaimedCharity.charityCode]"
          class="dropdown-item">
          {{ unclaimedCharity.name }}
        </a>
      </div>
    </div>
  </div>
</header>

<kendo-grid [data]="charities$ | async" [loading]="loading" scrollable="none">
  <kendo-grid-column field="code" title="Code" [width]="150">
  </kendo-grid-column>
  <kendo-grid-column field="dmsCharity.name" title="Name">
  </kendo-grid-column>
  <kendo-grid-column title="Display on Site" class="text-center">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span [class.text-danger]="dataItem.link.length < 1" [class.text-success]="dataItem.link.length > 0">
        {{ dataItem.link.length > 0 ? 'Yes' : 'No' }}
      </span>
    </ng-template>

  </kendo-grid-column>
  <kendo-grid-column title="Link" field="link">
  </kendo-grid-column>
  <kendo-grid-column [width]="110">
    <ng-template kendoGridCellTemplate let-dataItem>
      <a [routerLink]="['/website-management', websiteConfig.id, 'charity-management', dataItem.code]"
        class="text-primary">
        <span class="k-icon k-i-edit"></span>
        Edit
      </a>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
