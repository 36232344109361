import { Observable, Subscription } from 'rxjs';
import { CanComponentDeactivate } from 'src/app/guards/can-deactivate.guard';
import { CONSUMERSITE_CONFIG, WINDOW } from 'src/app/injection-tokens';
import { ServiceArea } from 'src/app/models/website-management/service-area';
import { State } from 'src/app/models/website-management/state';
import { WebsiteConfig } from 'src/app/models/website-management/website-config';
import { HeaderService } from 'src/app/services/header.service';
import { ContentManagementService } from 'src/app/services/website-management/content-management.service';
import { ServiceAreaManagementService } from 'src/app/services/website-management/service-area-management.service';
import { Component, HostListener, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '@progress/kendo-angular-notification';

@Component({
  selector: 'app-service-area-management',
  templateUrl: './service-area-management.component.html',
  styleUrls: ['./service-area-management.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ServiceAreaManagementComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  serviceArea: ServiceArea;
  savedServiceArea: string;
  loading = false;
  abandonDialogOpened = false;

  states$: Observable<State[]>;
  serviceAreaType: 'major' | 'minor';
  websiteConfig: WebsiteConfig;

  private routeParamSubscription: Subscription;
  private fragmentSubscription: Subscription;
  private serviceAreaSubscription: Subscription;

  constructor(
    private serviceAreaManagement: ServiceAreaManagementService,
    private contentManagement: ContentManagementService,
    private route: ActivatedRoute,
    private router: Router,
    private header: HeaderService,
    private notification: NotificationService,
    @Inject(WINDOW) private window: Window,
    @Inject(CONSUMERSITE_CONFIG) private consumersiteConfig: WebsiteConfig[]
  ) { }

  ngOnInit(): void {
    this.routeParamSubscription = this.route.paramMap.subscribe(params => {
      const websiteId = params.get('websiteId');
      this.websiteConfig = this.consumersiteConfig.find(x => x.id === websiteId);
      this.header.title = `Service Area Management for ${this.websiteConfig.name}`;

      this.states$ = this.contentManagement.getStates(websiteId);

      const serviceAreaIdText = params.get('serviceAreaId');
      if (!serviceAreaIdText) {
        this.createNewServiceArea();
      } else {
        this.fragmentSubscription = this.route.fragment.subscribe(fragment => {
          const serviceAreaId = parseInt(serviceAreaIdText, 10);
          this.loadServiceArea(serviceAreaId, fragment === 'new');
        });
      }
    });
  }

  ngOnDestroy(): void {
    if (this.routeParamSubscription) {
      this.routeParamSubscription.unsubscribe();
    }
    if (this.fragmentSubscription) {
      this.fragmentSubscription.unsubscribe();
    }
    if (this.serviceAreaSubscription) {
      this.serviceAreaSubscription.unsubscribe();
    }
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.dirty) {
      return confirm('You have unsaved changes that will lost if you leave. Are you sure you want to leave?');
    }
    return true;
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification(event: Event): void {
    if (this.dirty) {
      event.returnValue = true;
    }
  }

  saveServiceArea(): void {
    if (this.serviceAreaType === 'major' &&
      (this.serviceArea.city.trim().length < 1 || this.serviceArea.state.trim().length !== 2)) {
      return;
    } else if (this.serviceAreaType === 'minor' && this.serviceArea.address.trim().length < 1) {
      return;
    }

    if (this.serviceAreaType === 'major') {
      this.serviceArea.address = '';
    } else {
      this.serviceArea.city = '';
      this.serviceArea.state = '';
    }

    this.loading = true;

    if (this.serviceArea.id > 0) {
      this.serviceAreaManagement.updateServiceArea(this.serviceArea, this.websiteConfig.id).subscribe(() => {
        this.loading = false;
        this.savedServiceArea = JSON.stringify(this.serviceArea);
        this.notification.show({
          content: 'This service area has been saved.',
          animation: { type: 'slide', duration: 350 },
          position: { horizontal: 'center', vertical: 'top' },
          type: { style: 'success', icon: true },
          hideAfter: 3000
        });
      });
    } else {
      this.serviceAreaManagement.createServiceArea(this.serviceArea, this.websiteConfig.id).subscribe(result => {
        this.router.navigate(
          [ 'website-management', this.websiteConfig.id, 'service-area-management', result.id ], {
            fragment: 'new'
          });
      });
    }
  }

  get existingPageSlug(): string {
    return !this.savedServiceArea ? null :
      (JSON.parse(this.savedServiceArea) as ServiceArea).pageSlug;
  }

  abandonChanges(): void {
    this.abandonDialogOpened = true;
  }

  abandonDialogClick(value: boolean): void {
    if (value) {
      this.serviceArea = JSON.parse(this.savedServiceArea);
    }

    this.abandonDialogOpened = false;
  }

  serviceAreaTypeChange(serviceAreaType: 'major' | 'minor'): void {
    this.serviceAreaType = serviceAreaType;
  }

  previewServiceArea(event: Event): void {
    event.preventDefault();
    const url = (event.target as HTMLAnchorElement).href;
    this.window.open(url, 'preview');
  }

  private createNewServiceArea() {
    const serviceArea: ServiceArea = {
      id: 0,
      name: '',
      address: '',
      city: '',
      state: '',
      active: true,
      pageTitle: '',
      pageContent: '',
      pageSlug: '',
      pageDescription: '',
      sortOrder: 0
    };

    this.serviceAreaType = 'minor';

    this.serviceArea = serviceArea;
    this.savedServiceArea = JSON.stringify(serviceArea);
  }

  private loadServiceArea(serviceAreaId: number, newServiceArea: boolean) {
    this.loading = true;

    this.serviceAreaSubscription = this.serviceAreaManagement.getServiceArea(serviceAreaId, this.websiteConfig.id)
      .subscribe(serviceArea => {
        this.serviceArea = serviceArea;
        this.savedServiceArea = JSON.stringify(serviceArea);

        this.serviceAreaType = serviceArea.address && serviceArea.address.length > 0 ? 'minor' : 'major';

        this.loading = false;

        if (newServiceArea) {
          this.notification.show({
            content: 'This service area has been created.',
            animation: { type: 'slide', duration: 350 },
            position: { horizontal: 'center', vertical: 'top' },
            type: { style: 'success', icon: true },
            hideAfter: 3000
          });
        }
      });
  }

  get dirty(): boolean {
    return JSON.stringify(this.serviceArea) !== this.savedServiceArea;
  }
}
