<app-loading-spinner *ngIf="!serviceArea"></app-loading-spinner>

<form *ngIf="serviceArea" #serviceAreaForm="ngForm" appServiceAreaFormValidator class="service-area container-fluid" novalidate>
  <div class="row justify-content-end mb-4">
    <div class="col-auto">
      <a class="font-weight-bold" [routerLink]="['/website-management', websiteConfig.id, 'service-areas']">
        <span class="k-icon k-i-arrow-left"></span>
        Return to website management
      </a>
    </div>
  </div>
  <div class="row justify-content-end mb-5">
    <div class="col-auto">
      <button type="button"
        class="btn btn-primary"
        [disabled]="!dirty || loading || !serviceAreaForm.valid"
        (click)="saveServiceArea()">
        <span class="k-icon k-i-save"></span>
        Save Service Area
      </button>
    </div>
    <div class="col-auto">
      <button type="button"
        class="btn btn-secondary"
        [disabled]="!dirty || loading"
        (click)="abandonChanges()">
        <span class="k-icon k-i-cancel"></span>
        Abandon Changes
      </button>
    </div>
    <div class="col-auto">
      <a [href]="websiteConfig.baseUrl + '/' + serviceArea.pageSlug"
        class="btn btn-secondary"
        (click)="previewServiceArea($event)">
        <span class="k-icon k-i-preview"></span>
        Preview
      </a>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-auto" [style.width.px]="175">
      <input kendoRadioButton #majorServiceArea
        type="radio"
        name="serviceAreaType"
        value="major"
        [ngModel]="serviceAreaType"
        [disabled]="loading"
        (change)="serviceAreaTypeChange('major')">
      <label [for]="majorServiceArea" class="font-weight-bold ml-1">Major</label>
    </div>
    <div class="form-group col-4">
      <label [for]="city" class="font-weight-bold">City</label>
      <input #city
        type="text"
        name="city"
        maxlength="20"
        class="form-control"
        [(ngModel)]="serviceArea.city"
        [disabled]="loading || serviceAreaType !== 'major'">
    </div>
    <div class="form-group col-4">
      <label [for]="state" class="d-block font-weight-bold">State</label>
      <kendo-dropdownlist #state
        name="state"
        [data]="states$ | async"
        textField="abbreviation"
        valueField="abbreviation"
        [valuePrimitive]="true"
        [(ngModel)]="serviceArea.state"
        [disabled]="loading || serviceAreaType !== 'major'">
      </kendo-dropdownlist>
    </div>
  </div>
  <div class="form-row">
    <div class="col">
      <div *ngIf="serviceAreaForm.errors?.cityStateRequired && serviceAreaForm.controls.city.touched && serviceAreaForm.controls.state.touched"
        class="alert alert-danger">
        City &amp; State are required when &ldquo;Major&rdquo; is selected.
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-auto" [style.width.px]="175">
      <input kendoRadioButton #minorServiceArea
        type="radio"
        name="serviceAreaType"
        value="minor"
        [(ngModel)]="serviceAreaType"
        [disabled]="loading"
        (change)="serviceAreaTypeChange('minor')">
      <label [for]="minorServiceArea" class="ml-1">Minor</label>
    </div>
    <div class="form-group col-4">
      <label [for]="address" class="font-weight-bold">Service Area</label>
      <input #address
        type="text"
        name="address"
        maxlength="256"
        class="form-control"
        [style.width.rem]="30"
        [(ngModel)]="serviceArea.address"
        [disabled]="loading || serviceAreaType !== 'minor'">
    </div>
  </div>

  <div class="form-row">
    <div class="col">
      <div *ngIf="serviceAreaForm.errors?.serviceAreaRequired && serviceAreaForm.controls.address.touched"
        class="alert alert-danger">
        Service Area is required when &ldquo;Minor&rdquo; is selected.
      </div>
    </div>
  </div>

  <div class="form-group mb-3">
    <input kendoCheckBoxButton #active
      type="checkbox"
      name="active"
      [(ngModel)]="serviceArea.active"
      [disabled]="loading">
    <label [for]="active" class="font-weight-bold ml-1">Active</label>
  </div>

  <div class="form-group mb-3">
    <label for="page-title" class="font-weight-bold">Page Title</label>
    <input #pageTitle="ngModel"
      id="page-title"
      type="text"
      name="pageTitle"
      maxlength="256"
      required
      class="form-control w-100"
      [style.width.rem]="30"
      [(ngModel)]="serviceArea.pageTitle"
      [disabled]="loading">
    <div *ngIf="pageTitle.errors?.required && (pageTitle.dirty || pageTitle.touched)" class="alert alert-danger">
      Page Title is required.
    </div>
  </div>

  <div class="form-group mb-3">
    <label for="page-slug" class="font-weight-bold">Page Slug</label>
    <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text" id="page-slug-base-url">{{ websiteConfig.baseUrl }}/</span>
      </div>
      <input #pageSlug="ngModel"
        id="page-slug"
        type="text"
        name="pageSlug"
        maxlength="255"
        class="form-control"
        required
        [appPageSlugValidator]="[websiteConfig.baseUrl, existingPageSlug]"
        [(ngModel)]="serviceArea.pageSlug"
        aria-describedby="page-slug-base-url"
        [disabled]="loading">
    </div>
    <div *ngIf="pageSlug.errors?.required && (pageSlug.dirty || pageSlug.touched)" class="alert alert-danger">
      Page Slug is required.
    </div>
    <div *ngIf="pageSlug.errors?.invalidSyntax" class="alert alert-danger">
      A page slug can only contain lower-case letters, numbers and the "-" character.
    </div>
    <div *ngIf="pageSlug.errors?.notUnique" class="alert alert-danger">
      The page slug entered already exists. Page slugs must be unique.
    </div>
    <div *ngIf="pageSlug.errors?.uniqueFailure" class="alert alert-danger">
      Unable to determine if the page slug is unqiue. Please try again later.
    </div>
  </div>

  <div class="form-group mb-3">
    <label for="page-heading" class="font-weight-bold">Page Heading</label>
    <input #name="ngModel"
      id="page-heading"
      type="text"
      name="pageHeading"
      maxlength="255"
      required
      class="form-control w-100"
      [(ngModel)]="serviceArea.name"
      [disabled]="loading">
    <div *ngIf="name.errors?.required && (name.dirty || name.touched)" class="alert alert-danger">
      Page Heading is required.
    </div>
  </div>

  <div class="form-group mb-3">
    <label [for]="pageDescription" class="font-weight-bold">Page Description</label>
    <input #pageDescription
      type="text"
      name="pageDescription"
      maxlength="255"
      class="form-control w-100"
      [(ngModel)]="serviceArea.pageDescription"
      [disabled]="loading">
  </div>

  <div class="form-group pb-5">
    <label for="pageContent" class="font-weight-bold">Page Content</label>
    <app-html-editor #pageContent
      [(content)]="serviceArea.pageContent"
      name="pageContent"
      [websiteConfig]="websiteConfig"
      [disabled]="loading">
    </app-html-editor>
  </div>
</form>

<kendo-dialog title="Abandon Changes" *ngIf="abandonDialogOpened" [minWidth]="250">
  <p>Are you sure you want to abandon the changes to this service area?</p>
  <kendo-dialog-actions>
    <button kendoButton (click)="abandonDialogClick(true)" [primary]="true">Yes</button>
    <button kendoButton (click)="abandonDialogClick(false)">No</button>
  </kendo-dialog-actions>
</kendo-dialog>
