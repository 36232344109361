import { SlideDraftSwitchDirective } from 'src/app/directives/slide-draft-switch.directive';
import { CarouselSlide } from 'src/app/models/website-management/carousel-slide';
import { WebsiteConfig } from 'src/app/models/website-management/website-config';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SingleImageSlideDraftComponent } from '../components/single-image-slide-draft/single-image-slide-draft.component';
import { SingleImageSlide } from 'src/app/models/website-management/single-image-slide';
import { WebsiteComponent } from 'src/app/models/website-management/website-component';
import { SplitImageSlideDraftComponent } from '../components/split-image-slide-draft/split-image-slide-draft.component';
import { SplitImageSlide } from 'src/app/models/website-management/split-image-slide';
import { HtmlContentSlideDraftComponent } from '../components/html-content-slide-draft/html-content-slide-draft.component';
import { HtmlContentSlide } from 'src/app/models/website-management/html-content-slide';

@Component({
  selector: 'app-slide-draft',
  templateUrl: './slide-draft.component.html',
  styleUrls: ['./slide-draft.component.scss']
})
export class SlideDraftComponent implements OnInit {
  @Input() slide: CarouselSlide;
  @Input() component: Component;
  @Input() websiteConfig: WebsiteConfig;
  @Input() disabled: boolean;

  @ViewChild(SlideDraftSwitchDirective, {static: true}) appSlideDraftSwitch!: SlideDraftSwitchDirective;

  ngOnInit(): void {
    const viewContainerRef = this.appSlideDraftSwitch.viewContainerRef;
    viewContainerRef.clear();

    if (this.slide.type === 'ConsumerSites.Common.Models.SingleImageSlide') {
      const componentRef = this.appSlideDraftSwitch.viewContainerRef.createComponent(SingleImageSlideDraftComponent);
      componentRef.instance.slide = this.slide as SingleImageSlide;
      componentRef.instance.component = this.component as WebsiteComponent;
      componentRef.instance.websiteConfig = this.websiteConfig;
      componentRef.instance.disabled = this.disabled;
    } else if (this.slide.type === 'ConsumerSites.Common.Models.SplitImageSlide') {
      const componentRef = this.appSlideDraftSwitch.viewContainerRef.createComponent(SplitImageSlideDraftComponent);
      componentRef.instance.slide = (this.slide as unknown) as SplitImageSlide;
      componentRef.instance.component = this.component as WebsiteComponent;
      componentRef.instance.websiteConfig = this.websiteConfig;
      componentRef.instance.disabled = this.disabled;
    } else if (this.slide.type === 'ConsumerSites.Common.Models.HtmlContentSlide') {
      const componentRef = this.appSlideDraftSwitch.viewContainerRef.createComponent(HtmlContentSlideDraftComponent);
      componentRef.instance.slide = (this.slide as unknown) as HtmlContentSlide;
      componentRef.instance.disabled = this.disabled;
    } else {
      throw new TypeError('Unknown slide type');
    }
  }
}
