import { Component, Input } from '@angular/core';
import { NewsRibbon } from '../../../models/website-management/news-ribbon';

@Component({
  selector: 'app-news-ribbon-preview',
  templateUrl: './news-ribbon-preview.component.html',
  styleUrls: ['./news-ribbon-preview.component.scss']
})
export class NewsRibbonPreviewComponent {
  @Input() content: NewsRibbon;
}
