import { fromEvent, Subscription } from 'rxjs';
import { fileSelectRestrictions as restrictions } from 'src/app/models/website-management/file-select-restrictions';
import { imageLinkTypes as linkTypes, ImageLinkTypeValue } from 'src/app/models/website-management/image-link-type';
import { SingleImageSlide } from 'src/app/models/website-management/single-image-slide';
import { WebsiteComponent } from 'src/app/models/website-management/website-component';
import { WebsiteConfig } from 'src/app/models/website-management/website-config';
import { ContentManagementService } from 'src/app/services/website-management/content-management.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-single-image-slide-draft',
  templateUrl: './single-image-slide-draft.component.html',
  styleUrls: ['./single-image-slide-draft.component.scss']
})
export class SingleImageSlideDraftComponent implements OnInit, OnDestroy {
  @Input() slide: SingleImageSlide;
  @Input() component: WebsiteComponent;
  @Input() websiteConfig: WebsiteConfig;
  @Input() largeImageWidth = 1140;
  @Input() largeImageHeight = 445;
  @Input() smallImageWidth = 540;
  @Input() smallImageHeight = 211;
  @Input() disabled: boolean;

  readonly fileSelectRestrictions = restrictions;
  readonly imageLinkTypes = linkTypes;

  previewDialogOpened = false;
  previewDialogTitle: string;
  previewDialogWidth: number;
  previewDialogImage: HTMLImageElement;

  imageLinkType: ImageLinkTypeValue;

  smallImage: HTMLImageElement;
  largeImage: HTMLImageElement;

  private smallImageSubscription: Subscription;
  private largeImageSubscription: Subscription;

  constructor(private contentManagement: ContentManagementService) { }

  ngOnInit(): void {
    this.imageLinkType = !this.slide.navigateUrl ? 'None' :
      this.slide.navigateTarget === '_blank' ? 'External' :
      'Internal';

    if (this.slide.imageSrcSmall) {
      this.smallImageSubscription = this.contentManagement
        .getAsset(this.websiteConfig.id, this.component.componentId, this.slide.imageSrcSmall)
        .subscribe(src => {
          const image = new Image();
          fromEvent(image, 'load').subscribe(() => this.smallImage = image);
          image.src = src;
        });
    }

    if (this.slide.imageSrcLarge) {
      this.largeImageSubscription = this.contentManagement
        .getAsset(this.websiteConfig.id, this.component.componentId, this.slide.imageSrcLarge)
        .subscribe(src => {
          const image = new Image();
          fromEvent(image, 'load').subscribe(() => this.largeImage = image);
          image.src = src;
        });
    }
  }

  ngOnDestroy(): void {
    if (this.smallImageSubscription) {
      this.smallImageSubscription.unsubscribe();
    }
    if (this.largeImageSubscription) {
      this.largeImageSubscription.unsubscribe();
    }
  }

  imageChanged(files: File[]): void {
    if (!files || !files.length) {
      this.smallImage = undefined;
      this.largeImage = undefined;
      return;
    }

    this.largeImageSubscription = this.contentManagement
      .resizeAndSaveAsset(this.websiteConfig.id, this.component.componentId, files[0], this.largeImageWidth,
        this.largeImageHeight)
      .subscribe(result => {
        this.slide.imageSrcLarge = result.imageFilename;
        const image = new Image();
        fromEvent(image, 'load').subscribe(() => this.largeImage = image);
        image.src = result.imageUrl;
      });

    this.smallImageSubscription = this.contentManagement
      .resizeAndSaveAsset(this.websiteConfig.id, this.component.componentId, files[0], this.smallImageWidth,
        this.smallImageHeight)
      .subscribe(result => {
        this.slide.imageSrcSmall = result.imageFilename;
        const image = new Image();
        fromEvent(image, 'load').subscribe(() => this.smallImage = image);
        image.src = result.imageUrl;
      });
  }

  imageLinkTypeChanged(value: ImageLinkTypeValue): void {
    this.slide.navigateUrl = '';
    this.slide.navigateTarget = value === 'External' ? '_blank' : '';
  }

  previewSmallImage(): void {
    this.previewDialogOpened = true;
    this.previewDialogTitle = 'Preview Small Image';
    this.previewDialogImage = this.smallImage;
    this.previewDialogWidth = 575;
  }

  previewLargeImage(): void {
    this.previewDialogOpened = true;
    this.previewDialogTitle = 'Preview Large Image';
    this.previewDialogImage = this.largeImage;
    this.previewDialogWidth = 1140;
  }
}
