import { SlidePreviewSwitchDirective } from 'src/app/directives/slide-preview-switch.directive';
import { CarouselSlide } from 'src/app/models/website-management/carousel-slide';
import { HtmlContentSlide } from 'src/app/models/website-management/html-content-slide';
import { SingleImageSlide } from 'src/app/models/website-management/single-image-slide';
import { SplitImageSlide } from 'src/app/models/website-management/split-image-slide';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
    HtmlContentSlidePreviewComponent
} from '../components/html-content-slide-preview/html-content-slide-preview.component';
import {
    SingleImageSlidePreviewComponent
} from '../components/single-image-slide-preview/single-image-slide-preview.component';
import {
    SplitImageSlidePreviewComponent
} from '../components/split-image-slide-preview/split-image-slide-preview.component';

@Component({
  selector: 'app-slide-preview',
  templateUrl: './slide-preview.component.html',
  styleUrls: ['./slide-preview.component.scss']
})
export class SlidePreviewComponent implements OnInit {
  @Input() slide: CarouselSlide;
  @Input() componentId: string;
  @Input() websiteId: string;

  @ViewChild(SlidePreviewSwitchDirective, {static: true}) appSlidePreviewSwitchDirective!: SlidePreviewSwitchDirective;

  slideTypeName: string;

  ngOnInit(): void {
    const viewContainerRef = this.appSlidePreviewSwitchDirective.viewContainerRef;
    viewContainerRef.clear();

    if (this.slide.type === 'ConsumerSites.Common.Models.SingleImageSlide') {
      this.slideTypeName = 'Single Image Slide';

      const viewContainerRef = this.appSlidePreviewSwitchDirective.viewContainerRef
        .createComponent(SingleImageSlidePreviewComponent);
      viewContainerRef.instance.slide = this.slide as SingleImageSlide;
      viewContainerRef.instance.componentId = this.componentId;
      viewContainerRef.instance.websiteId = this.websiteId;
    } else if (this.slide.type === 'ConsumerSites.Common.Models.SplitImageSlide') {
      this.slideTypeName = 'Split Image Slide';

      const viewContainerRef = this.appSlidePreviewSwitchDirective.viewContainerRef
        .createComponent(SplitImageSlidePreviewComponent);
      viewContainerRef.instance.slide = (this.slide as unknown) as SplitImageSlide;
      viewContainerRef.instance.componentId = this.componentId;
      viewContainerRef.instance.websiteId = this.websiteId;
    } else if (this.slide.type === 'ConsumerSites.Common.Models.HtmlContentSlide') {
      this.slideTypeName = 'HTML Content Slide';

      const viewContainerRef = this.appSlidePreviewSwitchDirective.viewContainerRef
        .createComponent(HtmlContentSlidePreviewComponent);
      viewContainerRef.instance.slide = (this.slide as unknown) as HtmlContentSlide;
    } else {
      throw new TypeError('Unknown slide type');
    }
  }

}
