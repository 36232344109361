import { Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { ApplicationInsightsService } from './services/application-insights.service';
import { EventMessage, EventType } from '@azure/msal-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private msalSubscription: Subscription;
  private routerSubscription: Subscription;

  constructor(
    private broadcastService: MsalBroadcastService,
    private msal: MsalService,
    private router: Router,
    private appInsights: ApplicationInsightsService) { }

  ngOnInit(): void {
    this.msalSubscription = this.broadcastService.msalSubject$.subscribe((eventMessage: EventMessage) => {
      if (eventMessage.eventType === EventType.LOGIN_SUCCESS || eventMessage.eventType === EventType.LOGIN_FAILURE) {
        this.registerUser();
      }
    });
    this.routerSubscription = this.router.events.subscribe(this.onRouterEvent.bind(this));

    this.registerUser();
  }

  ngOnDestroy(): void {
    if (this.msalSubscription) {
      this.msalSubscription.unsubscribe();
    }
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  private registerUser() {
    const account = this.msal.instance.getActiveAccount();
    if (account) {
      this.appInsights.setUserId(account.username);
    } else {
      this.appInsights.clearUserId();
    }
  }

  private onRouterEvent(event: Event) {
    if (event instanceof NavigationEnd) {
      this.appInsights.logPageView((event as NavigationEnd).url);
    }
  }
}
