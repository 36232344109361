<app-loading-spinner *ngIf="!charity"></app-loading-spinner>

<form *ngIf="charity" #charityForm="ngForm" class="container-fluid" novalidate>
  <div class="row justify-content-end mb-4">
    <div class="col-auto">
      <a class="font-weight-bold" [routerLink]="['/website-management', websiteConfig.id, 'charities']">
        <span class="k-icon k-i-arrow-left"></span>
        Return to website management
      </a>
    </div>
  </div>
  <div class="row justify-content-end mb-5">
    <div class="col-auto">
      <button type="button"
        class="btn btn-primary"
        [disabled]="!dirty || loading"
        (click)="saveCharity()">
        <span class="k-icon k-i-save"></span>
        Save Charity
      </button>
    </div>
    <div class="col-auto">
      <button type="button"
        class="btn btn-secondary"
        [disabled]="!dirty || loading"
        (click)="abandonChanges()">
        <span class="k-icon k-i-cancel"></span>
        Abandon Changes
      </button>
    </div>
  </div>

  <div class="form-group">
    <label for="charityCode">Code</label>
    <input #charityCode="ngModel"
      type="text"
      name="code"
      maxlength="16"
      class="form-control"
      [style.width.em]="16"
      [(ngModel)]="charity.code"
      [disabled]="true">
  </div>

  <div class="form-group">
    <label for="charityName">Name</label>
    <input #charityName="ngModel"
      type="text"
      name="charityName"
      class="form-control"
      [(ngModel)]="charity.dmsCharity.name"
      [disabled]="true">
  </div>

  <div class="row align-items-center">
    <div class="col-auto">
      <div class="form-check">
        <input kendoCheckBox #displayOnWebsiteCheckbox
          type="checkbox"
          name="displayOnWebsiteCheckbox"
          value="true"
          required
          class="form-check-input"
          [(ngModel)]="displayOnWebsite"
          [disabled]="loading">
        <label [for]="displayOnWebsiteCheckbox" class="form-check-label">Display on website</label>
      </div>
    </div>
    <div class="col">
      <div class="form-group">
        <label for="charityLink">Charity Link</label>
        <input #charityLink="ngModel"
          type="text"
          name="charityLink"
          maxlength="500"
          class="form-control"
          [(ngModel)]="charity.link"
          [disabled]="loading || !displayOnWebsite">
        <div *ngIf="charityLink.errors?.required && (charityLink.dirty || charityLink.touched)" class="alert alert-danger my-2">
          Charity Link is required.
        </div>
      </div>
    </div>
  </div>

  <div class="form-group">
    <label for="charityLogoImg">Charity Logo Image</label>
    <input #charityLogoImg="ngModel"
      type="text"
      name="charityLogoImg"
      maxlength="500"
      required
      class="form-control"
      [(ngModel)]="charity.logoImg"
      [disabled]="loading">
    <div *ngIf="charityLogoImg.errors?.required && (charityLogoImg.dirty || charityLogoImg.touched)" class="alert alert-danger my-2">
      Charity logo image is required.
    </div>
  </div>

  <div class="form-group">
    <label for="charityLogoSquareImg">Charity Logo Square Image</label>
    <input #charityLogoSquareImg="ngModel"
      type="text"
      name="charityLogoSquareImg"
      maxlength="500"
      required
      class="form-control"
      [(ngModel)]="charity.logoSquareImg"
      [disabled]="loading">
    <div *ngIf="charityLogoSquareImg.errors?.required && (charityLogoSquareImg.dirty || charityLogoSquareImg.touched)"
      class="alert alert-danger my-2">
      Charity logo square image is required.
    </div>
  </div>

  <div class="form-group">
    <label for="thankYouImage">Thank You Image</label>
    <input #thankYouImage="ngModel"
      type="text"
      name="thankYouImage"
      maxlength="500"
      class="form-control"
      [(ngModel)]="charity.thankYouImage"
      [disabled]="loading">
  </div>

  <div class="form-group">
    <label for="thankYouLabel">Thank You Label</label>
    <input #thankYouLabel="ngModel"
      type="text"
      name="thankYouLabel"
      maxlength="32"
      class="form-control"
      [style.width.em]="20"
      [(ngModel)]="charity.thankYouLabel"
      [disabled]="loading">
  </div>
</form>

<kendo-dialog title="Abandon Changes" *ngIf="abandonDialogOpened" [minWidth]="250">
  <p>Are you sure you want to abandon the changes to this charity?</p>
  <kendo-dialog-actions>
    <button kendoButton (click)="abandonDialogClick(true)" [primary]="true">Yes</button>
    <button kendoButton (click)="abandonDialogClick(false)">No</button>
  </kendo-dialog-actions>
</kendo-dialog>
