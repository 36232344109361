import { SplitImageSlide } from 'src/app/models/website-management/split-image-slide';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-split-image-slide-preview',
  templateUrl: './split-image-slide-preview.component.html',
  styleUrls: ['./split-image-slide-preview.component.scss']
})
export class SplitImageSlidePreviewComponent {
  @Input() slide: SplitImageSlide;
  @Input() componentId: string;
  @Input() websiteId: string;
}
