import { WebsiteConfig } from 'src/app/models/website-management/website-config';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { EditorComponent } from '@progress/kendo-angular-editor';

@Component({
  selector: 'app-html-editor',
  templateUrl: './html-editor.component.html',
  styleUrls: ['./html-editor.component.scss']
})
export class HtmlEditorComponent implements OnInit, AfterViewInit {
  @Input() name: string;
  @Input() disabled: boolean;
  @Input() private websiteConfig: WebsiteConfig;
  @Input() content: string;
  @Output() contentChange = new EventEmitter<string>();

  @ViewChild('contentEditor', { static: true }) private contentEditor: EditorComponent;

  editorContent: string;

  ngOnInit(): void {
    this.editorContent = this.content;
  }

  ngAfterViewInit(): void {
    if (this.websiteConfig) {
      const iframe = this.contentEditor.container.element.nativeElement as HTMLIFrameElement;
      const doc = iframe.contentDocument;

      const link = doc.createElement('link') as HTMLLinkElement;
      link.rel = 'stylesheet';

      if (this.websiteConfig.id === 'SecondAve') {
        link.href = `${this.websiteConfig.baseUrl}/css/SecondAve.min.css`;
        doc.head.appendChild(link);
      } else if (this.websiteConfig.id === 'GoGreenDrop') {
        link.href = `${this.websiteConfig.baseUrl}/css/GreenDrop.min.css`;
        doc.head.appendChild(link);
      }
    }
  }

  valueChanged(value: string): void {
    this.content = value;
    this.contentChange.emit(this.content);
  }
}
