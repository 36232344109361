import { LocationHours } from 'src/app/models/website-management/location-hours';
import { Component, Input } from '@angular/core';
import {
    AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator
} from '@angular/forms';

@Component({
  selector: 'app-location-hours',
  templateUrl: './location-hours.component.html',
  styleUrls: ['./location-hours.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: LocationHoursComponent,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: LocationHoursComponent,
      multi: true
    }
  ]
})
export class LocationHoursComponent implements ControlValueAccessor, Validator {
  @Input() disabled: boolean;
  @Input() locationHours: LocationHours[];
  @Input() readonly: boolean;

  onChange: (hours: LocationHours[]) => void;
  onTouched: () => void;

  touched = false;

  writeValue(locationHours: LocationHours[]): void {
    this.locationHours = locationHours;
  }

  registerOnChange(fn: (hours: LocationHours[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
  }

  validate(control: AbstractControl): ValidationErrors {
    if (control.value && control.value.filter(x => !x.closed && x.openTime >= x.closeTime).length > 0) {
      return { invalidHours: true };
    }

    return null;
  }

  markAsTouched(): void {
    if (!this.touched) {
      if (this.onTouched) {
        this.onTouched();
      }
      this.touched = true;
    }
  }

  openTimeChanged(dayOfWeek: number, openTime: Date): void {
    const hours = this.locationHours.find(x => x.dayofWeek === dayOfWeek);
    hours.openTime = openTime;
    this.onChange(this.locationHours);
  }

  closeTimeChanged(dayOfWeek: number, closeTime: Date): void {
    const hours = this.locationHours.find(x => x.dayofWeek === dayOfWeek);
    hours.closeTime = closeTime;
    this.onChange(this.locationHours);
  }

  closedChanged(dayOfWeek: number, event: Event): void {
    const checkbox = event.target as HTMLInputElement;
    const hours = this.locationHours.find(x => x.dayofWeek === dayOfWeek);
    hours.closed = checkbox.checked;
    this.onChange(this.locationHours);
  }

  temporaryChanged(dayOfWeek: number, event: Event): void {
    const checkbox = event.target as HTMLInputElement;
    const hours = this.locationHours.find(x => x.dayofWeek === dayOfWeek);
    hours.temporary = checkbox.checked;
    this.onChange(this.locationHours);
  }
}
