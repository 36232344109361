import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NewsRibbon } from '../../../models/website-management/news-ribbon';

@Component({
  selector: 'app-news-ribbon-draft',
  templateUrl: './news-ribbon-draft.component.html',
  styleUrls: ['./news-ribbon-draft.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NewsRibbonDraftComponent implements OnInit {
  @Input() content: NewsRibbon;
  @Input() disabled: boolean;

  justifyContentItems: { text: string, value: string }[] = [
    { text: 'Start', value: 'justify-content-start' },
    { text: 'End', value: 'justify-content-end' },
    { text: 'Center', value: 'justify-content-center' },
    { text: 'Between', value: 'justify-content-between' },
    { text: 'Around', value: 'justify-content-around' }
  ];

  ngOnInit(): void {
    if (!this.content.columns || !this.content.columns.length) {
      this.content.columns = [''];
    }
  }

  addColumn(): void {
    this.content.columns.push('');
  }

  deleteColumn(index: number): void {
    this.content.columns.splice(index, 1);
  }

  moveLeft(index: number): void {
    const column = this.content.columns.splice(index, 1)[0];
    this.content.columns.splice(index - 1, 0, column);
  }

  moveRight(index: number): void {
    const column = this.content.columns.splice(index, 1)[0];
    this.content.columns.splice(index + 1, 0, column);
  }

  dontTrack: () => string = () => '';
}
